import { useState } from "react";
import Select from "react-select";
import "./dropdown.style.scss";
import AsyncSelect from "react-select/async";
import React from "react";

function Dropdown({
    isLoading = false,
    isClearable = false,
    isSearchable = false,
    isMultiSelect = false,
    required = false,
    dropDownIndicator = false,
    disabled = false,
    isAsync = false,
    useCustomSingleLabel = false,
    options,
    parentDivClassname,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const showLabelClass = !props.label || props.label === "" ? "hidden" : "";
    const focusClass = focus ? "focus" : "";
    const disabledClass = disabled ? "disabled" : "";
    const requiredDropdown = dropDownIndicator ? "" : "none";

    // isloading
    if (isLoading) {
        options = [{ value: "loading", label: "Loading Data" }];
    }

    return (
        <div className={`dropdownParent-style ${parentDivClassname} `}>
            <label className={`${showLabelClass}`}>
                <div className={`dropdownLabelDiv-style`}>
                    {props.label}
                    {required && <span className="required">*</span>}
                </div>
            </label>
            <div className={`${focusClass} ${disabledClass} border dropDown ${isMultiSelect && 'dropdown-height-multiple'}`}>
                {!isAsync ? (
                    <Select
                        className={`${props.className}`}
                        classNamePrefix={"select"}
                        components={{
                            ...(props?.customOptionComponent && {
                                Option: props?.customOptionComponent,
                            }),
                        }}
                        closeMenuOnSelect={isMultiSelect === true ? false : true}
                        defaultValue={props?.defaultValue}
                        placeholder={props.placeholder}
                        value={props?.value}
                        isMulti={isMultiSelect}
                        isDisabled={disabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={options}
                        isOptionDisabled={(options) => options?.value === "loading"}
                        menuPosition="fixed"
                        menuIsOpen={isOpen}
                        onMenuOpen={() => setIsOpen(true)}
                        onMenuClose={() => setIsOpen(false)}
                        maxMenuHeight={115}
                        onFocus={() => {
                            setFocus(true);
                        }}
                        onBlur={() => {
                            setFocus(false);
                        }}
                        onChange={(e) => {
                            if (props.onClick) props.onClick(e);
                            if (props.additionalDataHandling)
                                props.additionalDataHandling(
                                    e?.additionalData ?? null
                                );
                        }}
                        onInputChange={(value) => {
                            if (props.onInputChange)
                                return props.onInputChange(value);
                        }}
                        filterOption={(option, inputValue) => {
                            if (isLoading) {
                                return true;
                            }
                            return option.label
                                .toUpperCase()
                                .includes(inputValue.toUpperCase());
                        }}
                        unstyled={false}
                        styles={{
                            control: (base) => ({
                                ...base,
                                border: "none",
                                boxShadow: "none",
                                "&:hover": {
                                    border: "none",
                                },
                                backgroundColor: disabled ? "" : "",
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: "var(--loglines-gray-v4)",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: "99",
                            }),
                            menuList: (base) => ({
                                ...base,
                                "::-webkit-scrollbar": {
                                    width: "12px",
                                    height: "15px",
                                    marginBottom: "50px",
                                },
                                "::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                },
                                "::-webkit-scrollbar-thumb": {
                                    backgroundColor: "var(--loglines-gray-v2)",
                                    border: "3px",
                                    borderStyle: "solid",
                                    borderColor: "transparent",
                                    borderRadius: "9px",
                                    backgroundClip: "content-box",
                                },
                                "::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "var(--loglines-gray-v3)",
                                    borderRadius: "15px",
                                },
                                backgroundColor: "var(--loglines-white)",
                                fontSize: "14px",
                                lineHeight: "20px",
                                borderRadius: "5px",
                                border: "1px",
                                borderColor: "#52B788",
                                borderStyle: "solid",
                                position: "relative",
                            }),
                            dropdownIndicator: (base, state) => ({
                                ...base,
                                display: requiredDropdown,
                                transform: state.selectProps.menuIsOpen
                                    ? "rotate(180deg)"
                                    : "",
                                transition: "transform 0.3s ease-in-out",
                            }),
                            indicatorSeparator: (base) => ({
                                ...base,
                                display: "none",
                            }),
                            clearIndicator: (base) => ({
                                ...base,
                                paddingRight: "2px !important",
                                cursor: "pointer",
                            }),
                            option: (base, { isSelected, isDisabled }) => ({
                                ...base,
                                textAlign: isLoading ? "center" : "left",
                                backgroundColor: isSelected
                                    ? "#D2ECDD"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#C3E5D2",
                                },
                                color: isDisabled
                                    ? "var(--loglines-gray-v2)"
                                    : "var(--loglines-gray-v6)",
                                position: "relative",
                                cursor: "pointer",
                                paddingLeft: "8px",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            }),
                            multiValue: (base) => ({
                                ...base,
                                backgroundColor: "#BEEDFF",
                                marginLeft: "4px",
                                marginTop: "4px",
                                marginBottom: "4px",
                                color: "#0075A1",
                                fontSize: "14px",
                                borderRadius: "5px",
                            }),
                            multiValueRemove: (base) => ({
                                ...base,
                                borderRadius: "5px",
                                "&:hover": {
                                    backgroundColor: "unset",
                                    color: "unset",
                                },
                            }),
                            singleValue: (base) => ({
                                ...base,
                                color: "var(--loglines-black)",
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                        })}
                    />
                ) : (
                    <AsyncSelect
                        loadOptions={props?.loadOptions && props?.loadOptions}
                        className={`${props.className}`}
                        classNamePrefix={"select"}
                        // comment because there are still bugs
                        // components={animatedComponents}
                        closeMenuOnSelect={isMultiSelect === true ? false : true}
                        placeholder={props.placeholder}
                        value={props?.value}
                        isMulti={isMultiSelect}
                        isDisabled={disabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        options={options}
                        isOptionDisabled={(options) => options?.value === "loading"}
                        menuPosition="fixed"
                        menuIsOpen={isOpen}
                        onMenuOpen={() => setIsOpen(true)}
                        onMenuClose={() => setIsOpen(false)}
                        maxMenuHeight={115}
                        onFocus={() => {
                            setFocus(true);
                        }}
                        onBlur={() => {
                            setFocus(false);
                        }}
                        onChange={props?.onChange && props.onChange}
                        defaultOptions={props?.defaultValue}
                        unstyled={false}
                        styles={{
                            control: (base) => ({
                                ...base,
                                border: "none",
                                boxShadow: "none",
                                "&:hover": {
                                    border: "none",
                                },
                            }),
                            placeholder: (base) => ({
                                ...base,
                                color: "var(--loglines-gray-v4)",
                                paddingLeft: "8px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }),
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: "99",
                            }),
                            menuList: (base) => ({
                                ...base,
                                "::-webkit-scrollbar": {
                                    width: "12px",
                                    height: "15px",
                                    marginBottom: "50px",
                                },
                                "::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                },
                                "::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#74D9FF",
                                    border: "3px",
                                    borderStyle: "solid",
                                    borderColor: "transparent",
                                    borderRadius: "9px",
                                    backgroundClip: "content-box",
                                },
                                "::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "#74A8FF",
                                    borderRadius: "15px",
                                },
                                backgroundColor: "white",
                                fontSize: "14px",
                                lineHeight: "20px",
                                borderRadius: "5px",
                                border: "1px",
                                borderColor: "#74D9FF",
                                borderStyle: "solid",
                                position: "relative",
                            }),
                            dropdownIndicator: (base, state) => ({
                                ...base,
                                display: requiredDropdown,
                                transform: state.selectProps.menuIsOpen
                                    ? "rotate(180deg)"
                                    : "",
                                transitionProperty: "all",
                                transitionDuration: "0.5s",
                                paddingLeft: "2px !important",
                            }),
                            indicatorSeparator: (base) => ({
                                ...base,
                                display: "none",
                            }),
                            clearIndicator: (base) => ({
                                ...base,
                                paddingRight: "2px !important",
                                cursor: "pointer",
                            }),
                            option: (base, { isSelected, isDisabled }) => ({
                                ...base,
                                textAlign: isLoading ? "center" : "left",
                                backgroundColor: isSelected
                                    ? "#d2f1fc"
                                    : "inherit",
                                "&:hover": {
                                    backgroundColor: "#d2f1fc",
                                },
                                color: isDisabled ? "#b7b7b7" : "#424A58",
                                position: "relative",
                                cursor: "pointer",
                                paddingLeft: "8px",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            }),
                            multiValue: (base) => ({
                                ...base,
                                backgroundColor: "#BEEDFF",
                                marginLeft: "4px",
                                marginTop: "4px",
                                marginBottom: "4px",
                                color: "#0075A1",
                                fontSize: "14px",
                                borderRadius: "5px",
                            }),
                            multiValueRemove: (base) => ({
                                ...base,
                                borderRadius: "5px",
                                "&:hover": {
                                    backgroundColor: "unset",
                                    color: "unset",
                                },
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                        })}
                    />
                )}
            </div>
        </div>
    );
}
export default Dropdown;
