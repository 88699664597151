import React, { useState, useEffect } from "react";
import "./style.scss";
import { LoginUser, postLogin, postRegister } from "../../Service";
import { setUser } from "../../store/storeLogin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/Logo/logo.png";
import { toast } from "react-toastify";
import LoadingImg from "../../assets/img/loading-gif.gif";
import { Form, Formik } from "formik";
import FormInput from "../../components/form-input/form-input.component";
import {
    loginInitialValue,
    loginValidation,
} from "../../form-validations/auth/login.validation";
import {
    signUpInitialValue,
    signUpValidation,
} from "../../form-validations/auth/sign-up.validation";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.user);
    const [contentItem, setContentItem] = useState(1);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [payload, setPayload] = useState({
        nik: "",
        name: "",
        phone: "",
        email: "admin",
        password: "admin123",
        confirmPassword: "",
    });
    const handleChange = (e) => {
        setPayload({ ...payload, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        LoginUser({ payload, dispatch, setData: setUser, navigate });
        toast.success("Login Success");
        navigate("/");

        e.preventDefault();
    };

    const loginSubmit = async (value) => {
        try {
            setLoading(true);
            const actionResult = await postLogin(value);
            setLoading(false);
            const response = actionResult;
            if (response.status !== 200) {
                setErrorMessage(response.message);
                return;
            }
            const responseData = actionResult?.data;
            const setData = {
                data: {
                    email: responseData?.user?.email,
                    name: responseData?.user?.name,
                    username: responseData?.user?.name,
                    role: responseData?.user?.role,
                    id: responseData?.user.id,
                    modules: [],
                },
                token: responseData.access_token,
            };
            dispatch(setUser(setData));
            const userLogin = JSON.stringify(setData?.data);
            const token = setData?.access_token;
            localStorage.setItem("Token", token);
            localStorage.setItem("userLogin", userLogin);
            toast.success("Welcome Back 😉");
            navigate("/");
        } catch (e) {
            setLoading(false);
            const errorMessage = typeof e !== "string" ? "Something wrong" : e;
            setErrorMessage(errorMessage);
        }
    };

    const signUpSubmit = async (value) => {
        try {
            setLoading(true);
            const actionResult = await postRegister(value);
            setLoading(false);
            const response = actionResult;
            if (response.status !== 200) {
                setErrorMessage(response.message);
                return;
            }
            toast.success("Successfully created user");
            const responseData = actionResult?.data;
            const setData = {
                data: {
                    email: responseData?.user?.email,
                    name: responseData?.user?.name,
                    username: responseData?.user?.name,
                    role: responseData?.user?.role,
                    id: responseData?.user.id,
                    modules: [],
                },
                token: responseData.access_token,
            };
            dispatch(setUser(setData));
            const userLogin = JSON.stringify(setData?.data);
            const token = setData?.access_token;
            localStorage.setItem("Token", token);
            localStorage.setItem("userLogin", userLogin);
            toast.success("Welcome Back 😉");
            navigate("/");
        } catch (e) {
            setLoading(false);
            const errorMessage = typeof e !== "string" ? "Something wrong" : e;
            setErrorMessage(errorMessage);
        }
    };

    useEffect(() => {
        if (user) navigate("/");
    }, [user, navigate]);

    const contentLogin = () => {
        return (
            <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                    <div className="content-title-login">
                        <div className="login-heading">
                            Let's Build Your Professional Career
                        </div>
                        <div className="login-desc">
                            Sign in to your account below
                        </div>
                    </div>
                    <Formik
                        initialValues={loginInitialValue}
                        validationSchema={loginValidation}
                        onSubmit={(values) => {
                            loginSubmit(values);
                        }}
                    >
                        {() => (
                            <Form>
                                <FormInput
                                    label="Email"
                                    placeholder="Enter your mail"
                                    name="email"
                                    formClassContainer="mb-3"
                                />
                                <FormInput
                                    label="Password"
                                    name="password"
                                    placeholder="Enter your Password"
                                    type="password"
                                    formClassContainer="mb-3"
                                />
                                <div className="d-grid">
                                    <button
                                        className="btn btn-lg btn-primary btn-login mb-2"
                                        type="submit"
                                    >
                                        {
                                            loading ? (
                                                <div className="loading-animasi">
                                                    <img src={LoadingImg} alt="avatar" className="avatar-image" />
                                                    Loading...
                                                </div>
                                            ) : "Sign in"
                                        }
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="content-footer-login">
                        Have an not account?
                        <div className="footer-btn">
                            <span onClick={() => setContentItem(2)}>
                                Sign Up
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const contentSignUp = () => {
        return (
            <div className="row">
                <div className="col-md-9 col-lg-8 mx-auto">
                    <div className="content-title-login">
                        <div className="login-heading">
                            Create an Account <br /> to Join Us
                        </div>
                        <div className="login-desc">
                            Create your account below
                        </div>
                    </div>
                    <Formik
                        initialValues={signUpInitialValue}
                        validationSchema={signUpValidation}
                        onSubmit={(values) => {
                            signUpSubmit(values);
                        }}
                    >
                        {() => (
                            <Form>
                                <div className="row">
                                    <FormInput
                                        label={"NIK"}
                                        name={"nik"}
                                        placeholder="Enter your NIK"
                                        formClassContainer={`mb-3 w-50`}
                                        type={"number"}
                                    />
                                    <FormInput
                                        label={"Name"}
                                        name={"name"}
                                        placeholder="Enter your name"
                                        formClassContainer={`mb-3 w-50`}
                                    />
                                </div>
                                <div className="row">
                                    <FormInput
                                        label={"Email"}
                                        name={"email"}
                                        placeholder="Enter your mail"
                                        formClassContainer={`mb-3 w-50`}
                                    />
                                    <FormInput
                                        label={"Call Phone Number"}
                                        name={"phone"}
                                        placeholder="Enter your call phone number"
                                        formClassContainer={`mb-3 w-50`}
                                    />
                                </div>
                                <div className="row">
                                    <FormInput
                                        label={"Password"}
                                        name={"password"}
                                        placeholder="Enter your password"
                                        formClassContainer={`mb-3 w-50`}
                                        type={"password"}
                                    />
                                    <FormInput
                                        label={"Confirm Password"}
                                        name={"confirmPassword"}
                                        placeholder="Confirm your password"
                                        formClassContainer={`mb-3 w-50`}
                                        type={"password"}
                                    />
                                </div>
                                <div className="d-grid">
                                    <button
                                        className="btn btn-lg btn-primary btn-login  mb-2"
                                        type="submit"
                                    >
                                        {
                                            loading ? (
                                                <div className="loading-animasi">
                                                    <img src={LoadingImg} alt="avatar" className="avatar-image" />
                                                    Loading...
                                                </div>
                                            ) : "Sign Up"
                                        }
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="content-footer-login">
                        Already Have account?
                        <div className="footer-btn">
                            <span onClick={() => setContentItem(1)}>
                                Sign In
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid ps-md-0">
            <div className="row g-0">
                <div
                    className={`d-none d-md-flex col-md-4 col-lg-6 ${contentItem === 1 ? "bg-image" : "bg-image-sigup"
                        }`}
                />
                <img src={Logo} alt="logoIWap" className="logo-login" />
                <div className="col-md-8 col-lg-6">
                    <div className="login d-flex align-items-center py-5">
                        <div className="container">
                            <div
                                className={`content-login ${contentItem === 1 ? "active" : ""
                                    }`}
                            >
                                {contentItem === 1 &&
                                    contentLogin({
                                        handleSubmit,
                                        handleChange,
                                        payload,
                                        setContentItem,
                                    })}
                            </div>
                            <div
                                className={`content-login ${contentItem === 2 ? "active" : ""
                                    }`}
                            >
                                {contentItem === 2 &&
                                    contentSignUp({
                                        handleSubmit,
                                        handleChange,
                                        payload,
                                        setContentItem,
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
