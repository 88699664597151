import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import FormDropdown from "../../../components/form-dropdown/form-dropdown.component.jsx";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";

const UserInterviewModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('recommendation');
    const [interview, setInterview] = useState(false);

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };

    const content = () => {
        if (interview) {
            return (
                <>
                    <HeaderExpand id="Interview">
                        <div className="header-user-interview">
                            <i className="ri-check-double-line" />
                            <div className="text-header-user-interview">User Interviews Have Been Completed</div>
                        </div>

                    </HeaderExpand>
                    <div className="recommendation-switch">
                        <input
                            type="radio"
                            id="recommendation"
                            value="recommendation"
                            checked={recommendation === 'recommendation'}
                            onChange={handleChange}
                        />
                        <label htmlFor="recommendation" className="recommendation-label">
                            Recommendation
                        </label>
                        <input
                            type="radio"
                            id="noRecommendation"
                            value="noRecommendation"
                            checked={recommendation === 'noRecommendation'}
                            onChange={handleChange}
                        />
                        <label htmlFor="noRecommendation" className="recommendation-label">
                            No Recommendation
                        </label>
                    </div>
                    <FormTextArea
                        placeholder={"Give notes to applicants"}
                        label="Note"
                        name="note"
                    />
                </>
            )
        } else {
            return (
                <div className="mb-3">
                    <FormDatePicker
                        name="date"
                        label="Date"
                        required
                    />
                    <div className="row mt-3">
                        <div className="col-4">
                            <FormDropdown
                                options={[
                                    {
                                        value: "BATAM",
                                        label: "BATAM",
                                    },
                                ]}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={'Select Hour'}
                                label="Select Hour"
                                name="hour"
                            />
                        </div>
                        <div className="col-4">
                            <FormDropdown
                                options={[
                                    {
                                        value: "BATAM",
                                        label: "BATAM",
                                    },
                                ]}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={'Select Minute'}
                                label="Select Minute"
                                name="minute"
                            />
                        </div>
                        <div className="col-4">
                            <FormDropdown
                                options={[
                                    {
                                        value: "BATAM",
                                        label: "BATAM",
                                    },
                                ]}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={'Select Zone'}
                                label="Select Zone"
                                name="zone"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <FormInput
                            parentDivClassname="w-100 me-3"
                            placeholder={"00"}
                            label="Meeting ID"
                            name="meetId"
                        />
                    </div>
                    <div className="mt-3">
                        <FormInput
                            parentDivClassname="w-100 me-3"
                            placeholder={"https://zoom"}
                            label="Link"
                            name="link"
                        />
                    </div>
                    <Button
                        onClick={() => setInterview(!interview)}
                        classNames={"w-100 mt-3"}
                        label="Schedule Interview"
                    />
                </div>
            )
        }

    }
    return (
        <FormikProvider value={formik}>
            <Expand title="User Interview" desc="Scheduling applicant interviews with the department" icon={"archive-fill"} status>
                {content()}
            </Expand>
        </FormikProvider>

    );
};

export default UserInterviewModal;
