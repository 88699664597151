import React from "react";
import "./button.style.scss";

const Button = ({
    label,
    classNames,
    onClick,
    variant = "green",
    type = "button",
    disabled = false,
    style,
    icon,
}) => {
    const buttonVariant = "button-" + variant;
    return (
        <button
            className={`btn button-style ${classNames} ${buttonVariant} ${icon}`}
            onClick={onClick}
            type={type}
            sryle={style}
            disabled={disabled}
        >
            {label}
        </button>
    );
};

export default Button;
