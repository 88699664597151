import React, { useEffect, useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import moment from "moment";

const CoursesData = ({
    formik,
    data,
    removeData,
    isAddData,
    setIsAddData,
    isSaveAll,
    canEdit,
}) => {
    const formateDate = (startDate, endDate) => {
        // Menghitung rentang tanggal
        const start = moment(startDate);
        const end = moment(endDate);
        const duration = moment.duration(end.diff(start));

        let formattedRange;

        // Hitung dalam hari
        const days = duration.asDays();
        if (days < 30) {
            formattedRange = `${Math.round(days)} Days`;
        } else {
            // Hitung dalam bulan
            const months = duration.asMonths();
            if (months < 12) {
                formattedRange = `${Math.round(months)} Month${
                    months > 1 ? "s" : ""
                }`;
            } else {
                // Hitung dalam tahun
                const years = duration.asYears();
                formattedRange = `${Math.round(years)} Year${
                    years > 1 ? "s" : ""
                }`;
            }
        }

        return formattedRange;
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="content-profile-title">Courses Data</div>
                {!isAddData && !isSaveAll && canEdit && (
                    <Button
                        label={"Add Courses"}
                        onClick={() => setIsAddData(true)}
                    />
                )}
            </div>
            {!isAddData ? (
                <div className="row">
                    {data?.map((item) => (
                        <div className={`col-12 col-lg-6 mt-3`}>
                            <div className="card-information">
                                <div className="d-flex justify-content-between">
                                    <div className="card-title">
                                        {item?.coursesName}
                                    </div>
                                    <div className="d-flex gap-2">
                                        <Button
                                            icon="ri-pencil-line"
                                            classNames="button-icon"
                                            variant="yellow"
                                            onClick={() => {
                                                formik?.setValues(item);
                                                setIsAddData(true);
                                            }}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                        <Button
                                            icon="ri-delete-bin-7-line"
                                            classNames="button-icon"
                                            variant="red"
                                            onClick={() => removeData(item?.id)}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                    </div>
                                </div>
                                <div className="card-details">
                                    <div>{item?.Organizer}</div>
                                    <div>
                                        {formateDate(
                                            item?.startDate,
                                            item?.endDate
                                        )}
                                    </div>
                                </div>
                                <div>
                                    {moment(item?.endDate).format("YYYY") || ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <FormikProvider value={formik}>
                    <div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"Enter courses name"}
                                    label="Courses Name"
                                    name="coursesName"
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"Select Organizer"}
                                    label="Organizer"
                                    name="organizer"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormDatePicker
                                    options={[]}
                                    useBorder={true}
                                    dropDownIndicator={true}
                                    placeholder={"Select Start Date"}
                                    label="Start Date"
                                    name="startDate"
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormDatePicker
                                    placeholder={"Select End Date"}
                                    label="End Date"
                                    name="endDate"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <FormInput
                                    placeholder={"Enter no certificate"}
                                    label="No Certificate"
                                    name="noCertificate"
                                    required
                                />
                            </div>
                        </div>
                        {!formik.isSubmitting && (
                            <Button
                                label={" Save Change"}
                                onClick={() => {
                                    formik?.submitForm();
                                }}
                                classNames={"float-end my-5"}
                            />
                        )}
                    </div>
                </FormikProvider>
            )}
        </>
    );
};

export default CoursesData;
