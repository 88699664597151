import React, { useState, useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { dataDummyTracking } from "../DummyData";
import Table from "../../components/Table";
import useModal from "../../components/modal/modal.service";
import ModalContent from "./content";
import "./style.scss";
import Modal from "../../components/modal/modal.component";

const tabContent = [
  { id: '1', name: 'Pre-Processed' },
  { id: '2', name: 'Processed' },
  { id: '3', name: 'Rejected' },
  { id: '4', name: 'Hired' }
]

const Tracking = () => {
  const [filterInput, setFilterInput] = useState("");
  const [contentActive, setContentActive] = useState("Pre-Processed");
  const [tableHeight, setTableHeight] = useState(310);
  const modalService = useModal();

  useEffect(() => {
    const handleResize = () => {
      const newTableHeight = window.innerHeight - 310;
      setTableHeight(newTableHeight);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Applicant ID",
        accessor: "applicant_id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "WhatsApp",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Origin",
        accessor: "origin",
        Cell: ({ value }) => (
          <div className="card-table card-table-yellow">
            <div><i className="ri-building-line me-1" />{value}</div>
          </div>
        ),
      },
      {
        Header: "Experience",
        accessor: "experience",
      },
      {
        Header: "Education",
        accessor: "education",
      },
      {
        Header: "Departement",
        accessor: "departement",
        Cell: ({ value }) => (
          <div className="card-table card-table-red ">
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: "Potition",
        accessor: "potition",
        Cell: ({ value }) => (
          <div className="card-table card-table-red ">
            <div>{value}</div>
          </div>
        ),
      },
      {
        Header: "Resume",
        accessor: "resume",
        Cell: ({ value }) => (
          <div className="card-table card-table-semi-red">
            <i className="ri-file-pdf-2-line me-2" />{value}
          </div>
        ),
      },
      {
        Header: "Tracking",
        accessor: "tracking",
        Cell: ({ value }) => (
          <div className="card-table card-table-gray-full" onClick={() => modalService.openModalHandling()}>
            <div className="d-flex align-items-center">{value}<i className="ri-eye-line ms-2" /></div>
          </div>
        ),
      },
      {
        Header: "Pic",
        accessor: "pic",
        Cell: ({ value }) => (
          <div className="card-table card-table-gray-full">
            <div className="d-flex align-items-center">{value}<i className="ri-user-3-line ms-2" /></div>
          </div>
        ),
      },
    ],
    []
  );

  useTable(
    {
      columns,
      data: dataDummyTracking,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );


  const {
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: dataDummyTracking,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  return (
    <>
      <div className="content-page">
        <div className="content-page-header">
          <div className="content-page-title">Tracking</div>
          <div className="d-flex align-items-center">
            <div className="form-control input-search d-flex">
              <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Enter a keyword to search"
              />
              <div className="d-flex">
                <div className="vertical" />
                <i className="ri-search-line" />
              </div>
            </div>
            <div className="icon-filter-page">
              <i className="ri-filter-line" />
            </div>
          </div>
        </div>
        <div className="tabs-item-tracking">
          {tabContent.map((data, idx) => {
            return (
              <div
                key={`item-tabs-content-${idx}`}

                onClick={() => setContentActive(data.name)}
                className={`tabs-item ${contentActive === data.name && 'active-item'}`}>
                {data.name}
              </div>
            )
          })}
        </div>
        <Table
          height={tableHeight - 40}
          columns={columns}
          data={dataDummyTracking}
        />
        <Modal
          modalService={modalService}
          className={"modal-tracking"}
          maxWidth="lg">
          <ModalContent modalService={modalService} />
        </Modal>
      </div>
    </>
  );
};

export default Tracking;
