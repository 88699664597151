import "./style.scss";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs.coomponent";
import useService from "./service.jsx";
import { CircularProgress } from "@mui/material";

const Profile = () => {
    const {
        itemProfile,
        navProfile,
        contentVisible,
        loadContent,
        allModalComponent,
        RequestOrSaveAllButton,
        loading,
        handleProfileItemClick,
    } = useService();

    return (
        <div className="container">
            <Breadcrumbs />
            <div className="profile">
                <div className="profile-header">
                    <div className="profile-title">Profile</div>
                </div>
                <div className="profile-body">
                    <div className="profile-nav">
                        <div>
                            {itemProfile.map((data, idx) => (
                                <div
                                    key={`item-profile-${idx}`}
                                    className={`profile-nav-item ${
                                        navProfile === data.name &&
                                        "active-profile"
                                    }`}
                                    onClick={() =>
                                        handleProfileItemClick(data.name)
                                    }
                                >
                                    <i className={data.icon} />
                                    {data.name}
                                </div>
                            ))}
                        </div>
                        <div className="px-3">{RequestOrSaveAllButton}</div>
                    </div>
                    <div
                        className={`profile-content ${
                            !contentVisible && "hidden"
                        }`}
                    >
                        {loading ? <CircularProgress /> : loadContent}
                    </div>
                </div>
            </div>
            <div className="copy-right">
                © 2024 PT Indonesia Weda Bay Industrial Park. All Rights
                Reserved
            </div>

            {allModalComponent}
        </div>
    );
};

export default Profile;
