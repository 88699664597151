import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

export const LoginUser = async ({ payload, dispatch, setData, navigate }) => {
    try {
        if (payload.email === "admin" && payload.password === "admin123") {
            const response = {
                data: {
                    email: "admin@gmail.com",
                    name: "Muhammad Hanjarraes",
                    username: "user",
                    role: "Admin",
                    modules: [],
                },
                token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJoYW5qYXJyYWVzIiwiaWF0IjoxNzE5ODEyNzczLCJleHAiOjE3MTk4OTkxNzN9.lF_6nLplKpOQgbitPFOcO-6EJT3oKjJhUecQ7JfKUT4",
            };
            dispatch(setData(response?.data));
            const userLogin = JSON.stringify(response?.data);
            const token = response?.data?.token;
            localStorage.setItem("Token", token);
            localStorage.setItem("userLogin", userLogin);
            navigate("/");
            toast.success("Welcome Back 😉");
        } else if (payload.email === "user" && payload.password === "user123") {
            const response = {
                data: {
                    email: "user@gmail.com",
                    name: "Muhammad Hanjarraes",
                    username: "user",
                    role: "User",
                    modules: [],
                },
                token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJoYW5qYXJyYWVzIiwiaWF0IjoxNzE5ODEyNzczLCJleHAiOjE3MTk4OTkxNzN9.lF_6nLplKpOQgbitPFOcO-6EJT3oKjJhUecQ7JfKUT4",
            };
            dispatch(setData(response?.data));
            const userLogin = JSON.stringify(response?.data);
            const token = response?.data?.token;
            localStorage.setItem("Token", token);
            localStorage.setItem("userLogin", userLogin);
            navigate("/");
            toast.success("Welcome Back 😉");
        }
    } catch {
        toast.error(` Wrong User 🫤`);
    }

    // try {
    //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, payload);
    //     dispatch(setData(response?.data));
    //     const userLogin =  JSON.stringify(response?.data);
    //     const token = response?.data?.token
    //     localStorage.setItem('Token', token);
    //     localStorage.setItem('userLogin', userLogin);
    //     navigate('/')
    //     // await window.location.reload()
    //     toast.success('Welcome Back 😉');

    // } catch (error) {
    //     if (error.response) {
    //         toast.error(` ${error.response.data.error} 🫤`);
    //     } else if (error.request) {
    //         toast.error(` ${error.response.data.error} 🫤`);
    //     } else {
    //         toast.error(` ${error.response.data.error} 🫤`);
    //     }
    // }
};

export const postLogin = async (payload) => {
    try {
        const api = `${process.env.REACT_APP_API_URL}auth/login`;
        const response = await axios.post(api, payload);
        return response;
    } catch (error) {
        if (error?.response && error?.response?.status === 400) {
            const response = error?.response?.data;
            return response;
        } else {
            const err = error;
            err.isSuccess = false;
            return err;
        }
    }
};

export const postRegister = async (payload) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}auth/register`;
        const response = await axios.post(url, payload);
        return response;
    } catch (error) {
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const getProvinceDropdownData = async () => {
    try {
        const url = `${process.env.REACT_APP_API_URL}location/provinces`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const getRegenciesDropdownData = async (privinceId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}location/regencies/${privinceId}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const getDistricsDropdownData = async (regencyId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}location/districts/${regencyId}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const getProfileData = async (userId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}profile/${userId}`;
        const response = await axios.get(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const postPersonalData = async (payload) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}profile/personal-data`;
        const response = await axios.post(url, payload);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const postAddressData = async (payload) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}profile/address-data`;
        const response = await axios.post(url, payload);
        return response?.data;
    } catch (error) {
        console.error(error);
        const err = error;
        err.isSuccess = false;
        return err;
    }
};

export const logoutUser = async ({ dispatch, setData, navigate }) => {
    try {
        // await axios.post(`/logout`);
        dispatch(setData(null));
        toast.success("Logout successful 😉");
        navigate("/login");
    } catch (error) {
        console.error("Failed to logout", error);
    }
};

export const GetData = async ({ dispatch, setData, urlApi }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${urlApi}`
        );
        dispatch(setData(response.data));
    } catch (error) {
        console.error("Failed to fetch", error);
        throw error;
    }
};

export const GetShowData = async ({ dispatch, setData, urlApi, param }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${urlApi}/${param}`
        );
        dispatch(setData(response.data));
    } catch (error) {
        console.error("Failed to fetch", error);
    }
};

export const GetDetail = async ({ dispatch, setData, urlApi }) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${urlApi}`
        );
        dispatch(setData(response.data));
    } catch (error) {
        console.error("Failed to fetch", error);
        throw error;
    }
};

export const postData = async ({ urlApi, payload }) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/${urlApi}`, payload);
        toast.success("Data Added Successfully");
    } catch (error) {
        console.error(`Failed to post data to ${urlApi}`, error);
        throw error;
    }
};

export const putData = async ({
    dispatch,
    setData,
    urlApi,
    payload,
    param,
}) => {
    try {
        await axios.put(
            `${process.env.REACT_APP_API_URL}/${urlApi}/${param}`,
            payload
        );
        dispatch(setData(null));
        toast.success("updated successfully");
    } catch (error) {
        console.error(`Failed to post data to ${urlApi}`, error);
        toast.error("update failed");
    }
};

export const deleteData = async ({ dispatch, setData, Id, urlApi }) => {
    try {
        const response = await axios.delete(`/${urlApi}`, {
            data: { id: Id },
        });
        dispatch(setData(response.data));
        toast.success("Data deleted successfully");
    } catch (error) {
        toast.error("Failed to delete");
        console.error("Failed to delete", error);
    }
};

export const formateDate = (date) => {
    if (!date) return "";
    return moment(date).format("DD MMM YYYY");
};
