import React, { useState, useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import Table from "../../../components/Table";
import "../style.scss";

const PreProcessed = ({ onClickRemove, data }) => {
    const [tableHeight, setTableHeight] = useState(310);
    useEffect(() => {
        const handleResize = () => {
            const newTableHeight = window.innerHeight - 310;
            setTableHeight(newTableHeight);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "NIK",
                accessor: "nik",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "E-mail",
                accessor: "email",
            },
            {
                Header: "Call Phone Number",
                accessor: "phone",
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (row) => (
                    <div
                        className="action-delete"
                        onClick={() => onClickRemove(row?.row?.original)}
                    >
                        <i className="ri-delete-bin-7-line" />
                    </div>
                ),
            },
        ],
        []
    );

    useTable(
        {
            columns,
            data: data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    return (
        <>
            <Table height={tableHeight - 40} columns={columns} data={data} />
        </>
    );
};

export default PreProcessed;
