import imgContentTime from "../../assets/img/Icon/timer.png";
import imgContentHourglass from "../../assets/img/Icon/hourglass.png";
import imgContentDeleteCircle from "../../assets/img/Icon/delete-circle.png";
import imgContentTarget from "../../assets/img/Icon/target.png";
import imgContentChecklist from "../../assets/img/Icon/checklist-pen.png";
import imgContentManagement from "../../assets/img/Icon/management.png";

export const itemApplicantProcess = [
    {
        name: "Screening",
        img1: imgContentTime,
        img2: imgContentHourglass,
        title1: 'Your Profile is Complete!',
        desc1: 'Your profile is complete and now that you are at the screening phase, wait a moment for instructions.',
        title2: 'No possible position',
        desc2: 'For now there are no possible positions for your application you can wait!',
        status: "success"
    },
    {
        name: "Psychological Test",
        type: 'upload',
        img1: imgContentManagement,
        img2: imgContentChecklist,
        title1: 'Psychological Test',
        desc1: 'You are currently at the psychological test phase, click the button below to take the test.',
        title2: 'Psychology Test Completed',
        desc2: 'You have completed psychological test, wait a moment to get the result.',
        status: "waiting"
    },
    {
        name: "User Interview",
        img1: '',
        img2: '',
        title1: 'Upload Assessment Result',
        desc1: 'You are currently in the assessment phase, upload your assessment results to be selected for the next phase.',
        status: "waiting"
    },
    {
        name: "Benefit Offer",
        img1: '',
        img2: '',
        title1: 'Upload Administrative File & Offering Letter',
        desc1: 'You are now in the final phase of your test, upload your administrative file & offer letter to be shortlisted to the next phase.',
        status: "waiting"
    },
    {
        name: "Onboarding",
        img1: imgContentTime,
        img2: '',
        title1: 'Waiting Process',
        desc1: "You've finished uploading the administrative files, wait for a few moments to get instructions.",
        status: "waiting"
    },
    {
        name: "MCU",
        img1: imgContentTime,
        img2: '',
        title1: 'Waiting Process',
        desc1: 'Now that you are in the MCU phase, just wait a moment to get some instruction.',
        status: "waiting"
    },
    {
        name: "Hired",
        img1: imgContentTarget,
        title1: 'Successful recruitment!',
        desc1: 'You have successfully passed all stages of recruitment. Congratulation for joining Indonesia Weda Bay Industrial Park',
        status: "waiting"
    }
];