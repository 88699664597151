import React from "react";
import "../style.scss";
import Button from "../../../components/button/button.component";
import { FormikProvider } from "formik";
import FormUploadFile from "../../../components/form-upload-file/form-upload-file.component";
import { administrationFileDataInitialValue } from "../../../form-validations/profile/administration-file-data.validation";
import CardAdministrationFile from "../components/card-administration-file";

const AdministrationFile = ({
    formik,
    data,
    setAdministrationFileDataValues,
    isSaveAll,
    canEdit,
    isAdministrationFileForm,
    setIsAdministrationFileForm,
}) => {
    return (
        <div className="administration">
            <div className="content-profile-title d-flex justify-content-between">
                <div>Administration File</div>

                {!isAdministrationFileForm && !isSaveAll && canEdit && (
                    <Button
                        label={"Edit Data"}
                        variant="yellow"
                        onClick={() => {
                            setAdministrationFileDataValues(
                                administrationFileDataInitialValue
                            );
                            setIsAdministrationFileForm(true);
                        }}
                    />
                )}
            </div>
            {!isAdministrationFileForm ? (
                <CardAdministrationFile data={formik.values} />
            ) : (
                <FormikProvider value={formik}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <FormUploadFile label={"CV"} name={"cv"} required />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Formal Photos"}
                                name={"formalPhotos"}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Identity Card"}
                                name={"identityCard"}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Family Card"}
                                name={"familyCard"}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Last Diploma Certificate"}
                                name={"lastDiplomaCertificate"}
                                required
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Grade Transcript"}
                                name={"gradeTranscript"}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormUploadFile
                                label={"Supporting Certificate"}
                                name={"supportingCertificate"}
                                required
                            />
                        </div>
                    </div>
                    {isAdministrationFileForm && (
                        <Button
                            label={"Save Change"}
                            onClick={() => {
                                formik?.submitForm();
                                setIsAdministrationFileForm(false);
                            }}
                            classNames={"float-end my-5"}
                        />
                    )}
                </FormikProvider>
            )}
        </div>
    );
};

export default AdministrationFile;
