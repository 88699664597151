import React from "react";
import Modal from "../../../components/modal/modal.component";
import Button from "../../../components/button/button.component";

const ConfimationModal = ({ modalService, onSubmit }) => {
    console.log("modalService", modalService);

    return (
        <Modal modalService={modalService} className="modal-home">
            <div className="modal-header">
                <div className="modal-title">Attention!</div>
            </div>
            <div className="modal-content">
                <i className={"ri-delete-bin-7-line"} />
                <div className="modal-title">
                    Are you sure you want to remove this user?
                </div>
                <div className="d-flex gap-2">
                    <Button
                        label="Cancel"
                        onClick={() => modalService?.closeModalHandling()}
                        style={{ width: "120px" }}
                        variant="gray"
                    />
                    <Button
                        label={"Yes"}
                        onClick={() => {
                            onSubmit();
                            modalService.closeModalHandling();
                        }}
                        style={{ width: "120px" }}
                        variant="red"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfimationModal;
