import * as Yup from "yup";

export const experienceDataInitialValue = {
    id: 0,
    position: "",
    company: "",
    startDate: "",
    endDate: "",
    additionalInformation: "",
};

export const experienceDataValidation = Yup.object({
    position: Yup.string().required("Position is required"),
    company: Yup.string().required("Company is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
});
