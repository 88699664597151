import React from "react";
import "../style.scss";
import FormTextArea from "../../../components/form-text-area/form-text-area.component";
import FormInput from "../../../components/form-input/form-input.component";
import { FormikProvider } from "formik";
import Button from "../../../components/button/button.component";
import { workPreferencesDataInitialValue } from "../../../form-validations/profile/work-preferences-data.validation";

const WorkPreferencesData = ({
    formik,
    data,
    setWorkPreferencesDataValues,
    isSaveAll,
    canEdit,
}) => {
    return (
        <>
            <FormikProvider value={formik}>
                <div>
                    <div className="content-profile-title d-flex justify-content-between w-100">
                        <div> Work Preferences Data</div>
                        {formik?.isSubmitting && !isSaveAll && (
                            <div className="">
                                <Button
                                    label={"Edit Data"}
                                    variant="yellow"
                                    onClick={() => {
                                        setWorkPreferencesDataValues(
                                            workPreferencesDataInitialValue
                                        );
                                        formik?.setSubmitting(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormTextArea
                                placeholder={
                                    "Enter reason/purpose for working in this company"
                                }
                                label="Reason/purpose for working in this company"
                                name="reason"
                                disabled={formik?.isSubmitting || !canEdit}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormInput
                                descValuePrefix={"Rp"}
                                placeholder={"Use dot as decimal separator"}
                                label="Salary Expectation"
                                name="salaryExpectation"
                                disabled={formik?.isSubmitting || !canEdit}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className=" col-12 col-lg-6">
                            <FormInput
                                placeholder={
                                    "Ex: Retirement benefits, family allowance, THR"
                                }
                                label="Expected allowances"
                                name="expectedallowances"
                                disabled={formik?.isSubmitting || !canEdit}
                                required
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormInput
                                placeholder={"Ex: Transportation, Housing"}
                                label="Expected facilities"
                                name="expectedfacilities"
                                disabled={formik?.isSubmitting || !canEdit}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormTextArea
                                placeholder={
                                    "Enter your Additional Information"
                                }
                                label="Additional Information"
                                name="additionalInformation"
                                disabled={formik?.isSubmitting || !canEdit}
                                required
                            />
                        </div>
                    </div>
                    {!formik.isSubmitting && canEdit && (
                        <Button
                            label={" Save Change"}
                            onClick={formik?.submitForm}
                            classNames={"float-end my-5"}
                        />
                    )}
                </div>
            </FormikProvider>
        </>
    );
};

export default WorkPreferencesData;
