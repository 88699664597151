import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import "./style.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});

const Modal = ({ modalService, children, className, maxWidth = "sm" }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={modalService?.isModalOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => modalService?.closeModalHandling()}
            aria-describedby="alert-dialog-slide-description"
            className={className}
        >
            <>{children}</>
        </Dialog>
    );
};

export default Modal;
