import Modal from "../../../components/modal/modal.component";

const Attention = ({ modalService, onSubmit }) => {
    return (
        <Modal modalService={modalService} className="modal-home">
            <div className="modal-header">
                <div className="modal-title">Complete Profile!</div>
                <i
                    onClick={() => modalService.closeModalHandling()}
                    className="ri-close-fill"
                />
            </div>
            <div className="modal-content">
                <div>
                    <i className="ri-stack-line" />
                </div>
                <div className="modal-title">Complete Profile!</div>
                <div className="modal-desc">
                    You are currently in the screening phase, complete your
                    profile!
                </div>
                <button
                    className="btn btn-primary-small mt-2"
                    type="submit"
                    onClick={() => onSubmit()}
                >
                    Go to Profile
                </button>
            </div>
        </Modal>
    );
};

export default Attention;
