import * as Yup from "yup";

export const administrationFileDataInitialValue = {
    cv: null,
    formalPhotos: null,
    identityCard: null,
    familyCard: null,
    lastDiplomaCertificate: null,
    gradeTranscript: null,
    supportingCertificate: null,
};

const fileValidation = Yup.mixed()
    .required("File is required")
    .test(
        "fileSize",
        "File size is too large, maximum 3MB",
        (value) => !value || (value && value.size <= 3 * 1024 * 1024) // 3MB
    )
    .test(
        "fileType",
        "Unsupported File Format",
        (value) =>
            !value ||
            (value &&
                [
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ].includes(value.type))
    );

export const administrationFileDataValidation = Yup.object({
    cv: fileValidation,
    lastDiplomaCertificate: fileValidation,
    supportingCertificate: fileValidation,
});
