import * as Yup from "yup";

export const workPreferencesDataInitialValue = {
    reason: "",
    salaryExpectation: "",
    expectedallowances: "",
    expectedfacilities: "",
    additionalInformation: "",
};

export const workPreferencesDataValidation = Yup.object({
    reason: Yup.string().required("Reason is required"),
    salaryExpectation: Yup.string().required("Salary Expectation is required"),
    expectedallowances: Yup.string().required(
        "Expected allowances is required"
    ),
    expectedfacilities: Yup.string().required(
        "Expected facilities is required"
    ),
    additionalInformation: Yup.string().required(
        " Additional Information is required"
    ),
});
