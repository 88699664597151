import React from "react";
import Table from "../../components/Table";
import { dataDummyRequest, dummyDepartement, dummyDivisi, dummyPosisi, educationOptionsDummy, majorOptionsDummy } from "../DummyData";
import Button from "../../components/button/button.component";
import useService from "./service";
import "./style.scss";
import Modal from "../../components/modal/modal.component";
import useModal from "../../components/modal/modal.service";
import { FormikProvider } from "formik";
import FormDropdown from "../../components/form-dropdown/form-dropdown.component";
import FormInput from "../../components/form-input/form-input.component";

const Requests = () => {
  const modalService = useModal();
  const { filterInput, tableHeight, handleFilterChange, columns, createUser } = useService()


  return (
    <>
      <div className="content-page">
        <div className="content-page-header row">
          <div className="content-page-title col-6">Applicant Request</div>
          <div className=" col-6 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => { modalService.openModalHandling() }}
              classNames={"btn-add-user"}
              label="Add Request"
            />
            <div className="form-control input-search d-flex">
              <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Enter a keyword to search"
              />
              <div className="d-flex">
                <div className="vertical" />
                <i className="ri-search-line" />
              </div>
            </div>
            <div className="icon-filter-page">
              <i className="ri-filter-line" />
            </div>
          </div>
        </div>
        <Table
          height={tableHeight - 40}
          columns={columns}
          data={dataDummyRequest}
        />
        <Modal
          modalService={modalService}
          className={"modal-tracking"}
          maxWidth="md">
          <div>
            <div>
              <div className="px-3 pt-3 d-flex justify-content-between border-bottom pb-3">
                <span className="fw-bold">Edit Request</span>
                <i className="ri-close-line" onClick={() => modalService.closeModalHandling()} />
              </div>
              <div className="px-3 pb-3 mt-3">
                <FormikProvider value={createUser}>
                  <div className="row">
                    <FormDropdown
                      isSearchable
                      required
                      options={dummyDepartement}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select departement"}
                      label="Departement"
                      name="departement"
                    />
                    <FormDropdown
                      isSearchable
                      required
                      options={dummyDivisi}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select Division"}
                      label="Division"
                      name="division"
                    />
                    <FormDropdown
                      isSearchable
                      required
                      options={dummyPosisi}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select Potition"}
                      label="Potition"
                      name="potition"
                    />
                  </div>
                  <div className="row">
                    <FormDropdown
                      isSearchable
                      required
                      options={[
                        {
                          label: 'Male',
                          value: 'Male'
                        },
                        {
                          label: 'Female',
                          value: 'Female'
                        }
                      ]}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select Gender"}
                      label="Gender"
                      name="gender"
                    />
                    <FormInput
                      placeholder={"Enter your Place of Birth"}
                      label="Age"
                      type="text"
                      name="age"
                      descValueSuffix={"Th"}
                      formClassContainer={'col-4'}
                    />
                    <FormDropdown
                      isSearchable
                      required
                      options={educationOptionsDummy}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select Education"}
                      label="Education"
                      name="education"
                    />
                  </div>
                  <div className="row">
                    <FormDropdown
                      isSearchable
                      required
                      options={majorOptionsDummy}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select Major"}
                      label="Major"
                      name="major"
                    />
                    <FormInput
                      placeholder={"Enter your Place of Birth"}
                      label="Experience"
                      type="text"
                      name="experience"
                      descValueSuffix={"Th"}
                      formClassContainer={'col-4'}
                    />
                    <FormDropdown
                      isSearchable
                      required
                      options={[
                        {
                          label: 'Amin',
                          value: 'Amin'
                        },
                        {
                          label: 'Budi',
                          value: 'Budi'
                        },
                        {
                          label: 'Citra',
                          value: 'Citra'
                        },
                        {
                          label: 'Eko',
                          value: 'Eko'
                        },
                        {
                          label: 'Hendra',
                          value: 'Hendra'
                        }
                      ]}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Select User"}
                      label="User"
                      name="user"
                    />
                  </div>
                  <div className="row">
                    <FormDropdown
                      isSearchable
                      required
                      isMultiSelect={true}
                      options={dummyPosisi}
                      useBorder={true}
                      dropDownIndicator={true}
                      formClassContainer={'col-4'}
                      placeholder={"Other Request"}
                      label="Other Request"
                      name="otherRequest"
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-end mt-5">
                    <Button
                      onClick={() => { }}
                      label="Submit Request"
                    />
                  </div>
                </FormikProvider>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Requests;
