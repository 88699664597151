import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";

const McuModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('fit');

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };
    return (
        <FormikProvider value={formik}>
            <Expand title="MCU" desc="Choose applicant MCU result" icon={"archive-fill"} status={false}>
                <HeaderExpand id="MCU">
                    <div>
                        <div className="text-content-modal-header">Choose Applicant MCU Result</div>
                        <div className="recommendation-switch">
                            <input
                                type="radio"
                                id="fit"
                                value="fit"
                                checked={recommendation === 'fit'}
                                onChange={handleChange}
                            />
                            <label htmlFor="fit" className="recommendation-label">
                                Fit
                            </label>
                            <input
                                type="radio"
                                id="unfit"
                                value="unfit"
                                checked={recommendation === 'unfit'}
                                onChange={handleChange}
                            />
                            <label htmlFor="unfit" className="recommendation-label">
                                UnFit
                            </label>
                        </div>
                    </div>
                </HeaderExpand>

                <FormTextArea
                    placeholder={"Give notes to applicants"}
                    label="Note"
                    name="note"
                />
            </Expand>
        </FormikProvider>

    );
};

export default McuModal;
