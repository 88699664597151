import React from "react";
import "./style.scss";

const Stepper = ({ steps, currentStep }) => {
    return (
        <div className="stepper">
            {steps.map((step, index) => (
                <div key={index} className="stepper-step">
                    <div
                        className={`stepper-circle ${index < currentStep
                            ? "completed"
                            : index === currentStep
                                ? "active"
                                : ""
                            }`}
                    >
                        {index < currentStep ? (
                            <i className="ri-check-fill" />
                        ) : (
                            <span>{index + 1}</span>
                        )}
                    </div>
                    <p className="stepper-label">{step.label}</p>
                    {index < steps.length - 1 && <div className="stepper-line" />}
                </div>
            ))}
        </div>
    );
};

export default Stepper;
