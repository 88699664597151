import React from 'react';
import { pdfDummy } from '../../DummyData';

const BioDataComponent = () => {
    const {
        name, noKtp, gender, nationality, placeOfBirth, religion, marriageStatus,
        height, weight, noBpjsHealth, noBpjsEmployment, noNpwp, addressAccordingToKtp,
        currentAddress, personalContactData, linkProfileSocialMedia, educationData,
        experienceData, coursesData, workPreferencesData
    } = pdfDummy;

    return (
        <div id="biodata-container" className="biodata-container">
            <div className='title-biodata'>BIO DATA</div>

            <div className="section">
                <div className='desc-title-biodata'>PERSONAL INFORMATION</div>
                <div className="personal-info">
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Personal Data</div>
                        <div className='content-personal-info'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='label-biodata'>Name  <span>{name}</span></div>
                                    <div className='label-biodata'>No KTP  <span>{noKtp}</span></div>
                                    <div className='label-biodata'>Gender  <span>{gender}</span></div>
                                    <div className='label-biodata'>Nationality  <span>{nationality}</span></div>
                                    <div className='label-biodata'>Place of Birth  <span>{placeOfBirth}</span></div>
                                    <div className='label-biodata'>Religion  <span>{religion}</span></div>
                                </div>
                                <div className='col-6'>
                                    <div className='label-biodata'>Marital Status  <span>{marriageStatus}</span></div>
                                    <div className='label-biodata'>Height  <span>{height}</span></div>
                                    <div className='label-biodata'>Weight  <span>{weight}</span></div>
                                    <div className='label-biodata'>No BPJS Health  <span>{noBpjsHealth}</span></div>
                                    <div className='label-biodata'>No BPJS Employment  <span>{noBpjsEmployment}</span></div>
                                    <div className='label-biodata'>No NPWP  <span>{noNpwp}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Personal Contact Data</div>
                        <div className='content-personal-info'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='label-biodata'>E-mail  <span>{personalContactData.email}</span></div>
                                    <div className='label-biodata'>Whatsapp  <span>{personalContactData.whatsappNumber}</span></div>
                                    <div className='label-biodata'>Phone  <span>{personalContactData.callPhoneNumber}</span></div>
                                </div>
                                <div className='col-6'>
                                    <div className='label-biodata'>Facebook  <span>{linkProfileSocialMedia.facebook}</span></div>
                                    <div className='label-biodata'>Instagram  <span>{linkProfileSocialMedia.instagram}</span></div>
                                    <div className='label-biodata'>Linkedin  <span>{linkProfileSocialMedia.linkedin}</span></div>
                                    <div className='label-biodata'>Twitter  <span>{linkProfileSocialMedia.twitter}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Address According to KTP</div>
                        <div className='content-personal-info'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='label-biodata'>Province  <span>{addressAccordingToKtp.province}</span></div>
                                    <div className='label-biodata'>City / District  <span>{addressAccordingToKtp.cityOrDistrict}</span></div>
                                    <div className='label-biodata'>Village  <span>{addressAccordingToKtp.village}</span></div>
                                </div>
                                <div className='col-6'>
                                    <div className='label-biodata'>RT  <span>{addressAccordingToKtp.rt}</span></div>
                                    <div className='label-biodata'>RW  <span>{addressAccordingToKtp.rw}</span></div>
                                    <div className='label-biodata'>Postal Code  <span>{addressAccordingToKtp.postalCode}</span></div>
                                </div>
                            </div>
                            <div className='label-biodata d-flex flex-column'>Address<span>{addressAccordingToKtp.address}</span></div>
                        </div>
                    </div>
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Current Address </div>
                        <div className='content-personal-info'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='label-biodata'>Province  <span>{currentAddress.province}</span></div>
                                    <div className='label-biodata'>City / District  <span>{currentAddress.cityOrDistrict}</span></div>
                                    <div className='label-biodata'>Village  <span>{currentAddress.village}</span></div>
                                </div>
                                <div className='col-6'>
                                    <div className='label-biodata'>RT  <span>{currentAddress.rt}</span></div>
                                    <div className='label-biodata'>RW  <span>{currentAddress.rw}</span></div>
                                    <div className='label-biodata'>Postal Code  <span>{currentAddress.postalCode}</span></div>
                                </div>
                            </div>
                            <div className='label-biodata d-flex flex-column'>Address<span>{addressAccordingToKtp.address}</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className='desc-title-biodata'>EDUCATIONAL BACKGROUND</div>
                <table className='table-biodata '>
                    <thead>
                        <tr>
                            <th>Education Level</th>
                            <th>Institution</th>
                            <th>Major</th>
                            <th>GPA</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Additional Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        {educationData.map((edu, index) => (
                            <tr key={index}>
                                <td>{edu.educationLevel}</td>
                                <td>{edu.institution}</td>
                                <td>{edu.major}</td>
                                <td>{edu.gpa}</td>
                                <td>{edu.startDate}</td>
                                <td>{edu.endDate}</td>
                                <td>{edu.additionalInformation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="section">
                <div className='desc-title-biodata'>WORK EXPERIENCE</div>
                <table className='table-biodata '>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Company</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Additional Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        {experienceData.map((exp, index) => (
                            <tr key={index}>
                                <td>{exp.position}</td>
                                <td>{exp.company}</td>
                                <td>{exp.startDate}</td>
                                <td>{exp.endDate}</td>
                                <td>{exp.additionalInformation}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="section">
                <div className='desc-title-biodata'>COURSES DATA</div>
                <table className='table-biodata '>
                    <thead>
                        <tr>
                            <th>Course Name</th>
                            <th>Organizer</th>
                            <th>Year</th>
                            <th>Duration</th>
                            <th>Certificate No</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coursesData.map((course, index) => (
                            <tr key={index}>
                                <td>{course.coursesName}</td>
                                <td>{course.organizer}</td>
                                <td>{course.year}</td>
                                <td>{course.duration}</td>
                                <td>{course.noCertificate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="section">
                <div className='desc-title-biodata'>WORK PREFERENCES</div>
                
                <div>
                    <p>Salary Expectation: {workPreferencesData.salaryExpectation}</p>
                    <p>Expected allowances: {workPreferencesData.expectedAllowances}</p>
                    <p>Expected facilities: {workPreferencesData.expectedFacilities}</p>
                </div>
                <div className="personal-info">
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Reason / Purpose for working in this company</div>
                        <div className='content-personal-info'>
                            <div className='label-biodata'><span>{workPreferencesData.reasonPurposeForWorkingInThisCompany}</span></div>
                        </div>
                    </div>
                    <div className='personal-info-content'>
                        <div className='personal-info-content-title'>Additional Information</div>
                        <div className='content-personal-info'>
                            <div className='label-biodata'><span>{workPreferencesData.additionalInformation}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BioDataComponent;
