import React, { useEffect } from "react";
import "./style.scss";
import PieChartComponent from "../../components/rechart/piechart.component";
import Ilustration from "../../assets/img/ilustrasion/hero-illustration.png";
import Calendar from "../../components/calender/calender.component";
import { useNavigate } from "react-router-dom";
import Attention from "./components/attention-modal.component";
import useModal from "../../components/modal/modal.service";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyPageProfile } from "../../store/storeGlobal";
import imgHome from "../../assets/img/Rectangle100.png";

const Home = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.user);
    const modalService = useModal();

    useEffect(() => {
        if (user?.data.role === 'Regular') {
            modalService.openModalHandling();
        }
    }, [user?.data.role]);

    return (
        <>
            {user?.data.role === 'Regular' && (
                <div className="container content-home">
                    <div className="card-hero">
                        <img
                            src={Ilustration}
                            alt="ilustrasion"
                            className="avatar-image"
                        />
                        <div className="content-card-text">
                            <div className="card-text">
                                <div className="card-hero-name">Hi, Hanjarraes</div>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur. Fermentum
                                    venenatis morbi tellus facilisi cursus pulvinar
                                    lacinia.
                                </p>
                            </div>
                            <PieChartComponent
                                percentage={775}
                                desc="Complete your profile"
                            />
                        </div>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="me-3 w-100">
                            <div className="card-text">History Status</div>
                            <div className="card-home"></div>
                        </div>
                        <div className="content-calender-home">
                            <div className="card-text">Operational Calendar</div>
                            <div className="card-home py-3 px-3">
                                <Calendar />
                            </div>
                        </div>
                    </div>
                    <Attention
                        modalService={modalService}
                        onSubmit={() => {
                            dispatch(
                                setPropertyPageProfile({
                                    isCurrentProfile: true,
                                    isSaveAll: false,
                                    canEdit: true,
                                })
                            );
                            nav("/profile");
                        }}
                    />
                </div>
            )}
            {user?.data.role === 'Admin' && (
                <>
                    <div className="home-admin">
                        <img src={imgHome} alt='logoIWap' className="background-image" />
                        <div className="content">
                            <div className="title">PT Indonesia Weda Bay Industrial Prak</div>
                            <div className="description">
                                Kawasan Industri Weda Bay merupakan Kawasan Industri pertama<br />
                                terintegrasi di Indonesia yang diperuntukkan untuk memfasilitasi proses <br />
                                pengolahan mineral dan produksi komponen baterai kendaraan listrik
                            </div>
                        </div>
                    </div>
                </>

            )}
        </>
    );
};

export default Home;
