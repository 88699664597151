import { useRef, useState } from "react";
import "./input.style.scss";

const Input = ({
    disabled = false,
    readonly = false,
    required = false,
    parentDivClassname,
    type = "text",
    ...props
}) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const showLabelClass = !props.label || props.label === "" ? "d-hidden" : "";
    const focusClass = isFocused ? "focus" : "";
    const isDisabled = disabled ? "disabled" : "";
    const handleValue = isDisabled && !props.value ? "-" : props.value;

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const showPasswordInfo = props.name === "password" || props.name === "confirmPassword"

    return (
        <div className={`${parentDivClassname} parent-input w-100`}>
            <label className={`${showLabelClass}`}>
                <div>
                    {props.label}
                    {required && <span className={`required`}>*</span>}
                </div>
            </label>
            <div
                className={`${props.className} ${isDisabled} ${focusClass} form-control container-input`}
            >
                {props?.descValuePrefix && (
                    <span className="desc-value desc-value-prefix">
                        {props?.descValuePrefix}
                    </span>
                )}
                <input
                    {...props}
                    ref={inputRef}
                    disabled={disabled}
                    placeholder={props.placeholder}
                    type={showPasswordInfo && showPassword ? "text" : type}
                    readOnly={readonly}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                        if (props.onChange) props.onChange(e);
                    }}
                    value={handleValue}
                />
                {props?.descValueSuffix && (
                    <span className="desc-value desc-value-suffix">
                        {props?.descValueSuffix}
                    </span>
                )}
                {showPasswordInfo && (
                    <button
                        type="button"
                        className="password-toggle"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? (
                            <i className="ri-eye-line" />
                        ) : (
                            <i className="ri-eye-off-line" />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Input;
