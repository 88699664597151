import React from "react";
import AvatarCircle from "../../../components/avatar-cirlce/avatar-cirle.component";
import Profile from "../../../assets/img/photo-user.png";
import "../style.scss";

import useService from "../serive";

const ModalContent = ({ modalService }) => {
    const { 
        allContent,
    } = useService()
    return (
        <>
            <div className="content-modal-tracking">
                <div className="px-3 pt-3 d-flex justify-content-between">
                    <span className="modal-tracking-title">Tracking</span>
                    <i className="ri-close-line" onClick={() => modalService.closeModalHandling()} />
                </div>
                <hr />
                <div className="row p-3">
                    <div className="col-6 ">
                        <div className="body-card p-3">
                            <div className="modal-tracking-title mb-3 mt-2">Profile Overview</div>
                            <AvatarCircle name={"hanjarraes@gmail.com"} size={100} src={Profile} />
                            <div className="row body-profile">
                                <div className="col-6">
                                    <div className="body-title">Name</div>
                                    <div className="body-desc">Alif Sahputra</div>
                                </div>
                                <div className="col-6">
                                    <div className="body-title">KTP</div>
                                    <div className="body-desc">217108890004</div>
                                </div>
                            </div>
                            <div className="row body-profile">
                                <div className="col-6">
                                    <div className="body-title">Gender</div>
                                    <div className="body-desc">Man</div>
                                </div>
                                <div className="col-6">
                                    <div className="body-title">Nationality</div>
                                    <div className="body-desc">Indonesia</div>
                                </div>
                            </div>
                            <div className="row body-profile">
                                <div className="col-6">
                                    <div className="body-title">Place of Birth</div>
                                    <div className="body-desc">Batam City</div>
                                </div>
                                <div className="col-6">
                                    <div className="body-title">Place of Date</div>
                                    <div className="body-desc">28/11/2001</div>
                                </div>
                            </div>
                            <div className="row body-profile">
                                <div className="col-6">
                                    <div className="body-title">Religion</div>
                                    <div className="body-desc">Islam</div>
                                </div>
                                <div className="col-6">
                                    <div className="body-title">Marriage Status</div>
                                    <div className="body-desc">Single</div>
                                </div>
                            </div>
                            <div className="row body-profile">
                                <div className="col-6">
                                    <div className="body-title">Height</div>
                                    <div className="body-desc">171</div>
                                </div>
                                <div className="col-6">
                                    <div className="body-title">Weight Body</div>
                                    <div className="body-desc">84</div>
                                </div>
                            </div>
                            <div className="donwload-cv mt-3">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="body-card body-card-extand">
                            {allContent}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalContent;
