import React from "react";
import "../style.scss";
import Profile from "../../../assets/img/photo-user.png";
import AvatarCircle from "../../../components/avatar-cirlce/avatar-cirle.component";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import FormDropdown from "../../../components/form-dropdown/form-dropdown.component.jsx";
import { FormikProvider } from "formik";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import { personalDataInitialValue } from "../../../form-validations/profile/personal-data.validation.js";

const PersonalData = ({
    formik,
    data,
    setPersonalDataValues,
    isSaveAll,
    isCurrentProfile,
    canEdit,
}) => {
    const colInputClass = "col-12 col-lg-6";
    console.log("canEdit", canEdit);

    return (
        <>
            <div className="d-flex align-items-center w-100">
                <AvatarCircle name={"hanjarraes@gmail.com"} src={Profile} />
                <div className="ms-4 d-flex justify-content-between w-100">
                    <div>
                        <button className="btn me-3 btn-profil" type="submit">
                            Change Picture
                        </button>
                        <button
                            className="btn btn-profil delete-btn"
                            type="submit"
                        >
                            Dalete Picture
                        </button>
                    </div>
                    {formik?.isSubmitting && !isSaveAll && (
                        <div className="">
                            <Button
                                label={"Edit Data"}
                                variant="yellow"
                                onClick={() => {
                                    setPersonalDataValues(
                                        personalDataInitialValue
                                    );
                                    formik?.setSubmitting(false);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <FormikProvider value={formik}>
                <div style={{ marginTop: "3rem" }}>
                    <div className="row">
                        <FormInput
                            placeholder={"Enter your Name"}
                            label="Name"
                            name={"name"}
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                        <FormInput
                            placeholder={"Enter your No KTP"}
                            label="No KTP"
                            name={"nik"}
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                    </div>
                    <div className="row mt-3">
                        <FormDropdown
                            options={[
                                { label: "Male", value: "Male" },
                                { label: "Female", value: "Female" },
                            ]}
                            useBorder={true}
                            dropDownIndicator={true}
                            placeholder={"Select Gender"}
                            label="Gander"
                            name="gender"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                        <FormDropdown
                            options={[
                                { label: "Indonesian", value: "Indonesian" },
                            ]}
                            useBorder={true}
                            dropDownIndicator={true}
                            placeholder={"Select Nationality"}
                            label="Nationality"
                            name="nationality"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                    </div>
                    <div className="row mt-3">
                        <FormInput
                            placeholder={"Enter your Place of Birth"}
                            label="Place of Birth"
                            name="tempat_lahir"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                        <FormDatePicker
                            name="tanggal_lahir"
                            label="Place Of Date"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                    </div>
                    <div className="row mt-3">
                        <FormDropdown
                            options={[
                                { label: "Islam", value: "Islam" },
                                {
                                    label: "Christianity",
                                    value: "Christianity",
                                },
                                {
                                    label: "Catholic Church",
                                    value: "Catholic Church",
                                },
                                { label: "Buddhism", value: "Buddhism" },
                            ]}
                            useBorder={true}
                            dropDownIndicator={true}
                            placeholder={"Select Religion"}
                            label="Religion"
                            name="religion"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                        <FormDropdown
                            options={[
                                { label: "Married", value: "Married" },
                                { label: "Not Married", value: "Not Married" },
                            ]}
                            useBorder={true}
                            dropDownIndicator={true}
                            placeholder={"Select Marrige Status"}
                            label="Marrige Status"
                            name="status_pernikahan"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                            required
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <FormInput
                        placeholder={"Enter your Place of Birth"}
                        label="Height"
                        type="number"
                        name="height"
                        formClassContainer={colInputClass}
                        disabled={formik?.isSubmitting || !canEdit}
                        descValueSuffix={"Cm"}
                        required
                    />
                    <FormInput
                        placeholder={"Enter your Place of Birth"}
                        label="Weight"
                        type="number"
                        name="weight_body"
                        formClassContainer={colInputClass}
                        disabled={formik?.isSubmitting || !canEdit}
                        descValueSuffix={"Kg"}
                        required
                    />
                </div>
                <hr style={{ marginTop: "4rem", marginBottom: "3rem" }} />
                <div>
                    <div className="row mt-3">
                        <FormInput
                            placeholder={"Enter your No BPJS Health"}
                            label="No BPJS Health"
                            name="no_bpjs_health"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                        />
                        <FormInput
                            placeholder={"Enter your No BPJS Employment"}
                            label="No BPJS Employment"
                            name="no_bpjs_employment"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                        />
                    </div>
                    <div className="row mt-3">
                        <FormInput
                            placeholder={"Enter your No NPWP"}
                            label="No NPWP"
                            name="no_npwp"
                            disabled={formik?.isSubmitting || !canEdit}
                            formClassContainer={colInputClass}
                        />
                    </div>
                    {!formik.isSubmitting && canEdit && (
                        <Button
                            label={" Save Change"}
                            onClick={formik?.submitForm}
                            classNames={"float-end my-5"}
                        />
                    )}
                </div>
            </FormikProvider>
        </>
    );
};

export default PersonalData;
