import React from "react";
import Home from "../pages/Home";
// import Registration from "../pages/Registration";
import ApplicantProcess from "../pages/ApplicantProcess";
import LayoutEvent from "../components/layout/layout-event.component";
import Tracking from "../pages/Tracking";
import TalentPool from "../pages/TalentPool";
import UserManagement from "../pages/UserManagement";
import Requests from "../pages/Request";

const appRoutes = [
    {
        path: "/",
        element: <Home />,
        state: "home",
        sidebarProps: {
            displayText: "Home",
            icon: <i className="ri-home-line" />,
        },
    },
    // {
    //   path: "/guide-registration",
    //   element: <Registration />,
    //   state: "guide-registration",
    //   sidebarProps: {
    //     displayText: "Guide Registration",
    //   }
    // },
    {
        path: "/recruitment",
        element: <LayoutEvent />,
        state: "recruitment",
        sidebarProps: {
            displayText: "Recruitment",
            icon: <i className="ri-group-line" />,
        },
        child: [
            {
                path: "/recruitment/talentPool",
                element: <TalentPool />,
                state: "recruitment.talentPool",
                sidebarProps: {
                    displayText: "Talent Pool",
                    icon: <i className="ri-arrow-drop-right-line" />,
                },
            },
            {
                path: "/recruitment/ProcessTracking",
                element: <Tracking />,
                state: "recruitment.ProcessTracking",
                sidebarProps: {
                    displayText: "Process Tracking",
                    icon: <i className="ri-arrow-drop-right-line" />,
                },
            },
        ],
    },
    {
        path: "/request",
        element: <Requests />,
        state: "request",
        sidebarProps: {
            displayText: "Request",
            icon: <i className="ri-upload-2-line" />,
        },
    },
    {
        path: "/user-management",
        element: <UserManagement />,
        state: "user-management",
        sidebarProps: {
            displayText: "User Management",
            icon: <i className="ri-user-line" />,
        },
    },
    // {
    //   path: "/guide-registration",
    //   element: <Registration />,
    //   state: "guide-registration",
    //   sidebarProps: {
    //     displayText: "Guide Registration",
    //   },
    // },
    {
        path: "/applicant-process",
        element: <ApplicantProcess />,
        state: "applicant-process",
        sidebarProps: {
            displayText: "Applicant Process",
        },
    },
    {
        path: "/history-status",
        element: <Home />,
        state: "history-status",
        sidebarProps: {
            displayText: "History Status",
        },
    },
];

export const getRoutesForRole = (role) => {
    if (role === "Admin") {
        return appRoutes.filter(
            (route) =>
                route.path !== "/guide-registration" &&
                route.path !== "/applicant-process" &&
                route.path !== "/history-status" 
        );
    } else if (role === "Regular") {
        return appRoutes.filter(
            (route) =>
                route.path === "/" ||
                route.path === "/guide-registration" ||
                route.path === "/applicant-process" ||
                route.path === "/history-status"
        );
    }
    return [];
};

export default appRoutes;
