import { useState } from "react";
import PreProcessed from "./content/pre-processed";
import Processed from "./content/processed";
import Rejected from "./content/rejected";
import Hired from "./content/hired";
import { setPropertyPageProfile } from "../../store/storeGlobal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfimationModal from "./components/confirmation-modal.component";
import useModal from "../../components/modal/modal.service";
import { dataDummyTracking } from "../DummyData";
import { toast } from "react-toastify";

const useService = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // states
    const [filterInput, setFilterInput] = useState("");
    const [contentActive, setContentActive] = useState("Pre-Processed");
    const [talentPoolData, setTalentPoolData] = useState();
    // Pre-Processed
    const [talentPreProcessedData, setTalentPreProcessedData] =
        useState(dataDummyTracking);
    // Processed
    const [talentProcessedData, setTalentProcessedData] =
        useState(dataDummyTracking);
    // Rejected
    const [talentRejectedData, setTalentRejectedData] =
        useState(dataDummyTracking);
    // Hired
    const [talentHiredData, setTalentHiredData] = useState(dataDummyTracking);

    // modals
    const confirmationModal = useModal();

    // general functions
    const handleFilterChange = (e) => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };
    const handlePropertyPageProfile = (canEdit) => {
        dispatch(
            setPropertyPageProfile({
                isCurrentProfile: false,
                isSaveAll: true,
                canEdit,
            })
        );
        navigate("/profile");
    };
    const removeTalentProcessedData = () => {
        let updateData;
        switch (contentActive) {
            case "Pre-Processed":
                updateData = talentPreProcessedData?.filter(
                    (item) =>
                        item?.applicant_id !== talentPoolData?.applicant_id
                );
                setTalentPreProcessedData(updateData);
                break;
            case "Processed":
                updateData = talentProcessedData?.filter(
                    (item) =>
                        item?.applicant_id !== talentPoolData?.applicant_id
                );
                setTalentProcessedData(updateData);
                break;
            case "Rejected":
                updateData = talentRejectedData?.filter(
                    (item) =>
                        item?.applicant_id !== talentPoolData?.applicant_id
                );
                setTalentRejectedData(updateData);
                break;
            case "Hired":
                updateData = talentHiredData?.filter(
                    (item) =>
                        item?.applicant_id !== talentPoolData?.applicant_id
                );
                setTalentHiredData(updateData);
                break;
            default:
        }

        toast.success("Successfully Removed Data");
    };

    // components
    const loadContents = () => {
        switch (contentActive) {
            case "Pre-Processed":
                return (
                    <PreProcessed
                        onClickRemove={(data) => {
                            setTalentPoolData(data);
                            confirmationModal.openModalHandling();
                        }}
                        data={talentPreProcessedData}
                    />
                );
            case "Processed":
                return (
                    <Processed
                        handlePropertyPageProfile={handlePropertyPageProfile}
                        onClickRemove={(data) => {
                            setTalentPoolData(data);
                            confirmationModal.openModalHandling();
                        }}
                        data={talentProcessedData}
                    />
                );
            case "Rejected":
                return (
                    <Rejected
                        data={talentRejectedData}
                        handlePropertyPageProfile={handlePropertyPageProfile}
                        onClickRemove={(data) => {
                            setTalentPoolData(data);
                            confirmationModal.openModalHandling();
                        }}
                    />
                );
            case "Hired":
                return (
                    <Hired
                        data={talentHiredData}
                        handlePropertyPageProfile={handlePropertyPageProfile}
                        onClickRemove={(data) => {
                            setTalentPoolData(data);
                            confirmationModal.openModalHandling();
                        }}
                    />
                );
            default:
                <></>;
        }
    };

    const allModal = (
        <>
            <ConfimationModal
                modalService={confirmationModal}
                onSubmit={removeTalentProcessedData}
            />
        </>
    );

    return {
        filterInput,
        contentActive,
        allModal,
        loadContents,
        handleFilterChange,
        setContentActive,
        setFilterInput,
    };
};

export default useService;
