import "./style.scss";
import Logo from "../../assets/img/Logo/logo.png";
import Profile from "../../assets/img/photo-user.png";
import AvatarCircle from "../avatar-cirlce/avatar-cirle.component";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { setUser } from "../../store/storeLogin";
import { getRoutesForRole } from "../../routes/appRoutes";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Service";
import { setPropertyPageProfile } from "../../store/storeGlobal";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.login.user);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const filteredRoutes = getRoutesForRole(user?.data?.role);
    const handleLogout = async () => {
        await logoutUser({ dispatch, setData: setUser, navigate });
    };

    const menuRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpenUserMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="header-layout">
                <div className="d-flex align-items-center">
                    <img src={Logo} alt="logoIWap" className="logo-header" />
                    {user?.data?.role === "Regular" ? (
                        <>
                            <div className="line-vertical-header-layout" />
                            <div className="navbar-header">
                                {filteredRoutes.map((data, idx) => {
                                    return (
                                        <span
                                            key={`item-nav-${idx}`}
                                            className={
                                                data.path ===
                                                    location.pathname &&
                                                "active"
                                            }
                                            onClick={() => {
                                                navigate(data.path);
                                            }}
                                        >
                                            {data.sidebarProps.displayText}
                                        </span>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="header-title">
                            Recruitment Management
                        </div>
                    )}
                </div>
                <div
                    className="d-flex align-items-center"
                    onClick={() => {
                        setOpenUserMenu(!openUserMenu);
                    }}
                >
                    <AvatarCircle
                        name={"hanjarraes@gmail.com"}
                        src={Profile}
                        size={40}
                    />
                    <div className="content-profile">
                        <div className="content-profile-name">Hanjarraes</div>
                        <div className="content-profile-email">
                            hanjarraes@gmail.com
                        </div>
                    </div>
                    <i
                        className={`ri-arrow-down-s-line ${
                            openUserMenu ? "open" : ""
                        }`}
                    />
                </div>
            </div>
            <PopupUserMenu
                menuRef={menuRef}
                isOpen={openUserMenu}
                funcProfile={() => {
                    dispatch(
                        setPropertyPageProfile({
                            isCurrentProfile: true,
                            isSaveAll: false,
                            canEdit: true,
                        })
                    );
                    navigate("/profile");
                }}
                funcLogout={() => {
                    handleLogout();
                }}
                setCloseUserMenu={() => {
                    setOpenUserMenu(false);
                }}
            />
        </>
    );
};

export default Header;

export const PopupUserMenu = ({
    isOpen,
    funcLogout,
    funcProfile,
    menuRef,
    setCloseUserMenu,
}) => {
    const showClass = isOpen
        ? " opacity-100 visible duration-300"
        : "opacity-0 invisible ! mt-[3.5rem]";

    return (
        <>
            <div
                ref={menuRef}
                className={`popup-user-menu ${showClass} themes-header`}
            >
                <div
                    className="menu-item themes-header-profile-menu"
                    onClick={() => {
                        funcProfile();
                        setCloseUserMenu();
                    }}
                >
                    <i className="ri-user-line"></i>
                    Profile
                </div>
                <div
                    className="menu-item themes-header-profile-menu"
                    onClick={() => {
                        funcLogout();
                        setCloseUserMenu();
                    }}
                >
                    <i className="ri-logout-box-r-line" />
                    Log Out
                </div>
            </div>
        </>
    );
};
