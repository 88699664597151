import { useFormik } from "formik";
import useToast from "../../components/toast/toast.service";
import ScreeningModal from "./content/screening.component";
import PsychologicalModal from "./content/psychological.component";
import { screeningInitialValue, screeningValidation } from "../../form-validations/process-tracking/screening";
import UserInterviewModal from "./content/user-interview.component";
import BanefitOfferModal from "./content/banefit-offer.component";
import OnboardingModal from "./content/onboarding.component";
import McuModal from "./content/mcu.component";
import HiredModal from "./content/hired.component";
import RejectedModal from "./content/rejected.component";


const useService = () => {
    // states
    const toastService = useToast();

    // formik
    const personalDataformik = useFormik({
        initialValues: screeningInitialValue,
        validationSchema: screeningValidation,
        onSubmit: () => {
        },
    });

    const allContent = (() => {
        return (
            <>
                <ScreeningModal formik={personalDataformik} />
                <PsychologicalModal formik={personalDataformik} />
                <UserInterviewModal formik={personalDataformik} />
                <BanefitOfferModal formik={personalDataformik} />
                <OnboardingModal formik={personalDataformik} />
                <McuModal formik={personalDataformik} />
                <HiredModal formik={personalDataformik} />
                <RejectedModal formik={personalDataformik} />
            </>
        );
    })();

    return {
        allContent,
        toastService,
    };
};

export default useService;
