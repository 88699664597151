import React, { useRef } from "react";
import "./style.scss";

const Textarea = ({
    disabled = false,
    readonly = false,
    required = false,
    parentDivClassName,
    ...props
}) => {
    const textareaRef = useRef(null);
    const showLabelClass = !props.label || props.label === "" ? "d-hidden" : "";
    const isDisabled = disabled ? "disabled" : "";
    const handleValue = isDisabled && !props.value ? "-" : props.value;

    return (
        <div className={`${parentDivClassName} container-textarea flex`}>
            <label className={`${showLabelClass}`}>{props.label}</label>
            <textarea
                {...props}
                ref={textareaRef}
                disabled={disabled}
                className={`${props.className} ${isDisabled} form-control`}
                placeholder={props.placeholder}
                value={handleValue}
                readOnly={readonly}
                onChange={(e) => {
                    if (props.onChange) props.onChange(e);
                }}
            />
        </div>
    );
};

export default Textarea;
