import * as Yup from "yup";

export const signUpInitialValue = {
    nik: "",
    name: "",
    email: "",
    password: "",
    confirmmPassword: "",
    phone: "",
};

export const signUpValidation = Yup.object({
    nik: Yup.string()
        .matches(/^\d+$/, "NIK must be a number")
        .length(16, "NIK must be exactly 16 characters") 
        .required("NIK is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match password"
        )
        .required("Confirm password is required"),
    phone: Yup.string().required("Phone Number is required"),
});
