import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import 'remixicon/fonts/remixicon.css';
import { format, addMonths, subMonths } from 'date-fns';
import "./style.scss";

const MyCalendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const handlePrevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };




    const monthName = format(currentMonth, 'LLLL');
    const year = format(currentMonth, 'yyyy');

    return (
        <div className='calender-custom'>
            <div className='content-calender'>
                <div className='calender-header'>
                    <i className="ri-arrow-left-s-line" onClick={handlePrevMonth}></i>
                    <div className='text-calender-header'>{monthName}, <span>{year}</span></div>
                    <i className="ri-arrow-right-s-line" onClick={handleNextMonth} ></i>
                </div>
                <DayPicker
                    mode="single"
                    selected={new Date()}
                    captionLayout="dropdown"
                    month={currentMonth}
                    disableNavigation
                    showOutsideDays
                />
            </div>
            <div className='text-info-calender'>Libur Nasional </div>
        </div>
    );
};

export default MyCalendar;
