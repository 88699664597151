import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs.coomponent";
import imgContentTime from "../../assets/img/Icon/timer.png";
import { itemApplicantProcess } from "./applicant-process.static";
import "./style.scss";
import PieChartComponent from "../../components/rechart/piechart.component";
import FormUploadFile from "../../components/form-upload-file/form-upload-file.component";
import { FormikProvider, useFormik } from "formik";
import { administrationFileDataInitialValue, administrationFileDataValidation } from "../../form-validations/profile/administration-file-data.validation";
import Button from "../../components/button/button.component";
import Stepper from "../../components/stepper-step";
import imgPlane from "../../assets/img/Icon/plane.png";

const ApplicantProcess = () => {
    const [contentVisible, setContentVisible] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentStepOnboarding, setCurrentStepOnboarding] = useState(0);
    const isScreening = false
    const isPsychological = true

    const [isContent, setIsContent] = useState({
        name: 'Screening',
        title: 'Your Profile is Complete!',
        img: imgContentTime,
        desc: 'Your profile is complete and now that you are at the screening phase, wait a moment for instructions.',
        statusContent: 'success'
    });

    const steps = [
        { label: "Administration File", completed: true },
        { label: "Benefit Offer", completed: false }
    ];

    const stepsOnboarding = [
        { label: "Offering Letter", completed: true },
        { label: "Identity Card & Tickets", completed: false }
    ];

    useEffect(() => {
        setContentVisible(true);
    }, [isContent]);

    const administrationFileDataformik = useFormik({
        initialValues: administrationFileDataInitialValue,
        validationSchema: administrationFileDataValidation,
        onSubmit: () => {

        },
    });

    const handleApplicantProcessItemClick = (data) => {
        setContentVisible(false);
        setTimeout(() => {
            const item = {
                name: data.name,
                title: data.title1,
                desc: data.desc1,
                statusContent: 'success',
                img: data.img1
            }
            if (data.name === 'Psychological Test') {
                item.title = isPsychological ? data.title1 : data.titl21
                item.desc = isPsychological ? data.desc1 : data.desc2
                item.statusContent = isPsychological ? 'fail' : 'success'
                item.img = isPsychological ? data.img1 : data.img2
            }

            setIsContent(item)
        }, 200);
    };

    const contentStatus = () => {
        return (
            <div className="content-center-ApplicantProcess">
                <img src={isContent.img} alt='logoIWap' className="img-content" />
                <div className="title-content-ApplicantProcess pt-5 pb-2">{isContent.title}</div>
                <div className="desc-content-ApplicantProcess"> {isContent.desc}</div>
                {isContent.name === 'Psychological Test' && isPsychological && (
                    <button className="btn  btn-primary-small mt-3" type="submit">
                        Taking Tests
                    </button>
                )}
            </div>
        )
    }

    const ContentHired = () => {
        return (
            <div className="content-center-ApplicantProcess">
                <img src={isContent.img} alt='logoIWap' className="img-content" />
                <div className="title-content-ApplicantProcess pt-5 pb-2">{isContent.title}</div>
                <div className="desc-content-ApplicantProcess"> {isContent.desc}</div>
            </div>
        )
    }

    const ContentOnboarding = () => {
        return (
            <div className="content-center-ApplicantProcess">
                <Stepper steps={stepsOnboarding} currentStep={currentStepOnboarding} />
                {currentStepOnboarding === 0 && (
                    <FormikProvider value={administrationFileDataformik}>
                        <div className="row w-100 mx-5 px-5">
                            <div className="col-12">
                                <div className="donwload-cv mt-3 my-5">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <div className="donwload-cv-title"> Download Offering Letter</div>
                                            <div className="donwload-cv-desc">offering_letter_erfan_yeager.pdf</div>
                                        </div>
                                    </div>
                                    <i className="ri-download-line" />
                                </div>
                            </div>

                            <div className="col-12">
                                <FormUploadFile
                                    label={"Offeriang Letter"}
                                    name={"offeriangLetter"}
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-100 justify-content-end">
                            <Button
                                label={"Next"}
                                onClick={() => { setCurrentStepOnboarding(1) }}
                                classNames={"float-end my-5"}
                            />
                        </div>
                    </FormikProvider>
                )}
                {currentStepOnboarding === 1 && (
                    <FormikProvider value={administrationFileDataformik}>
                        <div className="row w-100 mx-5 px-5">
                            <div className="col-12 text-center my-2">
                                <img src={imgPlane} alt='logoIWap' className="img-content my-4" />
                                <div className="title text-center">
                                    Upload Your Airplane <br />Ticket or Pick-up
                                </div>
                            </div>

                            <div className="col-12">
                                <FormUploadFile
                                    label={"Offeriang Letter"}
                                    name={"offeriangLetter"}
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-100 justify-content-end">
                            <Button
                                label={"Send"}
                                onClick={() => { setCurrentStepOnboarding(2) }}
                                classNames={"float-end my-5"}
                            />
                        </div>
                    </FormikProvider>
                )}
                      {currentStepOnboarding === 2 && ( 
                        contentStatus()
                      )}
            </div>
        )
    }

    const ContentUserInterview = () => {
        return (
            <div className="content-center-ApplicantProcess">
                <div className="title-content">Interview Schedule</div>
                <div className="content-interview">
                    <div className="header-interview">
                        <i className="ri-live-line" />
                        <div>
                            <div className="title-header">Zoom Interview</div>
                            <div className="desc-header">Confirm your attendance!</div>
                        </div>
                    </div>
                    <div className="body-interview">
                        <div className="item-interview">
                            <div className="interview-title"><i className="me-2 ri-calendar-line" />Day, Date</div>
                            <div className="interview-desc">Tuesday, 12 July 2024</div>
                        </div>
                        <div className="item-interview">
                            <div className="interview-title"><i className="me-2 ri-time-line" />Time</div>
                            <div className="interview-desc">10:00-11:00 WITA</div>
                        </div>
                        <div className="item-interview">
                            <div className="interview-title"><i className="me-2 ri-door-lock-line" />Meeting ID</div>
                            <div className="interview-desc">709 0224 7662</div>
                        </div>
                        <div className="item-interview">
                            <div className="interview-title"><i className="me-2 ri-key-line" />Key</div>
                            <div className="interview-desc">1987600</div>
                        </div>
                    </div>
                    <div className="footer-interview">
                        <button className="btn me-2 btn-delete-small w-full" type="submit">
                            Absent
                        </button>
                        <button className="btn btn-primary-small w-full" type="submit">
                            Attending
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const ContentBenefit = () => {
        return (
            <div className="content-center-ApplicantProcess">
                {/* <div className="title-content">Interview Schedule</div> */}
                <Stepper steps={steps} currentStep={currentStep} />
                {currentStep === 0 && (
                    <FormikProvider value={administrationFileDataformik}>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <FormUploadFile label={"CV"} name={"cv"} required />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Formal Photos"}
                                    name={"formalPhotos"}

                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Identity Card"}
                                    name={"identityCard"}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Family Card"}
                                    name={"familyCard"}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Last Diploma Certificate"}
                                    name={"lastDiplomaCertificate"}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Grade Transcript"}
                                    name={"gradeTranscript"}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormUploadFile
                                    label={"Supporting Certificate"}
                                    name={"supportingCertificate"}
                                    required
                                />
                            </div>
                        </div>
                        <div className="w-100 justify-content-end">
                            <Button
                                label={"Next"}
                                onClick={() => { setCurrentStep(1) }}
                                classNames={"float-end my-5"}
                            />
                        </div>
                    </FormikProvider>
                )}
                {currentStep === 1 && (
                    <div className="content-interview">
                        <div className="header-interview">
                            <i className="ri-live-line" />
                            <div>
                                <div className="title-header">Zoom Interview</div>
                                <div className="desc-header">Confirm your attendance!</div>
                            </div>
                        </div>
                        <div className="body-interview">
                            <div className="item-interview">
                                <div className="interview-title"><i className="me-2 ri-calendar-line" />Day, Date</div>
                                <div className="interview-desc">Tuesday, 12 July 2024</div>
                            </div>
                            <div className="item-interview">
                                <div className="interview-title"><i className="me-2 ri-time-line" />Time</div>
                                <div className="interview-desc">10:00-11:00 WITA</div>
                            </div>
                            <div className="item-interview">
                                <div className="interview-title"><i className="me-2 ri-door-lock-line" />Meeting ID</div>
                                <div className="interview-desc">709 0224 7662</div>
                            </div>
                            <div className="item-interview">
                                <div className="interview-title"><i className="me-2 ri-key-line" />Key</div>
                                <div className="interview-desc">1987600</div>
                            </div>
                        </div>
                        <div className="footer-interview">
                            <button className="btn me-2 btn-delete-small w-full" type="submit">
                                Absent
                            </button>
                            <button className="btn btn-primary-small w-full" type="submit" >
                                Attending
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const ContentScreening = () => {
        return (
            <div className="content-center-ApplicantProcess">
                <PieChartComponent percentage={775} />
                <div className="title-content">Complate Your Profile</div>
                <div className="desc-content pt-1 pb-3">
                    You are currently in the screening phase, please complete your <br /> profile to be selected for the next phase.
                </div>
                <button className="btn me-3 btn-primary-small" type="submit">
                    Profile Page
                </button>
            </div>
        )
    }

    const renderContent = () => {
        switch (isContent.name) {
            case 'Screening':
                return isScreening ? contentStatus() : ContentScreening()
            case 'Psychological Test':
                return contentStatus()
            case 'User Interview':
                return ContentUserInterview();
            case 'Benefit Offer':
                return ContentBenefit();
            case 'Hired':
                return ContentHired();
            case 'Onboarding':
                return ContentOnboarding();
            default:
                return contentStatus();
        }
    };

    return (
        <div className="container">
            <Breadcrumbs />
            <div className="ApplicantProcess">
                <div className="ApplicantProcess-header">
                    <div className="ApplicantProcess-title">Applicant Process</div>
                </div>
                <div className="ApplicantProcess-body">
                    <div className="ApplicantProcess-nav">
                        {itemApplicantProcess.map((data, idx) => {
                            return (
                                <>
                                    <div
                                        key={`item-ApplicantProcess-${idx}`}
                                        className={`ApplicantProcess-nav-item ${data.status === 'success' && 'complate-ApplicantProcess'} ${data.status === 'next' && 'next-ApplicantProcess'}`}
                                        onClick={() => handleApplicantProcessItemClick(data)}
                                    >
                                        <div className="ApplicantProcess-step">
                                            <span className={`step-number`}>
                                                {data.status === 'success' ? <i class="ri-check-line" /> : idx + 1}
                                            </span>
                                        </div>
                                        <div className="ApplicantProcess-content">
                                            {data.name}
                                        </div>
                                    </div>
                                    {idx !== itemApplicantProcess.length - 1 && <div className={`vertical-nav-ApplicantProcess ${data.status === 'complate' && 'vertical-nav-ApplicantProcess-complate'}`} />}
                                </>
                            )
                        })}
                    </div>
                    <div className={`ApplicantProcess-content ${!contentVisible && 'hidden'}`}>
                        {renderContent()}
                    </div>
                </div>
            </div>
            <div className="copy-right">© 2024 PT Indonesia Weda Bay Industrial Park. All Rights Reserved</div>
        </div>
    );
};

export default ApplicantProcess;
