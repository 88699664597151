import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import sizeConfigs from "../../configs/sizeConfigs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { setUser } from "../../store/storeLogin";
import Header from "../../components/layout/header.component";
import { Box } from "@mui/material";
import Sidebar from "../../components/layout/sidebar.component";

const TheLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.login.user);

    if (user) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios.defaults.paramsSerializer = { indexes: null };
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common.Accept = "application/json";
        axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
        axios.defaults.timeout = 500000;
        axios.interceptors.request.use((request) => request);

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response?.status === 403) {
                    dispatch(setUser(null));
                    toast.error("sorry session expired 😥");
                    navigate("/login");
                }
                return Promise.reject(error);
            }
        );
    }

    useEffect(() => {
        if (user) {
        } else {
            navigate("/login");
        }
    }, [user, navigate]);


    return (
        <div className="content-layout">
            <div className="d-flex d-lg-none burger-mobile">
                <i className="ri-menu-line" />
            </div>
            <Header />
            {user?.data?.role === "Regular" ? (
                <div className="container container-main">
                    <Outlet />
                </div>
            ) : (
                <Box sx={{ display: "flex" }}>
                    <Box
                        className="d-none d-lg-flex"
                        component="nav"
                        sx={{
                            width: sizeConfigs.sidebar.width,
                            flexShrink: 0,
                        }}
                    >
                        <Sidebar />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                            minHeight: "100vh",
                        }}
                    >
                        <div className="container-main">
                            <Outlet />
                        </div>
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default TheLayout;
