import React from "react";

const CardAdministrationFile = ({ data }) => {
    return (
        <div className="row">
            <div className=" col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <>
                        <i class="ri-file-pdf-2-line "></i>
                        <div className="description">
                            <div className="title">CV</div>
                            <div className="value">{data?.cv?.name}</div>
                        </div>
                    </>

                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Formal Photos</div>
                        <div className="value">{data?.formalPhotos?.name}</div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Identity Card</div>
                        <div className="value">{data?.identityCard?.name}</div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Family Card</div>
                        <div className="value">{data?.familyCard?.name}</div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Last Diploma Certificate</div>
                        <div className="value">
                            {data?.lastDiplomaCertificate?.name}
                        </div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Grade Transcript</div>
                        <div className="value">
                            {data?.gradeTranscript?.name}
                        </div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
            <div className="col-12 col-lg-6  mt-3">
                <div className="card-administration-file">
                    <i class="ri-file-pdf-2-line "></i>
                    <div className="description">
                        <div className="title">Supporting Certificate</div>
                        <div className="value">
                            {data?.supportingCertificate?.name}
                        </div>
                    </div>
                    <i class="ri-download-line"></i>
                </div>
            </div>
        </div>
    );
};

export default CardAdministrationFile;
