import * as Yup from "yup";

export const contactDataInitialValue = {
    email: "",
    callPhoneNumber: "",
    whatsAppNumber: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    linkedinLink: "",
};

export const contactDataValidation = Yup.object({
    email: Yup.string().required("E-mail is required"),
    callPhoneNumber: Yup.string().required("Call Phone Number is required"),
    whatsAppNumber: Yup.string().required("WhatsApp Number is required"),
});

export const emergencyContactDataInitialValue = {
    id: 0,
    name: "",
    relationship: "",
    noTelephone: "",
};

export const emergencyContactDataValidation = Yup.object({
    name: Yup.string().required("Name is required"),
    relationship: Yup.string().required("Relationship is required"),
    noTelephone: Yup.string().required("No Telephones is required"),
});
