import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import FormUploadFile from "../../../components/form-upload-file/form-upload-file.component.jsx";

const PsychologicalModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('recommendation');

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };
    return (
        <FormikProvider value={formik}>
            <Expand title="Psychological" desc="Applicant phase of psychological test" icon={"archive-fill"} status>
                <HeaderExpand id="Psychological">
                    <div className="w-100 me-5">
                        <FormUploadFile
                           className={"uploud-file-text"}
                            label={"Upload Result"}
                            name={"file"}
                        />
                    </div>
                </HeaderExpand>
                <div className="recommendation-switch">
                    <input
                        type="radio"
                        id="recommendation"
                        value="recommendation"
                        checked={recommendation === 'recommendation'}
                        onChange={handleChange}
                    />
                    <label htmlFor="recommendation" className="recommendation-label">
                        Recommendation
                    </label>
                    <input
                        type="radio"
                        id="noRecommendation"
                        value="noRecommendation"
                        checked={recommendation === 'noRecommendation'}
                        onChange={handleChange}
                    />
                    <label htmlFor="noRecommendation" className="recommendation-label">
                        No Recommendation
                    </label>
                </div>
                <FormTextArea
                    placeholder={"Give notes to applicants"}
                    label="Note"
                    name="note"
                />
            </Expand>
        </FormikProvider>

    );
};

export default PsychologicalModal;
