import * as Yup from "yup";

export const educationDataInitialValue = {
    id: 0,
    educationLevel: "",
    institution: "",
    major: "",
    gpa: "",
    startDate: "",
    endDate: "",
    additionalInformation: "",
};

export const educationDataValidation = Yup.object({
    educationLevel: Yup.string().required("Education Level is required"),
    institution: Yup.string().required("Institution Number is required"),
    major: Yup.string().required("Major is required"),
    gpa: Yup.string().required("GPA is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
});
