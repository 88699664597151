import { useState } from "react";

const useModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModalHandling = () => setModalOpen(true);
  const closeModalHandling = () => setModalOpen(false);
  const toggleModalHandling = () => setModalOpen(!isModalOpen);

  return {
    isModalOpen,
    openModalHandling,
    closeModalHandling,
    toggleModalHandling,
  };
};

export default useModal;
