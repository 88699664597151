import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import BioDataComponent from "../component/biodata-component.jsx";
import Modal from "../../../components/modal/modal.component.jsx";
import useModal from "../../../components/modal/modal.service.jsx";

const ScreeningModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('recommendation');

    const exportPDF = () => {
        const input = document.getElementById('biodata-container');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
    
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
    
            const ratio = canvasWidth / pdfWidth;
            const pdfCanvasHeight = canvasHeight / ratio;
    
            let position = 0;
    
            while (position < pdfCanvasHeight) {
                pdf.addImage(
                    imgData,
                    'PNG',
                    0,
                    -position,
                    pdfWidth,
                    pdfCanvasHeight
                );
                position += pdfHeight;
                if (position < pdfCanvasHeight) {
                    pdf.addPage();
                }
            }
            pdf.save('biodata lah.pdf');
        });
    };

    const modalBiodata = useModal();

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };
    return (
        <FormikProvider value={formik}>
            <Modal
                modalService={modalBiodata}
                className={"Biodata"}
                maxWidth="lg">
                <div onClick={exportPDF}>Export</div>
                <BioDataComponent />
            </Modal>

            <Expand title="Screening" desc="Applicant phase to complete profile" icon={"archive-fill"} status>
                <HeaderExpand id="Screening">
                    <div className="header-screening">
                        <div className="title">100% Complete</div>
                        <div className="desc">Applicants have completed a profile, <span onClick={() => modalBiodata.openModalHandling()} >see profile</span></div>
                    </div>
                </HeaderExpand>
                <div className="recommendation-switch">
                    <input
                        type="radio"
                        id="recommendation"
                        value="recommendation"
                        checked={recommendation === 'recommendation'}
                        onChange={handleChange}
                    />
                    <label htmlFor="recommendation" className="recommendation-label">
                        Recommendation
                    </label>
                    <input
                        type="radio"
                        id="noRecommendation"
                        value="noRecommendation"
                        checked={recommendation === 'noRecommendation'}
                        onChange={handleChange}
                    />
                    <label htmlFor="noRecommendation" className="recommendation-label">
                        No Recommendation
                    </label>
                </div>
                <FormTextArea
                    placeholder={"Give notes to applicants"}
                    label="Note"
                    name="note"
                />
            </Expand>
        </FormikProvider>

    );
};

export default ScreeningModal;
