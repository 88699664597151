import React from "react";
import { ListItemButton } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";

const SidebarItem = ({ item }) => {
    const appState = useSelector((state) => state.global.appState);

    return (
        item.sidebarProps && item.path ? (
            <ListItemButton
                className="item-sidebar-custom"
                component={Link}
                to={item.path}
                sx={{
                    "&:hover": {
                        backgroundColor: colorConfigs.sidebar.hoverBg,
                        color: colorConfigs.sidebar.activecolor
                    },
                    color: appState === item.state ? colorConfigs.sidebar.activecolor : "unset",
                    backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
                    paddingY: "12px",
                    paddingX: "24px"
                }}
            >

                {item.sidebarProps.icon && item.sidebarProps.icon}
                {item.sidebarProps.displayText}
            </ListItemButton>
        ) : null
    );
};

export default SidebarItem;