import React, { useState } from "react";
import "../style.scss";

const HeaderExpand = ({ children, id }) => {
    const [isStatus, setIsStatus] = useState(false);

    return (
        <>
            <div className="header-expand-content">
                <>{children}</>
                <div className="d-flex">
                    <input
                        type="checkbox"
                        id={"switch" + id}
                        className="switch"
                        checked={isStatus}
                        onChange={() => setIsStatus(!isStatus)}
                    />
                    <label htmlFor={"switch" + id} className="label-switch">
                        Toggle
                    </label>
                </div>
            </div>
        </>
    );
};

export default HeaderExpand;
