import React, { useEffect, useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormDropdown from "../../../components/form-dropdown/form-dropdown.component.jsx";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import moment from "moment";

const EducationData = ({
    formik,
    data,
    removeData,
    isSaveAll,
    isEducationForm,
    setIsEducationForm,
    canEdit,
}) => {
    const formateDate = (startDate, endDate) => {
        const formattedStartDate = moment(startDate).format("MMM YYYY");
        const formattedEndDate = moment(endDate).format("MMM YYYY");
        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="content-profile-title">Education Data</div>
                {!isEducationForm && !isSaveAll && canEdit && (
                    <Button
                        label={"Add Education"}
                        onClick={() => {
                            setIsEducationForm(true);
                        }}
                    />
                )}
            </div>
            {!isEducationForm ? (
                <div className="row" key={"card"}>
                    {data?.map((item) => (
                        <div className={`col-12 col-lg-6 mt-3 `}>
                            <div
                                className="card-information"
                                style={{ minHeight: "125px" }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="card-title">
                                        {item?.institution}
                                    </div>
                                    <div className="d-flex gap-2">
                                        <Button
                                            icon="ri-pencil-line"
                                            classNames="button-icon"
                                            variant="yellow"
                                            onClick={() => {
                                                formik?.setValues(item);
                                                setIsEducationForm(true);
                                            }}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                        <Button
                                            icon="ri-delete-bin-7-line"
                                            classNames="button-icon"
                                            variant="red"
                                            onClick={() => removeData(item?.id)}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                    </div>
                                </div>
                                <div className="card-details">
                                    <div>{item?.major}</div>
                                    <div>
                                        {formateDate(
                                            item?.startDate,
                                            item?.endDate
                                        )}
                                    </div>
                                </div>
                                <div>GPA: {item?.gpa}</div>
                                <div>{item?.additionalInformation}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <FormikProvider value={formik}>
                    <div key={"form"} className="">
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormDropdown
                                    options={[
                                        {
                                            label: "Bachelor's Degree",
                                            value: "Bachelor's Degree",
                                        },
                                    ]}
                                    useBorder={true}
                                    dropDownIndicator={true}
                                    placeholder={"Select Education Level"}
                                    label="Education Level"
                                    name="educationLevel"
                                    disabled={data?.educationLevel}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormDropdown
                                    options={[
                                        {
                                            label: "University of Example",
                                            value: "University of Example",
                                        },
                                    ]}
                                    useBorder={true}
                                    dropDownIndicator={true}
                                    placeholder={"Select Institution"}
                                    label="Institution"
                                    name="institution"
                                    disabled={data?.institution}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormDropdown
                                    options={[
                                        {
                                            label: "Computer Science",
                                            value: "Computer Science",
                                        },
                                    ]}
                                    useBorder={true}
                                    dropDownIndicator={true}
                                    placeholder={"Select Major"}
                                    label="Major"
                                    name="major"
                                    disabled={data?.major}
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"Use dot as decimal separator"}
                                    label="GPA"
                                    name="gpa"
                                    disabled={data?.gpa}
                                    required
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between w-100 me-4">
                                <div className="w-100 mt-3">
                                    <FormDatePicker
                                        label="Start Date"
                                        name="startDate"
                                        disabled={data?.startDate}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <div className="w-100 mt-3">
                                    <FormDatePicker
                                        label="End Date"
                                        name="endDate"
                                        disabled={data?.endDate}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <FormTextArea
                                    placeholder={
                                        "Enter your Additional Information"
                                    }
                                    label="Additional Information"
                                    name="additionalInformation"
                                    disabled={data?.additionalInformation}
                                />
                            </div>
                        </div>
                        {isEducationForm && (
                            <Button
                                label={"Save Change"}
                                onClick={async () => {
                                    if (formik?.isValid) formik?.submitForm();
                                }}
                                classNames={"float-end my-5"}
                            />
                        )}
                    </div>
                </FormikProvider>
            )}
        </>
    );
};

export default EducationData;
