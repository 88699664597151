import React from "react";
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import "./style.scss";

const Table = ({ columns, data, height }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Default page size
    },
    useGlobalFilter,
    usePagination
  );
  return (
    <>
      <div className="table-container" style={{ height: `calc(${height}px)` }}>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <span className="desc-paging me-2">
          Row Per Page :
        </span>
        <select
          className="select-paging"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <span className="desc-paging mx-4">
          {pageIndex + 1} - {pageOptions.length} of {data.length}
        </span>
        <div className="arrow-icon">
          <i onClick={() => canPreviousPage && previousPage()} className="ri-arrow-left-s-line arrow-paging" />
          <i onClick={() => canNextPage && nextPage()} className="ri-arrow-right-s-line arrow-paging" />
        </div>
      </div>
    </>
  );
};

export default Table;
