import { ErrorMessage, useField } from "formik";
import DatePicker from "../date-picker/data-picker.component";

const FormDatePicker = ({
    hideError = false,
    formClassContainer,
    ...props
}) => {
    const [field, meta, helpers] = useField(props?.name);
    // const borderColorClass = meta.error && meta.touched ? "border-red" : "";
    const isError = !(!meta.error || (meta.error && !meta.touched));
    const errorClassMessage = isError && !hideError ? "" : "hidden";

    return (
        <div className={`${formClassContainer}`}>
            <div className="flex relative">
                <DatePicker
                    {...props}
                    selectedDate={field.value}
                    setSelectedDate={(value) => {
                        helpers.setValue(value);
                    }}
                />
            </div>
            <div className={`text-error ${errorClassMessage}`}>
                <ErrorMessage name={props?.name} component="p" />
            </div>
        </div>
    );
};

export default FormDatePicker;
