export const dataDummy = [
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
    {
        applicant_id: "IDC00001",
        origin: "Jakarta",
        pic: "Alif Shaputra",
        tracking: "Screening",
        name: "Ahmad Prayoga",
        division: "Port Service",
        experience: "12 Years",
        last_education: "Strata 1",
        attachments: "CV_Ahmad_Prayoga",
        status: "Screening",
    },
];

export const pdfDummy = {
    img: "../../../components/avatar-circle/avatar-circle.component",
    name: "John Doe",
    noKtp: "1234567890123456",
    gender: "Male",
    nationality: "Indonesian",
    placeOfBirth: "Jakarta",
    religion: "Islam",
    marriageStatus: "Single",
    height: "175 cm",
    weight: "70 kg",
    noBpjsHealth: "9876543210",
    noBpjsEmployment: "0123456789",
    noNpwp: "123456789012345",

    addressAccordingToKtp: {
        province: "DKI Jakarta",
        cityOrDistrict: "Central Jakarta",
        village: "Gambir",
        rt: "01",
        rw: "02",
        postalCode: "10110",
        address: "Jl. Medan Merdeka Barat No.3-5",
    },

    currentAddress: {
        province: "DKI Jakarta",
        cityOrDistrict: "South Jakarta",
        village: "Kebayoran Baru",
        rt: "03",
        rw: "04",
        postalCode: "12160",
        address: "Jl. Sudirman No.10",
    },

    personalContactData: {
        email: "johndoe@example.com",
        callPhoneNumber: "08123456789",
        whatsappNumber: "08123456789",
    },

    linkProfileSocialMedia: {
        facebook: "facebook.com/johndoe",
        instagram: "instagram.com/johndoe",
        twitter: "twitter.com/johndoe",
        linkedin: "linkedin.com/in/johndoe",
    },

    educationData: [
        {
            educationLevel: "Bachelor's Degree",
            institution: "University of Indonesia",
            major: "Computer Science",
            gpa: "3.8",
            startDate: "2015-08-01",
            endDate: "2019-07-31",
            additionalInformation: "Graduated with honors",
        },
        {
            educationLevel: "Master's Degree",
            institution: "Bandung Institute of Technology",
            major: "Information Systems",
            gpa: "3.9",
            startDate: "2020-08-01",
            endDate: "2022-07-31",
            additionalInformation: "Thesis on Machine Learning",
        },
    ],

    experienceData: [
        {
            position: "Software Engineer",
            company: "ABC Tech",
            startDate: "2019-09-01",
            endDate: "2022-08-31",
            additionalInformation: "Developed various web applications",
        },
        {
            position: "Senior Developer",
            company: "XYZ Solutions",
            startDate: "2022-09-01",
            endDate: "Present",
            additionalInformation: "Leading a team of developers",
        },
    ],

    coursesData: [
        {
            coursesName: "Full-Stack Web Development",
            organizer: "FreeCodeCamp",
            year: "2018",
            duration: "6 months",
            noCertificate: "FC123456",
        },
        {
            coursesName: "Data Science",
            organizer: "Coursera",
            year: "2021",
            duration: "3 months",
            noCertificate: "CO789012",
        },
    ],

    workPreferencesData: {
        reasonPurposeForWorkingInThisCompany:
            "To contribute to innovative projects",
        salaryExpectation: "IDR 15,000,000 per month",
        expectedAllowances: "Health insurance, transportation allowance",
        expectedFacilities: "Laptop, flexible working hours",
        additionalInformation: "Open to relocation",
    },
};

export const majorOptionsDummy = [
  { label: "Computer Science", value: "cs" },
  { label: "Electrical Engineering", value: "ee" },
  { label: "Mechanical Engineering", value: "me" },
  { label: "Business Administration", value: "ba" },
  { label: "Psychology", value: "psy" }
];

export const educationOptionsDummy = [
  { label: "High School", value: "hs" },
  { label: "Bachelor's Degree", value: "bachelor" },
  { label: "Master's Degree", value: "master" },
  { label: "Doctorate", value: "phd" },
  { label: "Associate Degree", value: "associate" }
];

 
export const personalDummy = {
    name: "John Doe",
    nik: "1234567890123456",
    gender: "Male",
    nationality: "Indonesian",
    tempat_lahir: "Jakarta",
    tanggal_lahir: "2024-07-31T17:00:00.000Z",
    religion: "Islam",
    status_pernikahan: "Married",
    height: "175",
    weight_body: "70",
    no_bpjs_health: "BPJS123456789",
    no_bpjs_employment: "BPJS987654321",
    no_npwp: "NPWP123456789012",
};

export const addressDummy = {
    province: "82",
    regency: "8271",
    village: "8271010",
    rt: "01",
    rw: "02",
    postal_code: "10310",
    address: "Jl. Merdeka No. 1, Pulau Ternate, Ternate",
    c_province: "82",
    c_regency: "8271",
    c_village: "8271010",
    c_rt: "01",
    c_rw: "02",
    c_postalCode: "10310",
    c_address: "Jl. Merdeka No. 1, Pulau Ternate, Ternate",
};

export const contactDummy = {
    email: "john.doe@example.com",
    callPhoneNumber: "+62 812 3456 7890",
    whatsAppNumber: "+62 812 3456 7890",
    facebookLink: "https://www.facebook.com/johndoe",
    twitterLink: "https://twitter.com/johndoe",
    instagramLink: "https://www.instagram.com/johndoe",
    linkedinLink: "https://www.linkedin.com/in/johndoe",
};

export const educationDummy = [
    {
        id: 1,
        educationLevel: "Bachelor's Degree",
        institution: "University of Example",
        major: "Computer Science",
        gpa: "3.75",
        startDate: "2024-07-31T17:00:00.000Z",
        endDate: "2024-07-31T17:00:00.000Z",
        additionalInformation: "Graduated with honors",
    },
];

export const experienceDummy = [
    {
        id: 1,
        position: "Software Engineer",
        company: "Tech Solutions Inc.",
        startDate: "2024-07-31T17:00:00.000Z",
        endDate: "2024-07-31T17:00:00.000Z",
        additionalInformation:
            "Worked on various web development projects and led a team of developers.",
    },
];

export const coursesDummy = [
    {
        id: 1,
        coursesName: "Full-Stack Web Development",
        organizer: "Tech Academy",
        startDate: "2024-07-31T17:00:00.000Z",
        endDate: "2024-07-31T17:00:00.000Z",
        noCertificate: "FA1234567890",
    },
];

export const workPreferencesDummy = {
    reason: "Looking for career growth and new challenges.",
    salaryExpectation: "IDR 15,000,000 - 20,000,000 per month",
    expectedallowances: "Health insurance, transportation allowance",
    expectedfacilities: "Flexible working hours, remote work option",
    additionalInformation: "Open to relocation and travel opportunities.",
};

export const fileDummy = {
    cv: new File(["This is the CV content"], "cv.pdf", {
        type: "application/pdf",
    }),
    formalPhotos: new File([""], "formalPhoto.png", { type: "image/png" }),
    identityCard: new File(
        ["This is the identity card content"],
        "identityCard.jpg",
        { type: "image/jpeg" }
    ),
    familyCard: new File(
        ["This is the family card content"],
        "familyCard.jpg",
        { type: "image/jpeg" }
    ),
    lastDiplomaCertificate: new File(
        ["This is the diploma certificate"],
        "diploma.pdf",
        { type: "application/pdf" }
    ),
    gradeTranscript: new File(
        ["This is the grade transcript"],
        "transcript.pdf",
        { type: "application/pdf" }
    ),
    supportingCertificate: new File(
        ["This is a supporting certificate"],
        "certificate.pdf",
        { type: "application/pdf" }
    ),
};

export const dataDummyTracking = [
  {
    applicant_id: "APP001",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00001",
    name: "Ahmad Prayoga",
    email: "ahmad.prayoga@example.com",
    phone: "+6281234567890",
    education: "S1 Teknik Informatika",
    experience: "3 years in IT industry",
    departement: "IT",
    potition: "Software Developer",
    resume: "resume_ahmad",
    startDate: "2022-01-15",
  },
  {
    applicant_id: "APP002",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00002",
    name: "Budi Santoso",
    email: "budi.santoso@example.com",
    phone: "+6281234567891",
    education: "S1 Manajemen",
    experience: "5 years in Management",
    departement: "Management",
    potition: "Project Manager",
    resume: "resume_budi",
    startDate: "2021-06-20",
  },
  {
    applicant_id: "APP003",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00003",
    name: "Citra Lestari",
    email: "citra.lestari@example.com",
    phone: "+6281234567892",
    education: "S1 Akuntansi",
    experience: "4 years in Accounting",
    departement: "Finance",
    potition: "Accountant",
    resume: "resume_citra",
    startDate: "2020-03-10",
  },
  {
    applicant_id: "APP004",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00004",
    name: "Dewi Kartika",
    email: "dewi.kartika@example.com",
    phone: "+6281234567893",
    education: "S2 Teknik Industri",
    experience: "6 years in Industrial Engineering",
    departement: "Engineering",
    potition: "Industrial Engineer",
    resume: "resume_dewi",
    startDate: "2019-11-05",
  },
  {
    applicant_id: "APP005",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00005",
    name: "Eko Wijaya",
    email: "eko.wijaya@example.com",
    phone: "+6281234567894",
    education: "S1 Teknik Elektro",
    experience: "7 years in Electrical Engineering",
    departement: "Engineering",
    potition: "Electrical Engineer",
    resume: "resume_eko",
    startDate: "2018-09-25",
  },
  {
    applicant_id: "APP006",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00006",
    name: "Fajar Nugroho",
    email: "fajar.nugroho@example.com",
    phone: "+6281234567895",
    education: "S1 Desain Komunikasi Visual",
    experience: "2 years in Graphic Design",
    departement: "Design",
    potition: "Graphic Designer",
    resume: "resume_fajar",
    startDate: "2023-02-14",
  },
  {
    applicant_id: "APP007",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00007",
    name: "Gita Permata",
    email: "gita.permata@example.com",
    phone: "+6281234567896",
    education: "S1 Psikologi",
    experience: "5 years in Human Resources",
    departement: "HR",
    potition: "HR Manager",
    resume: "resume_gita",
    startDate: "2017-08-30",
  },
  {
    applicant_id: "APP008",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00008",
    name: "Hendra Setiawan",
    email: "hendra.setiawan@example.com",
    phone: "+6281234567897",
    education: "S1 Teknik Mesin",
    experience: "8 years in Mechanical Engineering",
    departement: "Engineering",
    potition: "Mechanical Engineer",
    resume: "resume_hendra",
    startDate: "2016-05-20",
  },
  {
    applicant_id: "APP009",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00009",
    name: "Indah Sari",
    email: "indah.sari@example.com",
    phone: "+6281234567898",
    education: "S1 Ilmu Komunikasi",
    experience: "3 years in Public Relations",
    departement: "Communications",
    potition: "Public Relations Specialist",
    resume: "resume_indah",
    startDate: "2022-07-01",
  },
  {
    applicant_id: "APP010",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00010",
    name: "Joko Susilo",
    email: "joko.susilo@example.com",
    phone: "+6281234567899",
    education: "S1 Hukum",
    experience: "10 years in Legal",
    departement: "Legal",
    potition: "Legal Advisor",
    resume: "resume_joko",
    startDate: "2014-04-15",
  },
  {
    applicant_id: "APP001",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00001",
    name: "Ahmad Prayoga",
    email: "ahmad.prayoga@example.com",
    phone: "+6281234567890",
    education: "S1 Teknik Informatika",
    experience: "3 years in IT industry",
    departement: "IT",
    potition: "Software Developer",
    resume: "resume_ahmad",
    startDate: "2022-01-15",
  },
  {
    applicant_id: "APP002",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00002",
    name: "Budi Santoso",
    email: "budi.santoso@example.com",
    phone: "+6281234567891",
    education: "S1 Manajemen",
    experience: "5 years in Management",
    departement: "Management",
    potition: "Project Manager",
    resume: "resume_budi",
    startDate: "2021-06-20",
  },
  {
    applicant_id: "APP003",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00003",
    name: "Citra Lestari",
    email: "citra.lestari@example.com",
    phone: "+6281234567892",
    education: "S1 Akuntansi",
    experience: "4 years in Accounting",
    departement: "Finance",
    potition: "Accountant",
    resume: "resume_citra",
    startDate: "2020-03-10",
  },
  {
    applicant_id: "APP004",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00004",
    name: "Dewi Kartika",
    email: "dewi.kartika@example.com",
    phone: "+6281234567893",
    education: "S2 Teknik Industri",
    experience: "6 years in Industrial Engineering",
    departement: "Engineering",
    potition: "Industrial Engineer",
    resume: "resume_dewi",
    startDate: "2019-11-05",
  },
  {
    applicant_id: "APP005",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00005",
    name: "Eko Wijaya",
    email: "eko.wijaya@example.com",
    phone: "+6281234567894",
    education: "S1 Teknik Elektro",
    experience: "7 years in Electrical Engineering",
    departement: "Engineering",
    potition: "Electrical Engineer",
    resume: "resume_eko",
    startDate: "2018-09-25",
  },
  {
    applicant_id: "APP006",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00006",
    name: "Fajar Nugroho",
    email: "fajar.nugroho@example.com",
    phone: "+6281234567895",
    education: "S1 Desain Komunikasi Visual",
    experience: "2 years in Graphic Design",
    departement: "Design",
    potition: "Graphic Designer",
    resume: "resume_fajar",
    startDate: "2023-02-14",
  },
  {
    applicant_id: "APP007",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00007",
    name: "Gita Permata",
    email: "gita.permata@example.com",
    phone: "+6281234567896",
    education: "S1 Psikologi",
    experience: "5 years in Human Resources",
    departement: "HR",
    potition: "HR Manager",
    resume: "resume_gita",
    startDate: "2017-08-30",
  },
  {
    applicant_id: "APP008",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00008",
    name: "Hendra Setiawan",
    email: "hendra.setiawan@example.com",
    phone: "+6281234567897",
    education: "S1 Teknik Mesin",
    experience: "8 years in Mechanical Engineering",
    departement: "Engineering",
    potition: "Mechanical Engineer",
    resume: "resume_hendra",
    startDate: "2016-05-20",
  },
  {
    applicant_id: "APP009",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00009",
    name: "Indah Sari",
    email: "indah.sari@example.com",
    phone: "+6281234567898",
    education: "S1 Ilmu Komunikasi",
    experience: "3 years in Public Relations",
    departement: "Communications",
    potition: "Public Relations Specialist",
    resume: "resume_indah",
    startDate: "2022-07-01",
  },
  {
    applicant_id: "APP010",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00010",
    name: "Joko Susilo",
    email: "joko.susilo@example.com",
    phone: "+6281234567899",
    education: "S1 Hukum",
    experience: "10 years in Legal",
    departement: "Legal",
    potition: "Legal Advisor",
    resume: "resume_joko",
    startDate: "2014-04-15",
  },
  {
    applicant_id: "APP001",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00001",
    name: "Ahmad Prayoga",
    email: "ahmad.prayoga@example.com",
    phone: "+6281234567890",
    education: "S1 Teknik Informatika",
    experience: "3 years in IT industry",
    departement: "IT",
    potition: "Software Developer",
    resume: "resume_ahmad",
    startDate: "2022-01-15",
  },
  {
    applicant_id: "APP002",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00002",
    name: "Budi Santoso",
    email: "budi.santoso@example.com",
    phone: "+6281234567891",
    education: "S1 Manajemen",
    experience: "5 years in Management",
    departement: "Management",
    potition: "Project Manager",
    resume: "resume_budi",
    startDate: "2021-06-20",
  },
  {
    applicant_id: "APP003",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00003",
    name: "Citra Lestari",
    email: "citra.lestari@example.com",
    phone: "+6281234567892",
    education: "S1 Akuntansi",
    experience: "4 years in Accounting",
    departement: "Finance",
    potition: "Accountant",
    resume: "resume_citra",
    startDate: "2020-03-10",
  },
  {
    applicant_id: "APP004",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00004",
    name: "Dewi Kartika",
    email: "dewi.kartika@example.com",
    phone: "+6281234567893",
    education: "S2 Teknik Industri",
    experience: "6 years in Industrial Engineering",
    departement: "Engineering",
    potition: "Industrial Engineer",
    resume: "resume_dewi",
    startDate: "2019-11-05",
  },
  {
    applicant_id: "APP005",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00005",
    name: "Eko Wijaya",
    email: "eko.wijaya@example.com",
    phone: "+6281234567894",
    education: "S1 Teknik Elektro",
    experience: "7 years in Electrical Engineering",
    departement: "Engineering",
    potition: "Electrical Engineer",
    resume: "resume_eko",
    startDate: "2018-09-25",
  },
  {
    applicant_id: "APP006",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00006",
    name: "Fajar Nugroho",
    email: "fajar.nugroho@example.com",
    phone: "+6281234567895",
    education: "S1 Desain Komunikasi Visual",
    experience: "2 years in Graphic Design",
    departement: "Design",
    potition: "Graphic Designer",
    resume: "resume_fajar",
    startDate: "2023-02-14",
  },
  {
    applicant_id: "APP007",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00007",
    name: "Gita Permata",
    email: "gita.permata@example.com",
    phone: "+6281234567896",
    education: "S1 Psikologi",
    experience: "5 years in Human Resources",
    departement: "HR",
    potition: "HR Manager",
    resume: "resume_gita",
    startDate: "2017-08-30",
  },
  {
    applicant_id: "APP008",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00008",
    name: "Hendra Setiawan",
    email: "hendra.setiawan@example.com",
    phone: "+6281234567897",
    education: "S1 Teknik Mesin",
    experience: "8 years in Mechanical Engineering",
    departement: "Engineering",
    potition: "Mechanical Engineer",
    resume: "resume_hendra",
    startDate: "2016-05-20",
  },
  {
    applicant_id: "APP009",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00009",
    name: "Indah Sari",
    email: "indah.sari@example.com",
    phone: "+6281234567898",
    education: "S1 Ilmu Komunikasi",
    experience: "3 years in Public Relations",
    departement: "Communications",
    potition: "Public Relations Specialist",
    resume: "resume_indah",
    startDate: "2022-07-01",
  },
  {
    applicant_id: "APP010",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00010",
    name: "Joko Susilo",
    email: "joko.susilo@example.com",
    phone: "+6281234567899",
    education: "S1 Hukum",
    experience: "10 years in Legal",
    departement: "Legal",
    potition: "Legal Advisor",
    resume: "resume_joko",
    startDate: "2014-04-15",
  },
  {
    applicant_id: "APP001",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00001",
    name: "Ahmad Prayoga",
    email: "ahmad.prayoga@example.com",
    phone: "+6281234567890",
    education: "S1 Teknik Informatika",
    experience: "3 years in IT industry",
    departement: "IT",
    potition: "Software Developer",
    resume: "resume_ahmad",
    startDate: "2022-01-15",
  },
  {
    applicant_id: "APP002",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00002",
    name: "Budi Santoso",
    email: "budi.santoso@example.com",
    phone: "+6281234567891",
    education: "S1 Manajemen",
    experience: "5 years in Management",
    departement: "Management",
    potition: "Project Manager",
    resume: "resume_budi",
    startDate: "2021-06-20",
  },
  {
    applicant_id: "APP003",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00003",
    name: "Citra Lestari",
    email: "citra.lestari@example.com",
    phone: "+6281234567892",
    education: "S1 Akuntansi",
    experience: "4 years in Accounting",
    departement: "Finance",
    potition: "Accountant",
    resume: "resume_citra",
    startDate: "2020-03-10",
  },
  {
    applicant_id: "APP004",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00004",
    name: "Dewi Kartika",
    email: "dewi.kartika@example.com",
    phone: "+6281234567893",
    education: "S2 Teknik Industri",
    experience: "6 years in Industrial Engineering",
    departement: "Engineering",
    potition: "Industrial Engineer",
    resume: "resume_dewi",
    startDate: "2019-11-05",
  },
  {
    applicant_id: "APP005",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00005",
    name: "Eko Wijaya",
    email: "eko.wijaya@example.com",
    phone: "+6281234567894",
    education: "S1 Teknik Elektro",
    experience: "7 years in Electrical Engineering",
    departement: "Engineering",
    potition: "Electrical Engineer",
    resume: "resume_eko",
    startDate: "2018-09-25",
  },
  {
    applicant_id: "APP006",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00006",
    name: "Fajar Nugroho",
    email: "fajar.nugroho@example.com",
    phone: "+6281234567895",
    education: "S1 Desain Komunikasi Visual",
    experience: "2 years in Graphic Design",
    departement: "Design",
    potition: "Graphic Designer",
    resume: "resume_fajar",
    startDate: "2023-02-14",
  },
  {
    applicant_id: "APP007",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00007",
    name: "Gita Permata",
    email: "gita.permata@example.com",
    phone: "+6281234567896",
    education: "S1 Psikologi",
    experience: "5 years in Human Resources",
    departement: "HR",
    potition: "HR Manager",
    resume: "resume_gita",
    startDate: "2017-08-30",
  },
  {
    applicant_id: "APP008",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00008",
    name: "Hendra Setiawan",
    email: "hendra.setiawan@example.com",
    phone: "+6281234567897",
    education: "S1 Teknik Mesin",
    experience: "8 years in Mechanical Engineering",
    departement: "Engineering",
    potition: "Mechanical Engineer",
    resume: "resume_hendra",
    startDate: "2016-05-20",
  },
  {
    applicant_id: "APP009",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00009",
    name: "Indah Sari",
    email: "indah.sari@example.com",
    phone: "+6281234567898",
    education: "S1 Ilmu Komunikasi",
    experience: "3 years in Public Relations",
    departement: "Communications",
    potition: "Public Relations Specialist",
    resume: "resume_indah",
    startDate: "2022-07-01",
  },
  {
    applicant_id: "APP010",
    origin: "Jakarta",
    pic: "Alif Shaputra",
    tracking: "Screening",
    nik: "IDC00010",
    name: "Joko Susilo",
    email: "joko.susilo@example.com",
    phone: "+6281234567899",
    education: "S1 Hukum",
    experience: "10 years in Legal",
    departement: "Legal",
    potition: "Legal Advisor",
    resume: "resume_joko",
    startDate: "2014-04-15",
  },
];

export const dataDummyUser = [
  {
    userId: "APP001",
    username: "Ahmad",
    name: "Ahmad Prayoga",
    email: "ahmad.prayoga@example.com",
    departement: "Software",
    divisi: "IT",
    potition: "Software Developer",
  },
  {
    userId: "APP002",
    username: "Budi",
    name: "Budi Santoso",
    email: "budi.santoso@example.com",
    departement: "Software",
    divisi: "IT",
    potition: "Frontend Developer",
  },
  {
    userId: "APP003",
    username: "Citra",
    name: "Citra Dewi",
    email: "citra.dewi@example.com",
    departement: "Marketing",
    divisi: "Digital",
    potition: "Content Strategist",
  },
  {
    userId: "APP004",
    username: "Dian",
    name: "Dian Wulandari",
    email: "dian.wulandari@example.com",
    departement: "HR",
    divisi: "Talent Acquisition",
    potition: "Recruiter",
  },
  {
    userId: "APP005",
    username: "Eka",
    name: "Eka Suryani",
    email: "eka.suryani@example.com",
    departement: "Finance",
    divisi: "Accounting",
    potition: "Accountant",
  },
  {
    userId: "APP006",
    username: "Fajar",
    name: "Fajar Ramadhan",
    email: "fajar.ramadhan@example.com",
    departement: "IT",
    divisi: "Support",
    potition: "IT Support",
  },
  {
    userId: "APP007",
    username: "Gita",
    name: "Gita Larasati",
    email: "gita.larasati@example.com",
    departement: "Software",
    divisi: "IT",
    potition: "Backend Developer",
  },
  {
    userId: "APP008",
    username: "Hadi",
    name: "Hadi Kurniawan",
    email: "hadi.kurniawan@example.com",
    departement: "Operations",
    divisi: "Logistics",
    potition: "Logistics Coordinator",
  },
  {
    userId: "APP009",
    username: "Indra",
    name: "Indra Pratama",
    email: "indra.pratama@example.com",
    departement: "Sales",
    divisi: "B2B",
    potition: "Sales Executive",
  },
  {
    userId: "APP010",
    username: "Joko",
    name: "Joko Supriyadi",
    email: "joko.supriyadi@example.com",
    departement: "IT",
    divisi: "Security",
    potition: "Cybersecurity Analyst",
  },
  {
    userId: "APP011",
    username: "Karin",
    name: "Karin Astuti",
    email: "karin.astuti@example.com",
    departement: "Marketing",
    divisi: "Digital",
    potition: "SEO Specialist",
  },
  {
    userId: "APP012",
    username: "Lina",
    name: "Lina Puspita",
    email: "lina.puspita@example.com",
    departement: "HR",
    divisi: "Employee Relations",
    potition: "HR Manager",
  },
  {
    userId: "APP013",
    username: "Maya",
    name: "Maya Indriani",
    email: "maya.indriani@example.com",
    departement: "IT",
    divisi: "Development",
    potition: "DevOps Engineer",
  },
  {
    userId: "APP014",
    username: "Nina",
    name: "Nina Sari",
    email: "nina.sari@example.com",
    departement: "Finance",
    divisi: "Payroll",
    potition: "Payroll Specialist",
  },
  {
    userId: "APP015",
    username: "Oka",
    name: "Oka Wibowo",
    email: "oka.wibowo@example.com",
    departement: "Software",
    divisi: "IT",
    potition: "Full Stack Developer",
  },
  {
    userId: "APP016",
    username: "Putri",
    name: "Putri Andayani",
    email: "putri.andayani@example.com",
    departement: "Sales",
    divisi: "B2C",
    potition: "Sales Manager",
  },
  {
    userId: "APP017",
    username: "Rizki",
    name: "Rizki Mahendra",
    email: "rizki.mahendra@example.com",
    departement: "Operations",
    divisi: "Procurement",
    potition: "Procurement Specialist",
  },
  {
    userId: "APP018",
    username: "Sari",
    name: "Sari Hartono",
    email: "sari.hartono@example.com",
    departement: "Customer Service",
    divisi: "Support",
    potition: "Customer Service Representative",
  },
  {
    userId: "APP019",
    username: "Toni",
    name: "Toni Wijaya",
    email: "toni.wijaya@example.com",
    departement: "Marketing",
    divisi: "Branding",
    potition: "Brand Manager",
  },
  {
    userId: "APP020",
    username: "Umi",
    name: "Umi Rahayu",
    email: "umi.rahayu@example.com",
    departement: "HR",
    divisi: "Training",
    potition: "Training Coordinator",
  },
];

export const dataDummyRequest = [
  {
    requestID: "RID0001",
    departement: "Software",
    divisi: "IT",
    position: "Software Developer",
    gender: "L",
    age: "25 - 40 th",
    education: "Strata 1",
    otherRequest: "Psikologi Industri & Organisasi",
    experience: "Min 5 th",
    user: "Amin"
  },
  {
    requestID: "RID0002",
    departement: "Human Resources",
    divisi: "HR",
    position: "HR Manager",
    gender: "P",
    age: "30 - 45 th",
    education: "Strata 1",
    otherRequest: "Komunikasi Interpersonal",
    experience: "Min 7 th",
    user: "Budi"
  },
  {
    requestID: "RID0003",
    departement: "Finance",
    divisi: "Accounting",
    position: "Accountant",
    gender: "L",
    age: "25 - 35 th",
    education: "Diploma 3",
    otherRequest: "Sertifikasi Akuntansi",
    experience: "Min 3 th",
    user: "Citra"
  },
  {
    requestID: "RID0004",
    departement: "Marketing",
    divisi: "Sales",
    position: "Sales Executive",
    gender: "P",
    age: "22 - 30 th",
    education: "Strata 1",
    otherRequest: "Negosiasi",
    experience: "Min 2 th",
    user: "Dewi"
  },
  {
    requestID: "RID0005",
    departement: "Operations",
    divisi: "Logistics",
    position: "Logistics Coordinator",
    gender: "L",
    age: "28 - 40 th",
    education: "Strata 1",
    otherRequest: "Pengelolaan Rantai Pasok",
    experience: "Min 4 th",
    user: "Eko"
  },
  {
    requestID: "RID0006",
    departement: "Engineering",
    divisi: "Mechanical",
    position: "Mechanical Engineer",
    gender: "L",
    age: "27 - 40 th",
    education: "Strata 1",
    otherRequest: "Desain CAD",
    experience: "Min 6 th",
    user: "Fajar"
  },
  {
    requestID: "RID0007",
    departement: "Customer Service",
    divisi: "Support",
    position: "Customer Support Specialist",
    gender: "P",
    age: "23 - 35 th",
    education: "Diploma 3",
    otherRequest: "Komunikasi Efektif",
    experience: "Min 3 th",
    user: "Gita"
  },
  {
    requestID: "RID0008",
    departement: "Legal",
    divisi: "Corporate",
    position: "Corporate Lawyer",
    gender: "L",
    age: "30 - 45 th",
    education: "Strata 2",
    otherRequest: "Hukum Korporasi",
    experience: "Min 8 th",
    user: "Hendra"
  },
  {
    requestID: "RID0009",
    departement: "Product",
    divisi: "Design",
    position: "UI/UX Designer",
    gender: "P",
    age: "24 - 35 th",
    education: "Strata 1",
    otherRequest: "Desain Interaksi",
    experience: "Min 3 th",
    user: "Ira"
  },
  {
    requestID: "RID0010",
    departement: "Procurement",
    divisi: "Purchasing",
    position: "Purchasing Officer",
    gender: "L",
    age: "26 - 40 th",
    education: "Strata 1",
    otherRequest: "Manajemen Pengadaan",
    experience: "Min 5 th",
    user: "Joko"
  }
];

export const dummyDepartement = [
  { label: 'Departemen Teknologi', value: 'tech' },
  { label: 'Departemen Pemasaran', value: 'marketing' },
  { label: 'Departemen Sumber Daya Manusia', value: 'hr' },
  { label: 'Departemen Keuangan', value: 'finance' },
  { label: 'Departemen Operasional', value: 'operations' }
];

export const dummyDivisi = [
  { label: 'Divisi Pengembangan Software', value: 'dev' },
  { label: 'Divisi IT Support', value: 'it_support' },
  { label: 'Divisi Digital Marketing', value: 'digital_marketing' },
  { label: 'Divisi Rekrutmen', value: 'recruitment' },
  { label: 'Divisi Audit Internal', value: 'internal_audit' }
];

export const dummyPosisi = [
  { label: 'Software Engineer', value: 'software_engineer' },
  { label: 'Frontend Developer', value: 'frontend_developer' },
  { label: 'HR Manager', value: 'hr_manager' },
  { label: 'Brand Manager', value: 'brand_manager' },
  { label: 'Financial Analyst', value: 'financial_analyst' }
];
