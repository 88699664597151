import { ErrorMessage, useField } from "formik";
import Dropdown from "../dropdown/dropdown.component";

const FormDropdown = ({
    hideError = false,
    className,
    formClassContainer,
    options = [],
    additionalOnClick,
    onlyShowSelectedLabel,
    ...props
}) => {
    const [field, meta, helpers] = useField(props?.name);

    const selectedItem = options.find((x) => {
        return x.value === field.value;
    });

    const borderColorClass = meta.error && meta.touched ? "border-red" : "";
    const isError = !(!meta.error || (meta.error && !meta.touched));
    const errorClassMessage = isError && !hideError ? "" : "hidden";


    if (onlyShowSelectedLabel)
        return (
            <div className={`${formClassContainer}`}>
                {selectedItem?.label ?? "-"}
            </div>
        );

    return (
        <div className={`${formClassContainer}`}>
            <div className="flex relative">
                <Dropdown
                    options={options}
                    onClick={(change) => {
                        helpers.setValue(change?.value);
                        additionalOnClick && additionalOnClick(change);
                    }}
                    className={` ${props.className}`}
                    parentDivClassname={borderColorClass}
                    value={selectedItem}
                    {...props}
                />
            </div>
            <div className={`text-error ${errorClassMessage}`}>
                <ErrorMessage name={props?.name} component="p" />
            </div>
        </div>
    );
};

export default FormDropdown;
