import { ErrorMessage, useField } from "formik";
import "./form-text-area.scss";
import Textarea from "../text-area/text-area.component";

const FormTextArea = ({
    hideError = false,
    className,
    formClassContainer,
    ...props
}) => {
    const [field, meta] = useField(props?.name);
    const borderColorClass = meta.error && meta.touched ? "border-red" : "";
    const isError = !(!meta.error || (meta.error && !meta.touched));
    const errorClassMessage = isError && !hideError ? "" : "hidden";

    return (
        <div className={`${formClassContainer}`}>
            <div className="flex relative">
                <Textarea
                    {...props}
                    value={field.value}
                    onChange={field?.onChange}
                    className={`${borderColorClass} ${className}`}
                />
            </div>
            <div className={`text-error ${errorClassMessage}`}>
                <ErrorMessage name={props?.name} component="p" />
            </div>
        </div>
    );
};

export default FormTextArea;
