import { ErrorMessage, useField } from "formik";
import Input from "../input/input.component";
import "./form-input.scss";

const FormInput = ({
    hideError = false,
    className,
    formClassContainer,
    ...props
}) => {
    const [field, meta] = useField(props?.name);
    const borderColorClass = meta.error && meta.touched ? "border-red" : "";
    const isError = !(!meta.error || (meta.error && !meta.touched));
    const errorClassMessage = isError && !hideError ? "" : "hidden";

    return (
        <div className={`${formClassContainer}`}>
            <div className="flex relative">
                <Input
                    {...props}
                    value={field.value}
                    onChange={field?.onChange}
                    className={`${borderColorClass} ${className}`}
                />
            </div>
            <div className={`text-error ${errorClassMessage}`}>
                <ErrorMessage name={props?.name} component="p" />
            </div>
        </div>
    );
};

export default FormInput;
