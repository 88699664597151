import { useFormik } from "formik";
import {
    personalDataInitialValue,
    personalDataValidation,
} from "../../form-validations/profile/personal-data.validation";
import {
    addressDataInitialValue,
    addressDataValidation,
} from "../../form-validations/profile/address-data.validation";
import {
    contactDataInitialValue,
    contactDataValidation,
    emergencyContactDataInitialValue,
    emergencyContactDataValidation,
} from "../../form-validations/profile/contact-data.validation";
import { useEffect, useState } from "react";
import PersonalData from "./content/personal-data.component";
import AddressData from "./content/address-data.component";
import ContactData from "./content/contact-data.component";
import EducationData from "./content/education-data.component";
import ExperienceData from "./content/experience-data.component";
import CoursesData from "./content/courses-data.component";
import WorkPreferencesData from "./content/work-preferences-data.component";
import ConfimationSubmitFormModal from "./components/confimation-submit-form-modal.component";
import useModal from "../../components/modal/modal.service";
import {
    educationDataInitialValue,
    educationDataValidation,
} from "../../form-validations/profile/education-data.validation";
import {
    experienceDataInitialValue,
    experienceDataValidation,
} from "../../form-validations/profile/experience-data.validation";
import {
    coursesDataInitialValue,
    coursesDataValidation,
} from "../../form-validations/profile/courses-data.validation";
import {
    workPreferencesDataInitialValue,
    workPreferencesDataValidation,
} from "../../form-validations/profile/work-preferences-data.validation";
import Button from "../../components/button/button.component";
import AdministrationFile from "./content/administration-file.component";
import {
    administrationFileDataInitialValue,
    administrationFileDataValidation,
} from "../../form-validations/profile/administration-file-data.validation";
import {
    getDistricsDropdownData,
    getProfileData,
    getProvinceDropdownData,
    getRegenciesDropdownData,
    postAddressData,
    postPersonalData,
} from "../../Service";
import { useDispatch, useSelector } from "react-redux";
import {
    setDistricsDropdownDataCurrent,
    setDistricsDropdownDataKtp,
    setProfileData,
    setProvinceDropdownData,
    setRegenciesDropdownDataCurrent,
    setRegenciesDropdownDataKtp,
} from "../../store/storeGlobal";
import {
    addressDummy,
    contactDummy,
    coursesDummy,
    educationDummy,
    experienceDummy,
    fileDummy,
    personalDummy,
    workPreferencesDummy,
} from "../DummyData";
import { toast } from "react-toastify";

// Hook untuk memeriksa apakah semua form dirty
const useAllFormsSubmitting = (formConfigs) => {
    return formConfigs.every((config) => config.formik.isSubmitting === false);
};

const useService = () => {
    const dispatch = useDispatch();

    // states
    const [navProfile, setNavProfile] = useState("Personal Data");
    const [contentVisible, setContentVisible] = useState(true); // State untuk mengontrol visibilitas konten
    const [personalDataValues, setPersonalDataValues] = useState(
        personalDataInitialValue
    );
    const [addressDataValues, setAddressDataValues] = useState(
        addressDataInitialValue
    );
    const [contactDataValues, setContactDataValues] = useState(
        contactDataInitialValue
    );
    const [emergencyContactDataValues, setEmergencyContactDataValues] =
        useState([]);
    const [educationDataValues, setEducationDataValues] = useState([]);
    const [experienceDataValues, setExperienceDataValues] = useState([]);
    const [coursesDataValues, setCoursesDataValues] = useState([]);
    const [workPreferencesDataValues, setWorkPreferencesDataValues] = useState(
        workPreferencesDataInitialValue
    );
    const [administrationFileDataValues, setAdministrationFileDataValues] =
        useState(administrationFileDataInitialValue);
    const [isSaveAll, setIsSaveAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEducationForm, setIsEducationForm] = useState(true);
    const [isExperienceForm, setIsExperienceForm] = useState(
        experienceDataValues?.length ? false : true
    );
    const [isCoursesForm, setIsCoursesForm] = useState(
        coursesDataValues?.length ? false : true
    );
    const [isAdministrationFileForm, setIsAdministrationFileForm] =
        useState(true);

    // selectors
    const dropdownProvince = useSelector(
        (state) => state?.global?.provinceDropdownDataKtp
    );
    const dropdownRegenciesKtp = useSelector(
        (state) => state?.global?.regenciesDropdownDataKtp
    );
    const dropdownRegenciesCurrent = useSelector(
        (state) => state?.global?.regenciesDropdownDataCurrent
    );
    const dropdownDistricsKtp = useSelector(
        (state) => state?.global?.districsDropdownDataKtp
    );
    const dropdownDistricsCurrent = useSelector(
        (state) => state?.global?.districsDropdownDataCurrent
    );
    const propertyPageProfile = useSelector(
        (state) => state?.global?.propertyPageProfile
    );
    const user = useSelector((state) => state?.login?.user);

    // modal
    const confimationSubmitFormModal = useModal();

    // formik
    const personalDataformik = useFormik({
        initialValues: personalDataValues,
        validationSchema: personalDataValidation,
        onSubmit: (values) => {
            savePersonalData(values);
            setPersonalDataValues(values);
        },
    });
    const addressDataformik = useFormik({
        initialValues: addressDataValues,
        validationSchema: addressDataValidation,
        onSubmit: (values) => {
            setAddressDataValues(values);
            saveAddressData(values);
        },
    });
    const contactDataformik = useFormik({
        initialValues: contactDataValues,
        validationSchema: contactDataValidation,
        onSubmit: (values) => {
            if (!isSaveAll) {
                setContactDataValues(values);
                toast.success("Data Successfully Changed");
            } else {
                setContactDataValues(values);
            }
        },
    });
    const emergencyContactDataformik = useFormik({
        initialValues: emergencyContactDataInitialValue,
        validationSchema: emergencyContactDataValidation,
        onSubmit: (values, { resetForm }) => {
            const id = emergencyContactDataValues.length + 1;

            const data =
                values.id === 0
                    ? [...emergencyContactDataValues, { ...values, id: id }]
                    : emergencyContactDataValues.map((item) =>
                          item.id === values.id ? { ...item, ...values } : item
                      );

            // Update state dan tampilkan notifikasi sesuai dengan kondisi
            setEmergencyContactDataValues(data);

            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
            resetForm();
        },
    });
    const educationDataformik = useFormik({
        initialValues: educationDataInitialValue,
        validationSchema: educationDataValidation,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            const id = educationDataValues.length + 1;

            const data =
                values.id === 0
                    ? [...educationDataValues, { ...values, id: id }]
                    : educationDataValues.map((item) =>
                          item.id === values.id ? { ...item, ...values } : item
                      );

            // Update state dan tampilkan notifikasi sesuai dengan kondisi
            setEducationDataValues(data);

            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
            resetForm();
            setIsEducationForm(false);
        },
    });
    const experienceDataformik = useFormik({
        initialValues: experienceDataInitialValue,
        validationSchema: experienceDataValidation,
        onSubmit: (values, { resetForm }) => {
            const id = experienceDataValues.length + 1;

            const data =
                values.id === 0
                    ? [...experienceDataValues, { ...values, id: id }]
                    : experienceDataValues.map((item) =>
                          item.id === values.id ? { ...item, ...values } : item
                      );

            // Update state dan tampilkan notifikasi sesuai dengan kondisi
            setExperienceDataValues(data);

            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
            resetForm();
            setIsExperienceForm(false);
        },
    });
    const workPreferencesDataformik = useFormik({
        initialValues: workPreferencesDataValues,
        validationSchema: workPreferencesDataValidation,
        onSubmit: (values) => {
            setWorkPreferencesDataValues(values);
            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
        },
    });
    const administrationFileDataformik = useFormik({
        initialValues: administrationFileDataValues,
        validationSchema: administrationFileDataValidation,
        onSubmit: (values) => {
            setAdministrationFileDataValues(values);
            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
        },
    });
    const coursesDataformik = useFormik({
        initialValues: coursesDataInitialValue,
        validationSchema: coursesDataValidation,
        onSubmit: (values, { resetForm }) => {
            const id = coursesDataValues.length + 1;
            const data =
                values.id === 0
                    ? [...coursesDataValues, { ...values, id: id }]
                    : coursesDataValues.map((item) =>
                          item.id === values.id ? { ...item, ...values } : item
                      );

            // Update state dan tampilkan notifikasi sesuai dengan kondisi
            setCoursesDataValues(data);

            if (!isSaveAll) {
                toast.success("Data Successfully Changed");
            }
            resetForm();
            setIsCoursesForm(false);
        },
    });

    const itemProfile = [
        {
            name: "Personal Data",
            icon: "ri-user-line",
        },
        {
            name: "Address Data",
            icon: "ri-home-4-line",
        },
        {
            name: "Contact Data",
            icon: "ri-phone-line",
        },
        {
            name: "Education Data",
            icon: "ri-quill-pen-line",
        },
        {
            name: "Experience Data",
            icon: "ri-briefcase-5-line",
        },
        {
            name: "Courses Data",
            icon: "ri-presentation-line",
        },
        {
            name: "Work Preferences Data",
            icon: "ri-link-unlink-m",
        },
        {
            name: "Administration File",
            icon: "ri-file-4-line",
        },
    ];

    const handleProfileItemClick = (name) => {
        setContentVisible(false); // Sembunyikan konten sebelum memperbarui state navProfile
        setTimeout(() => {
            setNavProfile(name);
        }, 300); // Waktu yang sama dengan durasi transisi CSS (0.3s)
    };

    const loadProvinceDropdownData = async () => {
        try {
            const actionResult = await getProvinceDropdownData();

            const setData = actionResult?.data?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            dispatch(setProvinceDropdownData(setData));
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            console.error(errorMessage);
        }
    };

    const loadRegenciesDropdownData = async (statusAddress, provinceId) => {
        try {
            const actionResult = await getRegenciesDropdownData(provinceId);

            const setData = actionResult?.data?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            if (statusAddress === "ktp") {
                dispatch(setRegenciesDropdownDataKtp(setData));
            } else if (statusAddress === "current") {
                dispatch(setRegenciesDropdownDataCurrent(setData));
            }
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            console.error(errorMessage);
        }
    };

    const loadDistricsDropdownData = async (statusAddress, regencyId) => {
        try {
            const actionResult = await getDistricsDropdownData(regencyId);

            const setData = actionResult?.data?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            if (statusAddress === "ktp") {
                dispatch(setDistricsDropdownDataKtp(setData));
            } else if (statusAddress === "current") {
                dispatch(setDistricsDropdownDataCurrent(setData));
            }
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            console.error(errorMessage);
        }
    };

    // Array konfigurasi form
    const formConfigs = [
        { formik: personalDataformik },
        { formik: addressDataformik },
        { formik: contactDataformik },
        { formik: educationDataformik },
        { formik: experienceDataformik },
        { formik: coursesDataformik },
        { formik: workPreferencesDataformik },
        { formik: administrationFileDataformik },
    ];

    // Cek apakah semua form dalam status dirty
    const allFormSubmitting = useAllFormsSubmitting(formConfigs);

    const saveAllProfileData = async () => {
        try {
            // Validasi semua form secara bersamaan
            const allErrors = await Promise.all(
                formConfigs.map((config) => config.formik.validateForm())
            ).then((results) =>
                results.reduce((acc, errors) => ({ ...acc, ...errors }), {})
            );

            // Submit only forms that are not currently submitting or already submitted
            await Promise.all(
                formConfigs
                    .filter(
                        (config) =>
                            !config.formik.isSubmitting &&
                            !config.formik.isSubmitted
                    )
                    .map((config) => config.formik.submitForm())
            );

            // Jika ada kesalahan, log ke konsol dan hentikan proses
            if (Object.keys(allErrors).length > 0) {
                toast.error("Failed to Save All Data");
                return;
            }

            toast.success("All data saved successfully");

            setIsSaveAll(true);
        } catch (error) {
            toast.error("Error saving data");
        }
    };

    const requestChangesAll = () => {
        toast.success("Successfully Sent Request to Change Data");
    };

    const removeDataExperience = (id) => {
        const updateData = experienceDataValues.filter(
            (item) => item.id !== id
        );
        setExperienceDataValues(updateData);
        toast.success("Successfully Removed Data");
    };

    const removeDataEducation = (id) => {
        const updateData = educationDataValues.filter((item) => item.id !== id);
        setEducationDataValues(updateData);
        toast.success("Successfully Removed Data");
    };

    const removeDataCourses = (id) => {
        const updateData = coursesDataValues.filter((item) => item.id !== id);
        setCoursesDataValues(updateData);
        toast.success("Successfully Removed Data");
    };

    const removeEmergencyContactData = (id, items) => {
        const updateData = items?.filter((item) => item.id !== id);
        setEmergencyContactDataValues(updateData);
        toast.success("Successfully Removed Data");
    };

    const loadProfileData = async () => {
        try {
            setLoading(true);
            const userId = user?.data?.id;
            const actionResult = await getProfileData(userId);
            if (!actionResult.isSuccess) {
                setLoading(false);
                return;
            }

            const { personal_data, address_data, nik, name } = actionResult;
            if (personal_data || nik || name) {
                const setData = { ...personal_data, nik, name };
                setPersonalDataValues(setData);
                personalDataformik.setValues(setData);
            }
            if (address_data) {
                const setData = {
                    ...address_data,
                    village:
                        address_data?.regency && address_data.village
                            ? address_data.village
                            : "",
                    c_village:
                        address_data?.c_regency && address_data.c_village
                            ? address_data.c_village
                            : "",
                };
                setAddressDataValues(setData);
                addressDataformik.setValues(setData);
            }
            setLoading(false);

            dispatch(setProfileData(actionResult));
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            console.error(errorMessage);
        }
    };

    const savePersonalData = async (values) => {
        try {
            setLoading(true);
            const setValues = {
                ...values,
                date_of_birth: values?.tanggal_lahir,
                marriage_status: values?.status_pernikahan,
                place_of_birth: values?.tempat_lahir,
            };
            await postPersonalData(setValues);
            toast.success("Data Successfully Changed");

            setLoading(false);
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            toast.error("Data failed to change");
            console.error(errorMessage);
        }
    };

    const saveAddressData = async (values) => {
        try {
            setLoading(true);
            await postAddressData(values);
            toast.success("Data Successfully Changed");
            setLoading(false);
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            toast.error("Data failed to change");
            console.error(errorMessage);
        }
    };

    const loadDummyData = () => {
        try {
            setLoading(true);
            personalDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? personalDummy
                    : personalDataInitialValue
            );
            addressDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? addressDummy
                    : addressDataInitialValue
            );
            contactDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? contactDummy
                    : addressDataInitialValue
            );
            // education
            setEducationDataValues(
                !propertyPageProfile?.isCurrentProfile ? educationDummy : null
            );
            setIsEducationForm(
                !propertyPageProfile?.isCurrentProfile ? false : true
            );

            // experience
            setExperienceDataValues(
                !propertyPageProfile?.isCurrentProfile ? experienceDummy : null
            );
            setIsExperienceForm(
                !propertyPageProfile?.isCurrentProfile ? false : true
            );

            // courses
            setCoursesDataValues(
                !propertyPageProfile?.isCurrentProfile ? coursesDummy : null
            );
            setIsCoursesForm(
                !propertyPageProfile?.isCurrentProfile ? false : true
            );

            coursesDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? coursesDummy
                    : coursesDataInitialValue
            );
            workPreferencesDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? workPreferencesDummy
                    : workPreferencesDataInitialValue
            );

            // administration file
            administrationFileDataformik.setValues(
                !propertyPageProfile?.isCurrentProfile
                    ? fileDummy
                    : administrationFileDataValidation
            );
            setIsAdministrationFileForm(
                !propertyPageProfile?.isCurrentProfile ? false : true
            );
            setLoading(false);
        } catch (error) {
            const errorMessage =
                typeof error !== "string" ? "Something wrong" : error;
            console.error(errorMessage);
        }
    };

    // components
    const loadContent = (() => {
        switch (navProfile) {
            case "Personal Data":
                return (
                    <PersonalData
                        formik={personalDataformik}
                        data={personalDataValues}
                        setPersonalDataValues={setPersonalDataValues}
                        isSaveAll={isSaveAll}
                        isCurrentProfile={propertyPageProfile?.isCurrentProfile}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Address Data":
                return (
                    <AddressData
                        formik={addressDataformik}
                        data={addressDataValues}
                        dropdownProvince={dropdownProvince}
                        dropdownRegenciesKtp={dropdownRegenciesKtp}
                        dropdownRegenciesCurrent={dropdownRegenciesCurrent}
                        dropdownDistricsKtp={dropdownDistricsKtp}
                        dropdownDistricsCurrent={dropdownDistricsCurrent}
                        setAddressDataValues={setAddressDataValues}
                        isSaveAll={isSaveAll}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Contact Data":
                return (
                    <ContactData
                        formik={contactDataformik}
                        data={contactDataValues}
                        setContactDataValues={setContactDataValues}
                        isSaveAll={isSaveAll}
                        emergencyContactDataformik={emergencyContactDataformik}
                        emergencyContactDataValues={emergencyContactDataValues}
                        removeEmergencyContactData={removeEmergencyContactData}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Education Data":
                return (
                    <EducationData
                        formik={educationDataformik}
                        data={educationDataValues}
                        removeData={(id) => removeDataEducation(id)}
                        isSaveAll={isSaveAll}
                        isEducationForm={isEducationForm}
                        setIsEducationForm={setIsEducationForm}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Experience Data":
                return (
                    <ExperienceData
                        formik={experienceDataformik}
                        data={experienceDataValues}
                        removeData={(id) => removeDataExperience(id)}
                        isSaveAll={isSaveAll}
                        isAddData={isExperienceForm}
                        setIsAddData={setIsExperienceForm}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Courses Data":
                return (
                    <CoursesData
                        formik={coursesDataformik}
                        data={coursesDataValues}
                        removeData={(id) => removeDataCourses(id)}
                        isSaveAll={isSaveAll}
                        isAddData={isCoursesForm}
                        setIsAddData={setIsCoursesForm}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Work Preferences Data":
                return (
                    <WorkPreferencesData
                        formik={workPreferencesDataformik}
                        data={workPreferencesDataValues}
                        setWorkPreferencesDataValues={
                            setWorkPreferencesDataValues
                        }
                        isSaveAll={isSaveAll}
                        canEdit={propertyPageProfile?.canEdit}
                    />
                );
            case "Administration File":
                return (
                    <AdministrationFile
                        formik={administrationFileDataformik}
                        canEdit={propertyPageProfile?.canEdit}
                        data={administrationFileDataValues}
                        setAdministrationFileDataValues={
                            setAdministrationFileDataValues
                        }
                        isSaveAll={isSaveAll}
                        isAdministrationFileForm={isAdministrationFileForm}
                        setIsAdministrationFileForm={
                            setIsAdministrationFileForm
                        }
                    />
                );
            default:
                return null; // Atau pesan kesalahan kustom jika dibutuhkan.
        }
    })();

    const allModalComponent = (
        <>
            <ConfimationSubmitFormModal
                modalService={confimationSubmitFormModal}
                onSubmit={
                    !allFormSubmitting ? saveAllProfileData : requestChangesAll
                }
                allFormSubmitting={allFormSubmitting}
            />
        </>
    );
    const RequestOrSaveAllButton = (
        <Button
            variant="green"
            label={
                !allFormSubmitting ? "Save Changes All" : "Request Changes All"
            }
            classNames={"w-100"}
            onClick={confimationSubmitFormModal.openModalHandling}
        />
    );

    const formikAddressValues = addressDataformik?.values;

    // useEffects
    useEffect(() => {
        // Mengatur kembali visibilitas konten setelah perubahan navProfile
        setContentVisible(true);
    }, [navProfile]);

    useEffect(() => {
        loadDummyData();
        loadProvinceDropdownData();
        if (propertyPageProfile?.isCurrentProfile) {
            loadProfileData();
        }
    }, [propertyPageProfile?.isCurrentProfile]);

    useEffect(() => {
        const statusAddress = "ktp";
        const provinceId = formikAddressValues?.province;
        if (!provinceId && isSaveAll) return;
        loadRegenciesDropdownData(statusAddress, provinceId);
    }, [formikAddressValues?.province]);

    useEffect(() => {
        const statusAddress = "current";
        const provinceId = formikAddressValues?.c_province;
        if (provinceId && !isSaveAll)
            loadRegenciesDropdownData(statusAddress, provinceId);
    }, [formikAddressValues?.c_province]);

    useEffect(() => {
        const statusAddress = "ktp";
        const regencyId = formikAddressValues?.regency;
        if (regencyId && !isSaveAll)
            loadDistricsDropdownData(statusAddress, regencyId);
    }, [formikAddressValues?.regency]);

    useEffect(() => {
        const statusAddress = "current";
        const regencyId = formikAddressValues?.c_regency;
        if (regencyId && !isSaveAll)
            loadDistricsDropdownData(statusAddress, regencyId);
    }, [formikAddressValues?.c_regency]);

    console.log("personalDataformik.values", personalDataformik);

    return {
        itemProfile,
        navProfile,
        contentVisible,
        loadContent,
        allModalComponent,
        RequestOrSaveAllButton,
        loading,
        setNavProfile,
        setContentVisible,
        handleProfileItemClick,
    };
};

export default useService;
