import * as Yup from "yup";

export const personalDataInitialValue = {
    name: "",
    nik: "",
    gender: "",
    nationality: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    religion: "",
    status_pernikahan: "",
    height: "",
    weight_body: "",
    no_bpjs_health: "",
    no_bpjs_employment: "",
    no_npwp: "",
};

export const personalDataValidation = Yup.object({
    name: Yup.string().required("Name is required"),
    nik: Yup.string().required("No KTP is required"),
    gender: Yup.string().required("Gender is required"),
    nationality: Yup.string().required("Nationality is required"),
    tempat_lahir: Yup.string().required("Place Of Birth is required"),
    tanggal_lahir: Yup.string().required("Place Of Date is required"),
    religion: Yup.string().required("Religion is required"),
    status_pernikahan: Yup.string().required("Marrige Status is required"),
    height: Yup.string().required("Height is required"),
    weight_body: Yup.string().required("Weight is required"),
});
