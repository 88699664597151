import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import "./style.scss";

const PieChartComponent = ({ percentage, desc }) => {
    const data = [
        { name: 'Completed', value: percentage },
        { name: 'Remaining', value: 1000 - percentage },
    ];
    const COLORS = ['#52B788', '#d6d6d6'];

    const percentageText = ((percentage - 100) / 900) * 100;

    return (
        <div style={{ textAlign: 'center' }}>
            <div className='pie-chart-container'>
                <PieChart width={200} height={200}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={90}
                        startAngle={90}
                        endAngle={450}
                        paddingAngle={1}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index]} />
                        ))}
                    </Pie>
                </PieChart>
                <div className='pie-chart-percentage'>{`${Math.round(percentageText)}%`}</div>
            </div>
            <div className='text-desc-pie-chart'>{desc}</div>
        </div>
    );
};

export default PieChartComponent;
