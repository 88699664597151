import * as Yup from "yup";

export const addressDataInitialValue = {
    province: "",
    regency: "",
    village: "",
    rt: "",
    rw: "",
    postal_code: "",
    address: "",
    c_province: "",
    c_regency: "",
    c_village: "",
    c_rt: "",
    c_rw: "",
    c_postal_code: "",
    c_address: "",
};

export const addressDataValidation = Yup.object({
    province: Yup.string().required("Province is required"),
    regency: Yup.string().required("City / Disctric is required"),
    village: Yup.string().required("Village is required"),
    rt: Yup.string().required("RT is required"),
    rw: Yup.string().required("RW is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    c_province: Yup.string().required("Province is required"),
    c_regency: Yup.string().required("City / Disctric is required"),
    c_village: Yup.string().required("Village is required"),
    c_rt: Yup.string().required("RT is required"),
    c_rw: Yup.string().required("RW is required"),
    c_postalCode: Yup.string().required("Postal Code is required"),
});
