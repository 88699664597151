import Modal from "../../../components/modal/modal.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import "../style.scss";

const ConfimationSubmitFormModal = ({
    modalService,
    onSubmit,
    allFormSubmitting,
}) => {
    const loadContent = () => {
        let title, desc, icon, labelButton;
        if (!allFormSubmitting) {
            title = "Data Correction!";
            desc =
                "Make sure the data you enter is correct, if you want to make changes it takes time to request.";
            labelButton = "Save Data";
            icon = "ri-error-warning-line";
        } else {
            title = "Data Edit Request";
            desc = "Requests take 1-3 working days please wait!";
            labelButton = "Send Request";
            icon = "ri-mail-send-line";
        }

        return { title, desc, icon, labelButton };
    };

    return (
        <Modal modalService={modalService} className="modal-home">
            <div className="modal-header">
                <div className="modal-title">Attention!</div>
            </div>
            <div className="modal-content">
                <i className={loadContent().icon} />
                <div className="modal-title">{loadContent().title}</div>
                <div className="modal-desc">{loadContent().desc}</div>
                <div className="d-flex gap-2">
                    <Button
                        label="Cancel"
                        onClick={() => modalService.closeModalHandling()}
                        style={{ width: "120px" }}
                        variant="gray"
                    />
                    <Button
                        label={loadContent().labelButton}
                        onClick={() => {
                            onSubmit();
                            modalService.closeModalHandling();
                        }}
                        style={{ width: "120px" }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ConfimationSubmitFormModal;
