import React from 'react'
import "./style.scss";

const AvatarCircle = ({ name, size, src, }) => {
    const getInitials = (name) => {
        const words = name.split(' ')
        const letter = words
            .map((word) => word[0])
            .join('')
            .toUpperCase()
        return letter.split('').map((val, index) => {
            if (index < 2) return val
            return ''
        }).join('')
    }

    const initials = getInitials(name)

    return (
        <div
            className={`avatar-container ${src ? 'has-image' : ''}`}
            style={{ height: size, width: size }}
        >
            {src ? (
                <img src={src} alt="avatar" className="avatar-image" />
            ) : (
                <span className="avatar-initials">{initials}</span>
            )}
        </div>
    )
}

export default AvatarCircle
