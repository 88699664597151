import React from "react";
import { Drawer, List } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { getRoutesForRole } from "../../routes/appRoutes";
import SidebarItem from "./sidebar-item.component";
// import LogoIbsi from "../../assets/img/Logo/logo.png";
// import { logoutUser } from "../../Service";
// import { setUser } from "../../store/storeLogin";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import SidebarItemCollapse from "./sidebar-item-collapse.component";
import { useSelector } from "react-redux";

const Sidebar = () => {
    //   const dispatch = useDispatch();
    //   const navigate = useNavigate();
    //   const storedJsonString = localStorage.getItem('userLogin');
    //   const dataUserLogin = JSON.parse(storedJsonString);
    //   const modulesUser  = dataUserLogin?.data?.modules
    //   const filteredRoutes = appRoutes.filter(data => modulesUser?.includes(data?.sidebarProps?.displayText));

    const user = useSelector((state) => state.login.user);
    const filteredRoutes = getRoutesForRole(user?.data.role);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
        >
            <List className="sidebar-custom">
                {filteredRoutes.map((route, index) =>
                    route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={index} />
                        ) : (
                            <SidebarItem item={route} key={index} />
                        )
                    ) : null
                )}
            </List>
        </Drawer>
    );
};

export default Sidebar;
