import React from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import moment from "moment";

const ExperienceData = ({
    formik,
    data,
    removeData,
    isAddData,
    setIsAddData,
    isSaveAll,
    canEdit,
}) => {
    const formateDate = (startDate, endDate) => {
        const formattedStartDate = moment(startDate).format("MMM YYYY");
        const formattedEndDate = moment(endDate).format("MMM YYYY");
        return `${formattedStartDate} - ${formattedEndDate}`;
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="content-profile-title">Experience Data</div>
                {!isAddData && !isSaveAll && canEdit && (
                    <Button
                        label={"Add Experience"}
                        onClick={() => setIsAddData(true)}
                    />
                )}
            </div>
            {!isAddData ? (
                <div className="row">
                    {data?.map((item) => (
                        <div className={`col-12 col-lg-6 mt-3`}>
                            <div
                                className="card-information"
                                style={{ minHeight: "100px" }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="card-title">
                                        {item?.company}
                                    </div>
                                    <div className="d-flex gap-2">
                                        <Button
                                            icon="ri-pencil-line"
                                            classNames="button-icon"
                                            variant="yellow"
                                            onClick={() => {
                                                formik?.setValues(item);
                                                setIsAddData(true);
                                            }}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                        <Button
                                            icon="ri-delete-bin-7-line"
                                            classNames="button-icon"
                                            variant="red"
                                            onClick={() => removeData(item?.id)}
                                            disabled={isSaveAll || !canEdit}
                                        />
                                    </div>
                                </div>
                                <div className="card-details">
                                    <div>{item?.position}</div>
                                    <div>
                                        {formateDate(
                                            item?.startDate,
                                            item?.endDate
                                        )}
                                    </div>
                                </div>
                                <div>{item?.additionalInformation}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <FormikProvider value={formik}>
                    <div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"Enter your position"}
                                    label="Position"
                                    name="position"
                                    required
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"Enter your company"}
                                    label="Company"
                                    name="company"
                                    required
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between w-100 me-4">
                                <div className="w-100 mt-3 ">
                                    <FormDatePicker
                                        label="Start Date"
                                        name="startDate"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                                <div className="w-100 mt-3">
                                    <FormDatePicker
                                        label="End Date"
                                        name="endDate"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <FormTextArea
                                    placeholder={
                                        "Enter your Additional Information"
                                    }
                                    label="Additional Information"
                                    name="additionalInformation"
                                />
                            </div>
                        </div>
                        {!formik.isSubmitting && (
                            <Button
                                label={"Save Change"}
                                onClick={() => {
                                    formik?.submitForm();
                                }}
                                classNames={"float-end my-5"}
                            />
                        )}
                    </div>
                </FormikProvider>
            )}
        </div>
    );
};

export default ExperienceData;
