import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import FormUploadFile from "../../../components/form-upload-file/form-upload-file.component.jsx";

const tabContent = [
    { id: '1', name: 'Offering Letter' },
    { id: '2', name: 'Airplane Ticket' }
]

const OnboardingModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('recommendation');
    const [tabActive, setTabActive] = useState("Offering Letter");

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };

    const content = () => {

        return (
            <div>
                <div className="tabs-item-modal">
                    {tabContent.map((data, idx) => {
                        return (
                            <div
                                key={`item-tabs-content-${idx}`}

                                onClick={() => setTabActive(data.name)}
                                className={`tabs-item ${tabActive === data.name && 'active-item'}`}>
                                {data.name}
                            </div>
                        )
                    })}
                </div>
                {tabActive === 'Offering Letter' ? (
                    <div>
                        <div className="onboarding-offering ">
                            <FormUploadFile
                                className={"uploud-file-text"}
                                label={"Upload Offering Letter Applicant"}
                                name={"file"}
                            />
                        </div>
                        <div className="donwload-cv">
                            <div className="d-flex align-items-center">
                                <i className="ri-file-pdf-2-line me-2" />
                                <div>
                                    <div className="donwload-cv-title"> Download Offering Letter</div>
                                    <div className="donwload-cv-desc">offering_letter_erfan_yeager.pdf</div>
                                </div>
                            </div>
                            <i className="ri-download-line" />
                        </div>
                    </div>
                ) : (
                    <>
                        <HeaderExpand id="Onboarding">
                            <div className="donwload-cv w-100 me-5">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <div className="donwload-cv-title"> Download Aiplane Ticket</div>
                                        <div className="donwload-cv-desc">air_plane_ticket_erfan_yeager.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                        </HeaderExpand>
                        <div className="recommendation-switch">
                            <input
                                type="radio"
                                id="recommendation"
                                value="recommendation"
                                checked={recommendation === 'recommendation'}
                                onChange={handleChange}
                            />
                            <label htmlFor="recommendation" className="recommendation-label">
                                Recommendation
                            </label>
                            <input
                                type="radio"
                                id="noRecommendation"
                                value="noRecommendation"
                                checked={recommendation === 'noRecommendation'}
                                onChange={handleChange}
                            />
                            <label htmlFor="noRecommendation" className="recommendation-label">
                                No Recommendation
                            </label>
                        </div>
                        <FormTextArea
                            placeholder={"Give notes to applicants"}
                            label="Note"
                            name="note"
                        />
                    </>
                )}

            </div>

        )
    }
    return (
        <FormikProvider value={formik}>
            <Expand title="Onboarding" desc="Upload the offering letter and download the applicant's flight ticket" icon={"archive-fill"} status>
                {content()}
            </Expand>
        </FormikProvider>

    );
};

export default OnboardingModal;
