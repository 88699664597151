import { useGlobalFilter, usePagination, useTable } from "react-table";
import { dataDummyUser } from "../DummyData";
import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { userInitialValue, userValidation } from "../../form-validations/user-management/create-user";


const useService = () => {
    const [filterInput, setFilterInput] = useState("");
    const [tableHeight, setTableHeight] = useState(310);
      
    const columns = useMemo(
        () => [
            {
                Header: "Request ID",
                accessor: "requestID",
            },
            {
                Header: "Departement",
                accessor: "departement",
            },
            {
                Header: "Divisi",
                accessor: "divisi",
            },
            {
                Header: "Potition",
                accessor: "potition",
            },
            {
                Header: "Total Request",
                accessor: "totalRequest",
            },
            {
                Header: "Gender",
                accessor: "gender",
            },
            {
                Header: "Age",
                accessor: "age",
            },
            {
                Header: "Education",
                accessor: "education",
            },
            {
                Header: "Other Request",
                accessor: "otherRequest",
            },
            {
                Header: "Experience",
                accessor: "experience",
            },
            {
                Header: "User",
                accessor: "user",
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: () => (
                    <div className="d-flex">
                        <div className="action-show"><i className="ri-eye-line" /></div>
                        <div className="action-edit"><i className="ri-pencil-line" /></div>
                        <div className="action-delete"><i className="ri-delete-bin-7-line" /></div>
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        const handleResize = () => {
            const newTableHeight = window.innerHeight - 310;
            setTableHeight(newTableHeight);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const createUser = useFormik({
        initialValues: userInitialValue,
        validationSchema: userValidation,
        onSubmit: () => {
        },
    });

    useTable(
        {
            columns,
            data: dataDummyUser,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };

    return {
        createUser,
        columns,
        filterInput,
        tableHeight,
        handleFilterChange
    };
};

export default useService;
