import React from "react";
import "./style.scss";
import useService from "./service";

const tabContent = [
    { id: "1", name: "Pre-Processed" },
    { id: "2", name: "Processed" },
    { id: "3", name: "Rejected" },
    { id: "4", name: "Hired" },
];

const TalentPool = () => {
    const {
        filterInput,
        contentActive,
        loadContents,
        handleFilterChange,
        setContentActive,
        allModal,
    } = useService();
    return (
        <>
            <div className="content-page">
                <div className="content-page-header">
                    <div className="content-page-title">Talent Pool</div>
                    <div className="d-flex align-items-center">
                        <div className="form-control input-search d-flex">
                            <input
                                value={filterInput}
                                onChange={handleFilterChange}
                                placeholder="Enter a keyword to search"
                            />
                            <div className="d-flex">
                                <div className="vertical" />
                                <i className="ri-search-line" />
                            </div>
                        </div>
                        <div className="icon-filter-page">
                            <i className="ri-filter-line" />
                        </div>
                    </div>
                </div>
                <div className="tabs-item-tracking">
                    {tabContent.map((data, idx) => {
                        return (
                            <div
                                key={`item-tabs-content-${idx}`}
                                onClick={() => setContentActive(data.name)}
                                className={`tabs-item ${
                                    contentActive === data.name && "active-item"
                                }`}
                            >
                                {data.name}
                            </div>
                        );
                    })}
                </div>
                {loadContents()}
            </div>
            {allModal}
        </>
    );
};

export default TalentPool;
