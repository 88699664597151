import React  from "react";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import "../style.scss";

const HiredModal = ({ formik }) => {

    return (
        <FormikProvider value={formik}>
            <Expand title="Hired" desc="Upload applicant's MCU result" icon={"archive-fill"} status={false}>
                <HeaderExpand id="Hired">
                    <div className="py-4">
                        <div className="text-content-modal-header-green">Successful Applicant Hired</div>
                    </div>
                </HeaderExpand>
                <div className="mt-2">
                    <FormTextArea
                        placeholder={"Give notes to applicants"}
                        label="Note"
                        name="note"
                    />
                </div>
            </Expand>
        </FormikProvider>

    );
};

export default HiredModal;
