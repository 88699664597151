import React from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import Button from "../../../components/button/button.component.jsx";
import FormDropdown from "../../../components/form-dropdown/form-dropdown.component.jsx";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import { addressDataInitialValue } from "../../../form-validations/profile/address-data.validation.js";

const AddressData = ({
    formik,
    data,
    dropdownProvince,
    dropdownRegenciesKtp,
    dropdownRegenciesCurrent,
    dropdownDistricsKtp,
    dropdownDistricsCurrent,
    setAddressDataValues,
    isSaveAll,
    canEdit,
}) => {
    return (
        <div className="content-wrapper">
            <FormikProvider value={formik}>
                <div>
                    <div className="content-profile-title d-flex justify-content-between">
                        <div>Address According to KTP</div>
                        {formik?.isSubmitting && !isSaveAll && (
                            <Button
                                label={"Edit Data"}
                                variant="yellow"
                                onClick={() => {
                                    setAddressDataValues(
                                        addressDataInitialValue
                                    );
                                    formik?.setSubmitting(false);
                                }}
                            />
                        )}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownProvince}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={"Select Province"}
                                label="Province"
                                name={"province"}
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownRegenciesKtp}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={'Select "City / Disctric'}
                                label="City / Disctric"
                                name="regency"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownDistricsKtp}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={"Select Village"}
                                label="Village"
                                name="village"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-between gap-4">
                            <FormInput
                                required
                                parentDivClassname="w-100 me-3"
                                placeholder={"00"}
                                label="RT"
                                name="rt"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                            <FormInput
                                required
                                parentDivClassname="w-100"
                                placeholder={"00"}
                                label="RW"
                                name="rw"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormInput
                                required
                                placeholder={"0000"}
                                label="Postal Code"
                                name="postal_code"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormTextArea
                                required
                                placeholder={
                                    "Enter your address according to KTP"
                                }
                                label="Address"
                                name="address"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: "4rem", marginBottom: "3rem" }} />
                <div>
                    <div className="content-profile-title">Current Address</div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownProvince}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={"Select Province"}
                                label="Province"
                                name="c_province"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownRegenciesCurrent}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={'Select "City / Disctric'}
                                label="City / Disctric"
                                name="c_regency"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormDropdown
                                isSearchable
                                required
                                options={dropdownDistricsCurrent}
                                useBorder={true}
                                dropDownIndicator={true}
                                placeholder={"Select Village"}
                                label="Village"
                                name="c_village"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-between gap-4">
                            <FormInput
                                required
                                parentDivClassname="w-100 me-3"
                                placeholder={"00"}
                                label="RT"
                                name="c_rt"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                            <FormInput
                                required
                                parentDivClassname="w-100"
                                placeholder={"00"}
                                label="RW"
                                name="c_rw"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-lg-6">
                            <FormInput
                                required
                                placeholder={"0000"}
                                label="Pos Code"
                                name="c_postalCode"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormTextArea
                                required
                                placeholder={"Enter your current address"}
                                label="Address"
                                name="c_address"
                                disabled={formik.isSubmitting || !canEdit}
                            />
                        </div>
                    </div>
                    {!formik.isSubmitting && canEdit && (
                        <Button
                            label={"Save Change"}
                            onClick={formik?.submitForm}
                            classNames={"float-end my-5"}
                        />
                    )}
                </div>
            </FormikProvider>
        </div>
    );
};

export default AddressData;
