import * as Yup from "yup";

export const coursesDataInitialValue = {
    id: 0,
    coursesName: "",
    organizer: "",
    startDate: "",
    endDate: "",
    noCertificate: "",
};

export const coursesDataValidation = Yup.object({
    coursesName: Yup.string().required("Courses Name is required"),
    organizer: Yup.string().required("Organizer is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    noCertificate: Yup.string().required("No Certificate is required"),
});
