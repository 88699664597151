import { useRef } from "react";
import Button from "../button/button.component";
import "./upload-file.style.scss";

const UploadFile = ({
    label,
    required,
    disabled = false,
    selectedFile,
    setSelectedFile,
    className,
}) => {
    const inputRef = useRef(null);
    // const [selectedFile, setSelectedFile] = useState(null);
    const disabledClass = disabled ? "disabled" : "";

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    return (
        <div className="upload-file-style">
            <input
                className="d-none"
                type="file"
                onChange={handleFileChange}
                ref={inputRef}
                accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx"
            />

            <div className={className}>
                <div className="mb-2">
                    {label}
                    {required && <span className={`required`}>*</span>}
                </div>
                <div
                    className="d-flex align-items-center cursor-pointer w-100"
                    onClick={() => {
                        if (!disabled) onChooseFile();
                    }}
                    style={{ height: "40px" }}
                >
                    <Button
                        classNames="rounded-end-0 h-100"
                        disabled={disabled}
                        label="BROWSE FILE"
                        variant="gray"
                    />
                    <div
                        className={`file-name border p-2 rounded-end flex-grow-1 h-100 d-flex align-items-center ${
                            selectedFile ? "" : "text-secondary"
                        } ${disabledClass}`}
                    >
                        {selectedFile?.name || "No file chosen"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadFile;
