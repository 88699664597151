import React, { useEffect, useMemo, useState } from "react";
import "../style.scss";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import { FormikProvider } from "formik";
import Button from "../../../components/button/button.component.jsx";
import { contactDataInitialValue } from "../../../form-validations/profile/contact-data.validation.js";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import Table from "../../../components/Table";

const ContactData = ({
    formik,
    data,
    setContactDataValues,
    isSaveAll,
    emergencyContactDataformik,
    emergencyContactDataValues,
    removeEmergencyContactData,
    canEdit,
}) => {
    const [isAddEmergencyContact, setIsAddEmergencyContact] = useState(false);
    const [tableHeight, setTableHeight] = useState(110);

    const columns = useMemo(
        () => [
            {
                Header: "No",
                accessor: "no",
                Cell: ({ row }) => {
                    return <div>{row?.index + 1}</div>;
                },
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Relationship",
                accessor: "relationship",
            },
            {
                Header: "No Telephone",
                accessor: "noTelephone",
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (row) => {
                    return (
                        <div className="d-flex gap-2">
                            <Button
                                icon="ri-pencil-line"
                                classNames="button-icon"
                                variant="yellow"
                                onClick={() => {
                                    emergencyContactDataformik?.setValues(
                                        row?.original
                                    );
                                    setIsAddEmergencyContact(true);
                                }}
                                disabled={formik?.isSubmitting || !canEdit}
                            />
                            <Button
                                icon="ri-delete-bin-7-line"
                                classNames="button-icon"
                                variant="red"
                                onClick={() => {
                                    removeEmergencyContactData(
                                        row?.row?.original?.id,
                                        row?.data
                                    );
                                }}
                                disabled={formik?.isSubmitting || !canEdit}
                            />
                        </div>
                    );
                },
            },
        ],
        [formik?.isSubmitting]
    );

    useTable(
        {
            columns,
            data: emergencyContactDataValues,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    useEffect(() => {
        const handleResize = () => {
            const newTableHeight = window.innerHeight - 310;
            setTableHeight(newTableHeight);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (emergencyContactDataformik?.isSubmitting)
            setIsAddEmergencyContact(false);
    }, [emergencyContactDataformik?.isSubmitting]);

    const emergencyContactForm = (
        <FormikProvider value={emergencyContactDataformik}>
            <div>
                <div className="content-profile-title">
                    Emergency Contact Data
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-lg-6">
                        <FormInput
                            placeholder={"Enter name"}
                            label="Name"
                            name="name"
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <FormInput
                            placeholder={"Enter relationship"}
                            label="Relationship"
                            name="relationship"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-lg-6">
                        <FormInput
                            placeholder={"Enter no telephone"}
                            label="No Telephone"
                            name="noTelephone"
                        />
                    </div>
                </div>
                <Button
                    label={"Save Change"}
                    onClick={emergencyContactDataformik?.submitForm}
                    classNames={"float-end my-5"}
                />
            </div>
        </FormikProvider>
    );

    return (
        <>
            {isAddEmergencyContact ? (
                emergencyContactForm
            ) : (
                <FormikProvider value={formik}>
                    <div>
                        <div className="content-profile-title d-flex justify-content-between">
                            <div> Personal Contact Data</div>
                            {formik?.isSubmitting && !isSaveAll && (
                                <Button
                                    label={"Edit Data"}
                                    variant="yellow"
                                    onClick={() => {
                                        setContactDataValues(
                                            contactDataInitialValue
                                        );
                                        formik?.setSubmitting(false);
                                    }}
                                />
                            )}
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    required
                                    readonly
                                    placeholder={"Enter your Email"}
                                    value={"erfanyeager@gmail.com"}
                                    label="E-mail"
                                    name="email"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    required
                                    readonly
                                    type="number"
                                    placeholder={"Enter your Call Phone Number"}
                                    value={"81267665542"}
                                    label="Call Phone Number"
                                    name="callPhoneNumber"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    required
                                    placeholder={"Enter your whatsapp number"}
                                    label="WhatsApp Number"
                                    name="whatsAppNumber"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <hr style={{ marginTop: "4rem", marginBottom: "3rem" }} />
                    <div>
                        <div className="content-profile-title">
                            Link Profile Social Media
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"https://www.facebook.com/..."}
                                    label="Facebook"
                                    name="facebookLink"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={
                                        "https://www.instagram.com/..."
                                    }
                                    label="Instagram"
                                    name="twitterLink"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"https://twitter.com/..."}
                                    label="Twitter"
                                    name="instagramLink"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <FormInput
                                    placeholder={"https://linkedin.com/..."}
                                    label="Linkedin"
                                    name="linkedinLink"
                                    disabled={formik?.isSubmitting || !canEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <hr style={{ marginTop: "4rem", marginBottom: "3rem" }} />
                    <div>
                        <div className="content-profile-title d-flex justify-content-between">
                            <div>Emergency Contact Data</div>
                            {!formik.isSubmitting && canEdit && (
                                <Button
                                    label={"Add Contact"}
                                    onClick={() =>
                                        setIsAddEmergencyContact(true)
                                    }
                                />
                            )}
                        </div>
                        <div className="row mt-3">
                            <Table
                                height={tableHeight - 40}
                                columns={columns}
                                data={emergencyContactDataValues}
                            />
                        </div>
                        {!formik.isSubmitting && canEdit && (
                            <Button
                                label={"Save Change"}
                                onClick={formik?.submitForm}
                                classNames={"float-end my-5"}
                            />
                        )}
                    </div>
                </FormikProvider>
            )}
        </>
    );
};

export default ContactData;
