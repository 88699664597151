import React from "react";
import { Route } from "react-router-dom";
import PageWrapper from "../container/PageWrapper";
import appRoutes from "./appRoutes";

const generateRoute = (routes) => {
  // const storedJsonString = localStorage.getItem('userLogin');
  // const dataUserLogin = JSON.parse(storedJsonString);
  // const modulesUser = dataUserLogin?.data?.modules
  // const filteredRoutes = routes.filter(data => modulesUser?.includes(data?.sidebarProps?.displayText));

  return routes.map((route, index) => (
    route.index ? (
      <Route
        index
        path={route.path}
        element={
          <PageWrapper state={route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && (
          generateRoute(route.child)
        )}
      </Route>
    )
  ));
};

const routes = generateRoute(appRoutes);

export default routes;
