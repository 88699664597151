import React, { useState } from "react";
import "../style.scss";
import { FormikProvider } from "formik";
import FormTextArea from "../../../components/form-text-area/form-text-area.component.jsx";
import HeaderExpand from "./header-expand.jsx";
import { Expand } from "../../../components/expand/index.jsx";
import FormDatePicker from "../../../components/form-date-picker/form-input.component.jsx";
import FormDropdown from "../../../components/form-dropdown/form-dropdown.component.jsx";
import FormInput from "../../../components/form-input/form-input.component.jsx";
import Button from "../../../components/button/button.component.jsx";

const tabContent = [
    { id: '1', name: 'Administration File' },
    { id: '2', name: 'Banefit Offer' }
]

const BanefitOfferModal = ({ formik }) => {
    const [recommendation, setRecommendation] = useState('recommendation');
    const [tabActive, setTabActive] = useState("Administration File");
    const [interview, setInterview] = useState(false);

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };

    const content = () => {
        if (interview) {
            return (
                <>
                    <HeaderExpand id="Banefit">
                        <div className="header-user-interview">
                            <i className="ri-check-double-line" />
                            <div className="text-header-user-interview">User Interviews Have Been Completed</div>
                        </div>
                    </HeaderExpand>
                    <div className="recommendation-switch">
                        <input
                            type="radio"
                            id="recommendation"
                            value="recommendation"
                            checked={recommendation === 'recommendation'}
                            onChange={handleChange}
                        />
                        <label htmlFor="recommendation" className="recommendation-label">
                            Recommendation
                        </label>
                        <input
                            type="radio"
                            id="noRecommendation"
                            value="noRecommendation"
                            checked={recommendation === 'noRecommendation'}
                            onChange={handleChange}
                        />
                        <label htmlFor="noRecommendation" className="recommendation-label">
                            No Recommendation
                        </label>
                    </div>
                    <FormTextArea
                        placeholder={"Give notes to applicants"}
                        label="Note"
                        name="note"
                    />
                </>
            )
        } else {
            return (
                <div>
                    <div className="tabs-item-modal">
                        {tabContent.map((data, idx) => {
                            return (
                                <div
                                    key={`item-tabs-content-${idx}`}

                                    onClick={() => setTabActive(data.name)}
                                    className={`tabs-item ${tabActive === data.name && 'active-item'}`}>
                                    {data.name}
                                </div>
                            )
                        })}
                    </div>
                    {tabActive === 'Administration File' ? (
                        <div>
                             <div className="donwload-cv">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                            <div className="donwload-cv">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                            <div className="donwload-cv">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                            <div className="donwload-cv">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                            <div className="donwload-cv">
                                <div className="d-flex align-items-center">
                                    <i className="ri-file-pdf-2-line me-2" />
                                    <div>
                                        <div className="donwload-cv-title"> CV Ahmad Prayoga</div>
                                        <div className="donwload-cv-desc">cv_ahmad_prayoga.pdf</div>
                                    </div>
                                </div>
                                <i className="ri-download-line" />
                            </div>
                            <Button
                                onClick={() => setTabActive("Banefit Offer")}
                                classNames={"w-100 mt-3"}
                                label="Confirm Complete"
                            />
                        </div>
                    ) : (
                        <div className="mb-3">
                            <FormDatePicker
                                name="date"
                                label="Date"
                                required
                            />
                            <div className="row mt-3">
                                <div className="col-4">
                                    <FormDropdown
                                        options={[
                                            {
                                                value: "BATAM",
                                                label: "BATAM",
                                            },
                                        ]}
                                        useBorder={true}
                                        dropDownIndicator={true}
                                        placeholder={'Select Hour'}
                                        label="Select Hour"
                                        name="hour"
                                    />
                                </div>
                                <div className="col-4">
                                    <FormDropdown
                                        options={[
                                            {
                                                value: "BATAM",
                                                label: "BATAM",
                                            },
                                        ]}
                                        useBorder={true}
                                        dropDownIndicator={true}
                                        placeholder={'Select Minute'}
                                        label="Select Minute"
                                        name="minute"
                                    />
                                </div>
                                <div className="col-4">
                                    <FormDropdown
                                        options={[
                                            {
                                                value: "BATAM",
                                                label: "BATAM",
                                            },
                                        ]}
                                        useBorder={true}
                                        dropDownIndicator={true}
                                        placeholder={'Select Zone'}
                                        label="Select Zone"
                                        name="zone"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <FormInput
                                    parentDivClassname="w-100 me-3"
                                    placeholder={"00"}
                                    label="Meeting ID"
                                    name="meetId"
                                />
                            </div>
                            <div className="mt-3">
                                <FormInput
                                    parentDivClassname="w-100 me-3"
                                    placeholder={"https://zoom"}
                                    label="Link"
                                    name="link"
                                />
                            </div>
                            <Button
                                onClick={() => setInterview(!interview)}
                                classNames={"w-100 mt-3"}
                                label="Schedule Interview"
                            />
                        </div>
                    )}

                </div>

            )
        }

    }
    return (
        <FormikProvider value={formik}>
            <Expand title="Banefit Offer" desc="Schedule applicant interviews to negotiate benefits" icon={"archive-fill"} status>
                {content()}
            </Expand>
        </FormikProvider>

    );
};

export default BanefitOfferModal;
