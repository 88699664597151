import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const titleBreadcrumbs = capitalizeWords(location.pathname.replace("/", " ").replace("-", " "));
  
  return (
        <div className="bread-nav">
            <div>Home</div>
            <i className="ri-arrow-right-s-line active-nav" /> 
            <div className="active-nav">{titleBreadcrumbs}</div>
        </div>
  );
};

export default Breadcrumbs;
