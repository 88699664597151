import React, { useState } from "react";
import Table from "../../components/Table";
import { dataDummyUser, dummyDepartement, dummyDivisi, dummyPosisi } from "../DummyData";
import Button from "../../components/button/button.component";
import useService from "./service";
import "./style.scss";
import Modal from "../../components/modal/modal.component";
import useModal from "../../components/modal/modal.service";
import FormInput from "../../components/form-input/form-input.component";
import { FormikProvider } from "formik";
import FormDropdown from "../../components/form-dropdown/form-dropdown.component";

const tabContent = [
  { id: '1', name: 'Account' },
  { id: '2', name: 'Section' },
  { id: '3', name: 'Access' }
]

const UserManagement = () => {
  const modalService = useModal();
  const [tabActive, setTabActive] = useState("Account");
  const [recommendation, setRecommendation] = useState('recommendation');
  const [isStatusScreening, setIsStatusScreening] = useState(false);
  const [isStatusPsy, setIsStatusPsy] = useState(false);
  const [isStatusUI, setIsStatusUI] = useState(false);
  const [isStatusHR, setIsStatusHR] = useState(false);
  const [isStatusOB, setIsStatusOB] = useState(false);
  const [isStatusMCU, setIsStatusMCU] = useState(false);
  const [isStatusHired, setIsStatusHired] = useState(false);
  const [isStatusRj, setIsStatusRj] = useState(false);
  const { filterInput, tableHeight, handleFilterChange, columns, createUser } = useService()
  const handleChange = (event) => {
    setRecommendation(event.target.value);
  };

  return (
    <>
      <div className="content-page">
        <div className="content-page-header row">
          <div className="content-page-title col-6">User Management</div>
          <div className=" col-6 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => { modalService.openModalHandling() }}
              classNames={"btn-add-user"}
              label="Add user"
            />
            <div className="form-control input-search d-flex">
              <input
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Enter a keyword to search"
              />
              <div className="d-flex">
                <div className="vertical" />
                <i className="ri-search-line" />
              </div>
            </div>
            <div className="icon-filter-page">
              <i className="ri-filter-line" />
            </div>
          </div>
        </div>
        <Table
          height={tableHeight - 40}
          columns={columns}
          data={dataDummyUser}
        />
        <Modal
          modalService={modalService}
          className={"modal-tracking"}
          maxWidth="md">
          <div>
            <div>
              <div className="px-3 pt-3 d-flex justify-content-between border-bottom pb-3">
                <span className="fw-bold">Add User Management</span>
                <i className="ri-close-line" onClick={() => modalService.closeModalHandling()} />
              </div>
              <div className="tabs-item-modal p-3 mb-0">
                {tabContent.map((data, idx) => {
                  return (
                    <div
                      key={`item-tabs-content-${idx}`}

                      onClick={() => setTabActive(data.name)}
                      className={`tabs-item ${tabActive === data.name && 'active-item'}`}>
                      {data.name}
                    </div>
                  )
                })}
              </div>
              <div className="px-3 pb-3">
                {tabActive === 'Account' && (
                  <FormikProvider value={createUser}>
                    <div className="d-flex">
                      <FormInput
                        label={"Complete Name"}
                        name={"name"}
                        placeholder="Enter your Complete Name"
                        formClassContainer={`mb-3 w-50 me-2`}
                        type={"text"}
                      />
                      <FormInput
                        label={"E-Mail"}
                        name={"email"}
                        placeholder="Enter your E-Mail"
                        formClassContainer={`mb-3 w-50`}
                        type={"text"}
                      />
                    </div>
                    <div className="d-flex">
                      <FormInput
                        label={"Password"}
                        name={"password"}
                        placeholder="Enter your Password"
                        formClassContainer={`mb-3 w-50 me-2`}
                        type={"password"}
                      />
                      <FormInput
                        label={"Confirm Password"}
                        name={"confirmPassword"}
                        placeholder="Enter your Confirm Password"
                        formClassContainer={`mb-3 w-50`}
                        type={"password"}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-end mt-5">
                      <Button
                        onClick={() => { setTabActive('Section') }}
                        label="Next"
                      />
                    </div>
                  </FormikProvider>
                )}
                {tabActive === 'Section' && (
                  <FormikProvider value={createUser}>
                    <div className="row" style={{ height: '180px' }}>
                      <FormDropdown
                        isSearchable={true}
                        isMultiSelect={true}
                        required
                        isSE
                        options={dummyDepartement}
                        useBorder={true}
                        dropDownIndicator={true}
                        formClassContainer={'col-4'}
                        placeholder={"Select departement"}
                        label="Departement"
                        name="departement"
                      />
                      <FormDropdown
                        isSearchable={true}
                        isMultiSelect={true}
                        required
                        options={dummyDivisi}
                        useBorder={true}
                        dropDownIndicator={true}
                        formClassContainer={'col-4'}
                        placeholder={"Select Division"}
                        label="Division"
                        name="division"
                      />
                      <FormDropdown
                        isSearchable={true}
                        isMultiSelect={true}
                        required
                        options={dummyPosisi}
                        useBorder={true}
                        dropDownIndicator={true}
                        formClassContainer={'col-4'}
                        placeholder={"Select Potition"}
                        label="Potition"
                        name="potition"
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-between mt-5">
                      <Button
                        onClick={() => { setTabActive('Account') }}
                        label="Prev"
                      />
                      <Button
                        onClick={() => { setTabActive('Access') }}
                        label="Next"
                      />
                    </div>
                  </FormikProvider>
                )}
                {tabActive === 'Access' && (
                  <FormikProvider value={createUser}>
                    <div className="px-2" style={{ height: '180px' }}>
                      <div>Sign in as a review account?</div>
                      <div className="recommendation-switch  border-bottom pb-3">
                        <input
                          type="radio"
                          id="recommendation"
                          value="recommendation"
                          checked={recommendation === 'recommendation'}
                          onChange={handleChange}
                        />
                        <label htmlFor="recommendation" className="recommendation-label">
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="noRecommendation"
                          value="noRecommendation"
                          checked={recommendation === 'noRecommendation'}
                          onChange={handleChange}
                        />
                        <label htmlFor="noRecommendation" className="recommendation-label">
                          No
                        </label>
                      </div>
                      <div className="row mt-3">
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>Screening</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-Screening"}
                              className="switch"
                              checked={isStatusScreening}
                              onChange={() => setIsStatusScreening(!isStatusScreening)}
                            />
                            <label htmlFor={"switch-Screening"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>HR Interview</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-HR-Interview"}
                              className="switch"
                              checked={isStatusHR}
                              onChange={() => setIsStatusHR(!isStatusHR)}
                            />
                            <label htmlFor={"switch-HR-Interview"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>Hired</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-Hired"}
                              className="switch"
                              checked={isStatusHired}
                              onChange={() => setIsStatusHired(!isStatusHired)}
                            />
                            <label htmlFor={"switch-Hired"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>Psychological</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-Psychological"}
                              className="switch"
                              checked={isStatusPsy}
                              onChange={() => setIsStatusPsy(!isStatusPsy)}
                            />
                            <label htmlFor={"switch-Psychological"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>On Boarding</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-On-Boarding"}
                              className="switch"
                              checked={isStatusOB}
                              onChange={() => setIsStatusOB(!isStatusOB)}
                            />
                            <label htmlFor={"switch-On-Boarding"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>Rejected</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-Rejected"}
                              className="switch"
                              checked={isStatusRj}
                              onChange={() => setIsStatusRj(!isStatusRj)}
                            />
                            <label htmlFor={"switch-Rejected"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>User Interview</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-User-Interview"}
                              className="switch"
                              checked={isStatusUI}
                              onChange={() => setIsStatusUI(!isStatusUI)}
                            />
                            <label htmlFor={"switch-User-Interview"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">
                          <div>MCU</div>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={"switch-MCU"}
                              className="switch"
                              checked={isStatusMCU}
                              onChange={() => setIsStatusMCU(!isStatusMCU)}
                            />
                            <label htmlFor={"switch-MCU"} className="label-switch">
                              Toggle
                            </label>
                          </div>
                        </div>
                        <div className="col-4 d-flex mb-3 justify-content-between">

                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between mt-5">
                      <Button
                        onClick={() => { setTabActive('Section') }}
                        label="Prev"
                      />
                      <Button
                        onClick={() => { }}
                        label="Submit"
                      />
                    </div>
                  </FormikProvider>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UserManagement;
