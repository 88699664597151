import * as Yup from "yup";

export const loginInitialValue = {
    email: "",
    password: "",
};

export const loginValidation = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Password is required"),
});
