import React, { useState, useMemo, useEffect } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { dataDummyTracking } from "../../DummyData";
import Table from "../../../components/Table";
import "../style.scss";

const Hired = ({ data, handlePropertyPageProfile, onClickRemove }) => {
    const [tableHeight, setTableHeight] = useState(310);
    useEffect(() => {
        const handleResize = () => {
            const newTableHeight = window.innerHeight - 310;
            setTableHeight(newTableHeight);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Applicant ID",
                accessor: "applicant_id",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "WhatsApp",
                accessor: "phone",
            },
            {
                Header: "E-mail",
                accessor: "email",
            },
            {
                Header: "Departement",
                accessor: "departement",
                Cell: ({ value }) => (
                    <div className="card-table card-table-red ">
                        <div>{value}</div>
                    </div>
                ),
            },
            {
                Header: "Potition",
                accessor: "potition",
                Cell: ({ value }) => (
                    <div className="card-table card-table-red ">
                        <div>{value}</div>
                    </div>
                ),
            },
            {
                Header: "Resume",
                accessor: "resume",
                Cell: ({ value }) => (
                    <div className="card-table card-table-semi-red">
                        <div>{value}</div>
                    </div>
                ),
            },
            {
                Header: "Start Date",
                accessor: "startDate",
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: (row) => (
                    <div className="d-flex">
                        <div
                            className="action-show"
                            onClick={() => handlePropertyPageProfile(false)}
                        >
                            <i className="ri-eye-line" />
                        </div>
                        <div
                            className="action-edit"
                            onClick={() => handlePropertyPageProfile(true)}
                        >
                            <i className="ri-pencil-line" />
                        </div>
                        <div
                            className="action-delete"
                            onClick={() => {
                                onClickRemove(row?.row?.original);
                            }}
                        >
                            <i className="ri-delete-bin-7-line" />
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    // eslint-disable-next-line no-empty-pattern
    useTable(
        {
            columns,
            data: data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        usePagination
    );

    return (
        <>
            <Table height={tableHeight - 40} columns={columns} data={data} />
        </>
    );
};

export default Hired;
