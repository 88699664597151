import { ErrorMessage, useField } from "formik";
import "./form-upload-file.style.scss";
import UploadFile from "../upload-file/upload-file.component";

const FormUploadFile = ({
    hideError = false,
    className,
    formClassContainer,
    ...props
}) => {
    const [field, meta, helpers] = useField(props?.name);
    const borderColorClass = meta.error && meta.touched ? "border-red" : "";
    const isError = !(!meta.error || (meta.error && !meta.touched));
    const errorClassMessage = isError && !hideError ? "" : "hidden";

    return (
        <div className={`${formClassContainer}`}>
            <UploadFile
                {...props}
                selectedFile={field.value}
                setSelectedFile={(value) => helpers.setValue(value)}
                className={`${borderColorClass} ${className}`}
            />
            <div className={`text-error ${errorClassMessage}`}>
                <ErrorMessage name={props?.name} component="p" />
            </div>
        </div>
    );
};

export default FormUploadFile;
