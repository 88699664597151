import React, { useState, useEffect, useRef } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./date-picker.style.scss";
import { formateDate } from "../../Service";

const DatePicker = ({
    label,
    selectedDate,
    setSelectedDate,
    required,
    disabled,
}) => {
    // const [selectedDate, setSelectedDate] = useState(null);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [inputValue, setInputValue] = useState(selectedDate);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const pickerRef = useRef(null);
    const disabledClass = disabled ? "disabled" : "";

    const handleDayClick = (day) => {
        setSelectedDate(day);
        setInputValue(formateDate(selectedDate));
        setIsPickerVisible(false);
        setCurrentMonth(day);
    };

    const toggleDatePicker = () => {
        setIsPickerVisible(!isPickerVisible);
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setIsPickerVisible(false);
        }
    };

    const handleInputChange = (e) => {
        const [day, month, year] = e.target.value.split("/").map(Number);
        const parsedDate = new Date(year, month - 1, day);
        if (!isNaN(parsedDate.getTime())) {
            setSelectedDate(parsedDate);
            setCurrentMonth(parsedDate);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedDate) {
            setInputValue(formateDate(selectedDate));
        }
    }, [selectedDate]);

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 80; i <= currentYear + 80; i++) {
        years.push(i);
    }

    const CustomHeader = ({ date, onChange }) => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear - 50; i <= currentYear; i++) {
            const dateItem = { value: i, label: i };
            years.push(dateItem);
        }

        const handlePrevMonth = () => {
            const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1);
            onChange(prevMonth);
        };

        const handleNextMonth = () => {
            const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1);
            onChange(nextMonth);
        };

        return (
            <div className="date-picker-header">
                <div>
                    <i
                        className="ri-arrow-left-s-line"
                        onClick={handlePrevMonth}
                    ></i>
                </div>
                <select
                    value={date.getFullYear()}
                    onChange={(e) =>
                        onChange(
                            new Date(
                                date.setFullYear(parseInt(e.target.value, 10))
                            )
                        )
                    }
                >
                    {years.map((year) => (
                        <option key={year.value} value={year.value}>
                            {year.label}
                        </option>
                    ))}
                </select>
                <select
                    value={date.getMonth()}
                    onChange={(e) =>
                        onChange(
                            new Date(
                                date.setMonth(parseInt(e.target.value, 10))
                            )
                        )
                    }
                >
                    {months.map((month, idx) => (
                        <option key={idx} value={idx}>
                            {month}
                        </option>
                    ))}
                </select>
                <div>
                    <i
                        className="ri-arrow-right-s-line"
                        onClick={handleNextMonth}
                    ></i>
                </div>
            </div>
        );
    };

    return (
        <div className="date-picker-container" ref={pickerRef}>
            <label htmlFor="date-input">
                {label}
                {required && <span className="required">*</span>}
            </label>
            <div className={`date-picker-input ${disabledClass}`}>
                <input
                    type="text"
                    id="date-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={toggleDatePicker}
                    placeholder="DD/MM/YYYY"
                    autoComplete="off"
                    disabled={disabled}
                />
                <i
                    className={`ri-calendar-line ${disabledClass}`}
                    onClick={() => {
                        if (!disabled) toggleDatePicker();
                    }}
                ></i>
            </div>
            {isPickerVisible && (
                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleDayClick}
                    captionLayout="dropdown"
                    showOutsideDays
                    month={currentMonth}
                    onMonthChange={setCurrentMonth}
                    components={{
                        Caption: (props) => (
                            <CustomHeader
                                {...props}
                                date={currentMonth}
                                onChange={setCurrentMonth}
                            />
                        ),
                    }}
                />
            )}
        </div>
    );
};

export default DatePicker;
