import { useState } from "react";
import "./style.scss";

export const Expand = ({ title, desc, children, className, icon, status }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${className} content-expand`}>
            <button onClick={() => setIsOpen(!isOpen)} className="w-100 btn-header-expand">
                <span className="header-expand">
                    {icon && <i className={`ri-${icon} pr-2 themes-text-brand-v5`} />}
                    <div className="text-start">
                        <div className="expand-title">{title}</div>
                        <div className="expand-desc">{desc}</div>
                    </div>
                </span>
                <div className="d-flex align-items-center">
                    <div className={`expand-status ${status ? "expand-status-green" : "expand-status-gray"}`}>
                        {status ? "Possible" : "Not Possible"}
                    </div>
                    <i className={`ri-arrow-down-s-line ${isOpen ? 'rotate' : ''}`} />
                </div>
            </button>
            <div className={`content ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    );
};
