import { useState } from "react";

const useToast = () => {
    const [toastProperty, setToastProperty] = useState({
        isToastOpen: false,
        type: "success",
        message: "",
    });

    const openToastHandling = ({ type, message }) =>
        setToastProperty({
            isToastOpen: true,
            type,
            message,
        });
    const closeToastHandling = () =>
        setToastProperty({ ...toastProperty, isToastOpen: false });

    return {
        toastProperty,
        openToastHandling,
        closeToastHandling,
    };
};

export default useToast;
