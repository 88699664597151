import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
    name: "global",
    initialState: {
        sidebarShow: "responsive",
        siteData: [],
        clientData: [],
        orderTypeDataForm: [],
        supplierData: [],
    },
    reducers: {
        setAppState: (state, action) => {
            state.appState = action.payload;
        },

        //UserManagent
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setRoleData: (state, action) => {
            state.roleData = action.payload;
        },
        setUserDetail: (state, action) => {
            state.userDetail = action.payload;
        },
        setUserEdit: (state, action) => {
            state.userEdit = action.payload;
        },
        setRoleDetail: (state, action) => {
            state.roleDetail = action.payload;
        },
        setRoleEdit: (state, action) => {
            state.roleEdit = action.payload;
        },

        // invoice
        setInvoiceData: (state, action) => {
            state.invoiceData = action.payload;
        },
        setInvoiceDetail: (state, action) => {
            state.invoiceDetail = action.payload;
        },
        setInvoiceEdit: (state, action) => {
            state.invoiceEdit = action.payload;
        },
        setCurrency: (state, action) => {
            state.currencyData = action.payload;
        },

        // proile
        setProvinceDropdownData: (state, action) => {
            state.provinceDropdownDataKtp = action.payload;
        },
        setRegenciesDropdownDataKtp: (state, action) => {
            state.regenciesDropdownDataKtp = action.payload;
        },
        setRegenciesDropdownDataCurrent: (state, action) => {
            state.regenciesDropdownDataCurrent = action.payload;
        },
        setDistricsDropdownDataKtp: (state, action) => {
            state.districsDropdownDataKtp = action.payload;
        },
        setDistricsDropdownDataCurrent: (state, action) => {
            state.districsDropdownDataCurrent = action.payload;
        },
        setProfileData: (state, action) => {
            state.profileData = action.payload;
        },
        setPropertyPageProfile: (state, action) => {
            state.propertyPageProfile = action.payload;
        },
    },
});

export const {
    setAppState,
    setUserData,
    setRoleData,
    setInvoiceData,
    setInvoiceEdit,
    setInvoiceDetail,
    setUserDetail,
    setUserEdit,
    setRoleDetail,
    setRoleEdit,
    setCurrency,
    setProvinceDropdownData,
    setRegenciesDropdownDataKtp,
    setRegenciesDropdownDataCurrent,
    setDistricsDropdownDataKtp,
    setDistricsDropdownDataCurrent,
    setProfileData,
    setPropertyPageProfile,
} = globalSlice.actions;
export default globalSlice.reducer;
